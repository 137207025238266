import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { login, logout } from '../actions/auth.actions';
import { ILoginPayload, ILoginResponse } from '../dependencies';
import {
    getUserAuthenticated,
    getError,
    getUser,
} from '../selectors/auth.selectors';

@Injectable()
export class AuthFacade {
    constructor(private store: Store) {}

    public isUserAuthenticated$ = (): Observable<boolean> => {
        return this.store.select(getUserAuthenticated);
    };

    public getUser$ = (): Observable<ILoginResponse | null> => {
        return this.store.select(getUser);
    };

    public getAuthenticationError$ = (): Observable<string | null> => {
        return this.store.select(getError);
    };

    public onLogin = (payload: ILoginPayload): void => {
        this.store.dispatch(login({ payload }));
    };

    public onLogout = (): void => {
        this.store.dispatch(logout());
    };
}
