<div class="edit-company-details">
    <div class="edit-company-details__title">Edit Company Details</div>

    <div class="edit-company-details__body">
        <ejs-tab id="tab_default" heightAdjustMode="Auto" (selected)="handleTabChange($event)">
            <e-tabitems>
                <e-tabitem [header]="headerText[0]">
                    <ng-template #content>
                        <form id="reactive" [formGroup]="form" class="edit-company-details__form form">
                            <div class="form__group required">
                                <ejs-textbox placeholder="Company Name" floatLabelType="Always" formControlName="companyName" />

                                <ng-container *ngIf="companyDetailsF['companyName'].touched && companyDetailsF['companyName'].errors">
                                    <p class="caption status-danger" *ngIf="companyDetailsF['companyName'].errors['required']">
                                        Company Name is required.
                                    </p>

                                    <p class="caption status-danger" *ngIf="companyDetailsF['companyName'].errors['maxlength']">
                                        Company Name can't be more than 255 characters.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group required">
                                <ejs-textbox placeholder="Email" floatLabelType="Always" formControlName="email" type="email" />

                                <ng-container *ngIf="companyDetailsF['email'].touched && companyDetailsF['email'].errors">
                                    <p class="caption status-danger" *ngIf="companyDetailsF['email'].errors['required']">
                                        Email is required.
                                    </p>

                                    <p class="caption status-danger" *ngIf="companyDetailsF['email'].errors['pattern']">
                                        Enter a valid email id.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group required">
                                <ejs-textbox placeholder="Legal Name" floatLabelType="Always" formControlName="legalName" />

                                <ng-container *ngIf="companyDetailsF['legalName'].touched && companyDetailsF['legalName'].errors">
                                    <p class="caption status-danger" *ngIf="companyDetailsF['legalName'].errors['required']">
                                        Legal Name is required.
                                    </p>

                                    <p class="caption status-danger" *ngIf="companyDetailsF['legalName'].errors['maxlength']">
                                        Legal Name can't be more than 255 characters.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__two-column">
                                <div class="form__group">
                                    <ejs-maskedtextbox
                                        placeholder="Mobile Number"
                                        name="phone"
                                        floatLabelType="Always"
                                        mask="000-000-0000"
                                        formControlName="phone"
                                    />

                                    <ng-container *ngIf="companyDetailsF['phone'].touched">
                                        <p class="caption status-danger" *ngIf="companyDetailsF['phone'].invalid">
                                            Enter Phone Number (123-456-7890) this format.
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="form__group">
                                    <ejs-textbox placeholder="Extension" floatLabelType="Always" formControlName="extension" />
                                </div>
                            </div>

                            <div class="form__group">
                                <ejs-textbox placeholder="Website Name" floatLabelType="Always" formControlName="website" />

                                <ng-container *ngIf="companyDetailsF['website'].touched && companyDetailsF['website'].errors">
                                    <p class="caption status-danger" *ngIf="companyDetailsF['website'].errors['pattern']">
                                        Enter valid url eg. http://www.google.com.
                                    </p>

                                    <p class="caption status-danger" *ngIf="companyDetailsF['website'].errors['maxlength']">
                                        Website can't be more than 255 characters
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__footer">
                                <button [ngClass]="['e-btn e-outline e-danger']" id="cancelBtn" content="Cancel" (click)="cancel()">
                                    Cancel
                                </button>

                                <button
                                    [ngClass]="['e-btn e-outline', !form.valid || disableFormFlag ? 'e-disabled' : 'e-success']"
                                    id="cancelBtn"
                                    content="Save"
                                    (click)="updateCompanyDetails()"
                                    [disabled]="!form.valid || disableFormFlag"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </ng-template>
                </e-tabitem>

                <e-tabitem [header]="headerText[1]">
                    <ng-template #content>
                        <app-address-form
                            [isVisible]="selectedTabIndex === 1"
                            [formData]="addressFormData"
                            [parentForm]="form"
                            (cancelAddress)="handleAddressFormCancel($event)"
                            (submitAddress)="handleAddressSubmit($event)"
                            (addressFormUpdated)="handleAddressFormUpdated($event)"
                        />
                    </ng-template>
                </e-tabitem>
            </e-tabitems>
        </ejs-tab>
    </div>
</div>
