<form class="form reusable-form" name="form" [formGroup]="addressForm" novalidate>
    <div class="form__group required">
        <ejs-textbox placeholder="Address Line 1" floatLabelType="Always" formControlName="firstLine" />

        <ng-container *ngIf="addressFormF['firstLine'].touched && addressFormF['firstLine'].errors">
            <p class="caption status-danger" *ngIf="addressFormF['firstLine'].errors['required']">Address line 1 is required</p>
        </ng-container>
    </div>

    <div class="form__group">
        <ejs-textbox placeholder="Address Line 2" floatLabelType="Always" formControlName="lastLine" />
    </div>

    <div class="form__group required">
        <ejs-dropdownlist
            id="country"
            name="country"
            [(ngModel)]="country"
            (change)="changeCountry($event)"
            formControlName="country"
            [dataSource]="countryList"
            placeholder="Select Country"
            floatLabelType="Always"
        />

        <ng-container *ngIf="addressFormF['country'].touched && addressFormF['country'].errors">
            <p class="caption status-danger" *ngIf="addressFormF['country'].errors['required']">Country is required</p>
        </ng-container>
    </div>

    <div class="form__group required" *ngIf="usaCountryFlag">
        <ejs-dropdownlist
            id="state"
            name="state"
            formControlName="state"
            [dataSource]="stateList"
            placeholder="Select State"
            floatLabelType="Always"
        />

        <ng-container *ngIf="addressFormF['state'].touched && addressFormF['state'].errors">
            <p class="caption status-danger" *ngIf="addressFormF['state'].errors['required']">State is required</p>
        </ng-container>
    </div>

    <div class="form__group required" *ngIf="provinceFlag">
        <ejs-dropdownlist
            id="state"
            name="state"
            [value]="selectedState"
            formControlName="state"
            [dataSource]="provinceList"
            placeholder="Select Province"
            floatLabelType="Always"
        />

        <ng-container *ngIf="addressFormF['state'].touched && addressFormF['state'].errors">
            <p class="caption status-danger" *ngIf="addressFormF['state'].errors['required']">State is required</p>
        </ng-container>
    </div>

    <div class="form__group required" *ngIf="!usaCountryFlag && !provinceFlag">
        <ejs-textbox placeholder="State" floatLabelType="Always" formControlName="state" />

        <ng-container *ngIf="addressFormF['state'].touched && addressFormF['state'].errors">
            <p class="caption status-danger" *ngIf="addressFormF['state'].errors['required']">State is required</p>
        </ng-container>
    </div>

    <div class="form__group required">
        <ejs-textbox placeholder="City" floatLabelType="Always" formControlName="city" />

        <ng-container *ngIf="addressFormF['city'].touched && addressFormF['city'].errors">
            <p class="caption status-danger" *ngIf="addressFormF['city'].errors['required']">City is required</p>
        </ng-container>
    </div>

    <div class="form__group required" *ngIf="provinceFlag">
        <ejs-maskedtextbox
            placeholder="Postal Code"
            floatLabelType="Always"
            mask="L0L 0L0"
            formControlName="zipCode"
            style="text-transform: uppercase"
        />

        <ng-container *ngIf="addressFormF['zipCode'].touched && addressFormF['zipCode'].errors">
            <p class="caption status-danger" *ngIf="addressFormF['zipCode'].errors['required']">Postal code is required</p>

            <p class="caption status-danger" *ngIf="!addressFormF['zipCode'].errors['required'] && addressForm.controls['zipCode'].invalid">
                Enter zip code (X0X 0X0) this format. (eg: A1A1A1)
            </p>
        </ng-container>
    </div>

    <div class="form__group required" *ngIf="usaCountryFlag">
        <ejs-maskedtextbox
            placeholder="Postal Code"
            floatLabelType="Always"
            mask="00000"
            formControlName="zipCode"
            style="text-transform: uppercase"
        />

        <ng-container *ngIf="addressFormF['zipCode'].touched && addressFormF['zipCode'].errors">
            <p class="caption status-danger" *ngIf="addressFormF['zipCode'].errors['required']">Zip code is required</p>

            <p class="caption status-danger" *ngIf="!addressFormF['zipCode'].errors['required'] && addressForm.controls['zipCode'].invalid">
                Enter zip code XXXXX in this format
            </p>
        </ng-container>
    </div>

    <div class="form__group required" *ngIf="!usaCountryFlag && !provinceFlag">
        <ejs-textbox placeholder="Postal Code" floatLabelType="Always" formControlName="zipCode" />

        <ng-container *ngIf="addressFormF['zipCode'].touched && addressFormF['zipCode'].errors">
            <p class="caption status-danger" *ngIf="addressFormF['zipCode'].errors['required']">Zip code is required</p>
        </ng-container>
    </div>

    <div class="form__footer" *ngIf="!isInlineForm">
        <button [ngClass]="['e-btn e-outline e-danger']" id="cancelBtn" content="Cancel" (click)="cancel()">Cancel</button>

        <button
            [ngClass]="['e-btn e-outline', !addressForm.valid || !parentForm?.valid ? 'e-disabled' : 'e-success']"
            id="cancelBtn"
            content="Save"
            (click)="updateAddress()"
            [disabled]="!addressForm.valid || !parentForm?.valid"
        >
            Save
        </button>
    </div>
</form>
