import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ColumnModel, GridComponent, SortService } from '@syncfusion/ej2-angular-grids';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { IBankAccounts, ICheque, IMeta } from '@core/models';
import { BankAccountService, ChequeService, CommonService } from '@core/services';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-post-dated-cheques',
    styleUrls: ['./post-dated-cheques.component.scss'],
    templateUrl: './post-dated-cheques.component.html',
    providers: [SortService],
})
export class PostDatedChequesComponent implements OnInit, AfterViewInit {
    @Input() refreshDashboard = false;
    @ViewChild('postDatedTransactionsGrid')
        gridInstance!: GridComponent;
    @ViewChild('pdfViewer') pdfViewer!: ElementRef;
    public pdfUrl: SafeResourceUrl = '';
    public bankAccounts: IBankAccounts[] = [];
    public transactions: ICheque[] = [];
    public paymentTypes: { name: string; id: string }[] = [];

    constructor(
        private commonService: CommonService,
        private chequeService: ChequeService,
        private sanitizer: DomSanitizer,
        private bankAccountService: BankAccountService
    ) {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    }

    ngOnInit(): void {
        this.getPaymentTypes();
    }

    ngAfterViewInit(): void {
        ((this.gridInstance as GridComponent)?.columns[3] as ColumnModel) &&
            (((this.gridInstance as GridComponent)?.columns[3] as ColumnModel).customAttributes = { class: 'currency-column' });
    }

    getPaymentTypes = () => {
        this.chequeService.getPaymentTypes().subscribe({
            next: (res: { name: string; id: string }[]) => {
                if (res) {
                    this.paymentTypes = res;
                    this.getBankAccounts();
                }
            },
        });
    };

    getBankAccounts = () => {
        this.bankAccountService.getBankAccounts().subscribe({
            next: (res: IBankAccounts[]) => {
                this.bankAccounts = [...res];
                this.getTransactions();
            },
        });
    };

    getTransactions = () => {
        this.chequeService.getCheques('').subscribe({
            next: (res: { content: ICheque[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    const todayUTC: DateTime = DateTime.utc();

                    this.transactions = res.content
                        .map((obj) => {
                            obj['bankAccountNumber'] = this.bankAccounts.find((item) => item.id === obj.bankAccount)?.name || '';
                            obj['paymentTypeName'] = this.paymentTypes.find((item) => item.id === obj.paymentType)?.name || '';
                            obj['supplierCompanyName'] = obj.supplier?.companyName || '';
                            return obj;
                        })
                        .filter((transaction) => {
                            const chequeDateUTC = DateTime.fromISO(transaction.transactionDate);
                            return chequeDateUTC > todayUTC;
                        });
                }
            },
            error: () => {
                this.transactions = [];
            },
        });
    };

    handlePrintCheque = (event: MouseEvent, id: string) => {
        event.stopPropagation();

        if (id) {
            this.chequeService.getChequePDF(id).subscribe({
                next: (res: { status: boolean; data: { url: string } }) => {
                    if (res && 'data' in res && res.data && 'url' in res.data && res.data.url) {
                        this.getPdfFile(res.data?.url);
                    }
                },
            });
        }
    };

    getPdfFile = (fileURL: string) => {
        this.commonService.getPDFFile(fileURL).subscribe((response: Blob) => {
            // Read the Blob data and convert it to an ArrayBuffer
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result as ArrayBuffer;
                const byteArray = new Uint8Array(arrayBuffer);
                // Create a Blob with the correct MIME type
                const blob = new Blob([byteArray], {
                    type: 'application/pdf',
                });
                // Create a Blob URL and open it in a new window
                const url = URL.createObjectURL(blob);
                this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

                setTimeout(() => {
                    this.printFile();
                    this.gridInstance.refresh();
                }, 1000);
            };

            reader.readAsArrayBuffer(response);
        });
    };

    printFile = () => {
        if (this.pdfViewer) {
            const iframe = this.pdfViewer.nativeElement;
            iframe.contentWindow.print();
        }
    };

    transactionConfig = () => {
        this.gridInstance.localeObj['localeStrings'].EmptyRecord = 'There are no transactions';
    };

    convertJSDateToUTC = (jsDate: Date) => {
        if (jsDate) {
            return jsDate.toISOString().slice(0, 10) + 'T00:00:00.000Z';
        }

        return '';
    };

    convertToUserTz = (dateStr: string): string | null => {
        return this.commonService.convertToUserTz(dateStr);
    };
}
