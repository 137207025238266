<div class="suppliers">
    <div class="suppliers__toolbar toolbar">
        <a href="javascript:void(0)" class="toolbar__download" (click)="handleToolbarClick('download')">Download</a>

        <a href="javascript:void(0)" class="toolbar__print" (click)="handleToolbarClick('print')">Print</a>

        <button
            [ngClass]="['toolbar__add-new e-btn e-small e-outline e-success']"
            id="addNewBtn"
            content="Add New"
            (click)="handleToolbarClick('add-new')"
            *ngIf="isAdminUser"
        >
            Add New
        </button>
    </div>

    <div class="suppliers__body">
        <ejs-grid
            #overviewgrid
            id="overviewgrid"
            [dataSource]="suppliers"
            rowHeight="45"
            [allowSorting]="true"
            [allowExcelExport]="true"
            (excelExportComplete)="excelExportComplete()"
            (rowSelected)="rowSelected($event)"
        >
            <e-columns>
                <e-column field="companyName" headerText="Company Name" />
                <e-column field="nameOnCheque" headerText="Name On Cheque" />

                <e-column field="expenseAccount" headerText="Expense Account" />

                <e-column field="defaultTaxCodeName" headerText="Tax Code" />

                <e-column field="action" headerText="" width="120" [visible]="true">
                    <ng-template #template let-data>
                        <div class="action-container">
                            <a
                                href="javascript:void(0)"
                                class="suppliers__action--link edit"
                                (click)="manageSupplier(true, data?.id, $event)"
                            >
                                <span class="material-icons-outlined" *ngIf="isAdminUser">edit</span>
                                <span class="material-icons-outlined" *ngIf="!isAdminUser">visibility</span>
                            </a>

                            <a
                                href="javascript:void(0)"
                                class="suppliers__action--link delete"
                                *ngIf="data.isDeletable && isAdminUser"
                                (click)="handleDelete(data, $event)"
                            >
                                <span class="material-icons-outlined"> delete_forever </span>
                            </a>
                        </div>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>

        <ejs-pager
            *ngIf="suppliers.length > 0"
            [totalRecordsCount]="supplierTotalRecords"
            [pageCount]="supplierPageCount"
            [pageSize]="supplierPageSize"
            (currentPageChange)="handleSupplierPaging($event)"
        />

        <div class="supplier-details">
            <div class="supplier-details__title">Transactions of {{ selectedSupplier?.companyName }}</div>

            <ejs-grid
                #supplierTransactionsGrid
                id="supplierTransactionsGrid"
                [dataSource]="supplierTransactions"
                [allowSorting]="true"
                rowHeight="45"
                (load)="supplierTransactionConfig()"
            >
                <e-columns>
                    <e-column field="transactionDate" headerText="Transaction Date">
                        <ng-template #template let-data>
                            {{ convertToUserTz(data.transactionDate) ? convertToUserTz(data.transactionDate) : '' }}
                        </ng-template>
                    </e-column>
                    <e-column field="amount" headerText="Amount" textAlign="right">
                        <ng-template #template let-data>
                            {{ data.amount | currency }}
                        </ng-template>
                    </e-column>
                    <e-column field="bankAccountName" headerText="Bank Account" />
                    <e-column field="paymentTypeName" headerText="Payment Type" />
                    <e-column field="chequeNumber" headerText="Cheque Number">
                        <ng-template #template let-data>
                            <a href="javascript:void(0)" class="supplier-details__link" *ngIf="data.paymentTypeName !== 'Cheque'">-</a>

                            <a
                                href="javascript:void(0)"
                                class="supplier-details__link"
                                (click)="handlePrintCheque($event, data.chequeId)"
                                *ngIf="data.paymentTypeName === 'Cheque'"
                            >
                                {{ data.chequeNumber }}
                            </a>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>

            <ejs-pager
                *ngIf="supplierTransactions.length > 0"
                #supplierTransactionsPager
                id="supplierTransactionsPager"
                [totalRecordsCount]="transactionTotalRecords"
                [pageCount]="transactionPageCount"
                [pageSize]="transactionPageSize"
                (currentPageChange)="handlePaging($event)"
            />
        </div>
    </div>

    <iframe #pdfViewer [src]="pdfUrl" width="100%" height="600" class="pdf-preview"></iframe>
</div>
