import { Component } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormControl,
    AbstractControl,
    Validators,
} from '@angular/forms';

@Component({
    selector: 'app-chq-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
    public subscribeForm: FormGroup = new FormGroup({
        email: new FormControl<string>('', [Validators.required]),
    });

    constructor(private fb: FormBuilder) {
        this.subscribeForm = this.fb.group({
            email: ['', [Validators.required]],
        });
    }

    get subscribeFormF(): { [key: string]: AbstractControl } {
        return this.subscribeForm.controls;
    }
}
