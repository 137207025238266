<div class="c-settings d-flex">
    <div class="c-settings__card card-one">
        <app-company-details />
    </div>

    <div class="c-settings__card card-two">
        <app-bank-accounts />
    </div>

    <div class="c-settings__card card-three">
        <app-tax-setup />
    </div>

    <div class="c-settings__card card-four">
        <app-expense-accounts />
    </div>

    <div class="c-settings__card card-five">
        <app-user />
    </div>
</div>
