import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';
import { ChangeEventArgs } from '@syncfusion/ej2-dropdowns';

import { IBankAccountPayload, EToasterStatus, IBankAccounts, IECHQAddress } from '@core/models';
import { BankAccountService, ToasterService, UserService } from '@core/services';
import { Address, Constant } from '@core/constants';

interface Data {
    isEdit: boolean;
    backAccountID?: string;
}

@Component({
    selector: 'app-add-bank-accounts',
    templateUrl: './add-bank-accounts.component.html',
    styleUrls: ['./add-bank-accounts.component.scss'],
})
export class AddBankAccountsComponent implements OnInit {
    public countryList: string[] = [];
    public provinceList: string[] = [];
    public stateList: string[] = [];
    public provinceFlag: boolean = false;
    public usaCountryFlag: boolean = false;
    public form: FormGroup = new FormGroup({
        name: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
        account: new FormControl<string>('', [Validators.maxLength(255)]),
        chequeSequence: new FormControl<bigint>(0n, [Validators.required]),
        eaAccount: new FormControl<string>('', [Validators.maxLength(255)]),
        firstLine: new FormControl<string>('', []),
        lastLine: new FormControl<string>('', []),
        city: new FormControl<string>('', []),
        country: new FormControl<string>('', []),
        state: new FormControl<string>('', []),
        zipCode: new FormControl<string>('', []),
    });
    public ref: DialogRef<Data, boolean> = inject(DialogRef);
    public isEdit: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private bankAccountService: BankAccountService,
        private userService: UserService,
        private toasterService: ToasterService,
        private constant: Constant,
        private address: Address
    ) {
        this.countryList = this.address.COUNTRYLIST;
        this.provinceList = this.address.PROVINCES;
        this.stateList = this.address.STATES;
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(255)]],
            account: ['', [Validators.maxLength(255)]],
            chequeSequence: ['', Validators.required],
            eaAccount: ['', Validators.maxLength(255)],
            firstLine: ['', []],
            lastLine: ['', []],
            city: ['', []],
            country: ['', []],
            state: ['', []],
            zipCode: ['', []],
        });
    }

    ngOnInit(): void {
        if (this.ref.data.isEdit) {
            this.isEdit = this.ref.data.isEdit;
            this.ref.data?.backAccountID && this.getBankAccount(this.ref.data?.backAccountID);
        }
    }

    get formControlF(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    get title() {
        return this.ref.data.isEdit ? 'Edit Bank Accounts' : 'Add Bank Accounts';
    }

    cancel = () => {
        this.ref.close(false);
    };

    changeCountry = (event: ChangeEventArgs) => {
        this.checkCountrySelection(event.value as string);
    };

    checkCountrySelection(value: string) {
        this.usaCountryFlag = false;
        this.provinceFlag = false;
        this.form.patchValue({
            state: '',
            zipCode: '',
        });

        if (value == 'Canada') {
            this.provinceFlag = true;
        } else if (value == 'United States of America') {
            this.usaCountryFlag = true;
        }
    }

    getBankAccount = (id: string) => {
        this.bankAccountService.getBankAccountByID(id).subscribe({
            next: (data: IBankAccounts) => {
                this.form.patchValue({
                    name: data.name,
                    account: data.account,
                    chequeSequence: data.chequeSequence,
                    eaAccount: data?.eaAccount,
                    firstLine: data.address?.firstLine || '',
                    lastLine: data.address?.lastLine || '',
                    city: data.address?.city || '',
                    country: data.address?.country || '',
                    zipCode: data.address?.zipCode || '',
                    state: data.address?.state || '',
                });
            },
        });
    };

    addBankAccount = () => {
        const orgID: string = this.userService.organizationId;
        const formValue = this.form.getRawValue();

        const payload: IBankAccountPayload = {
            organization: orgID,
            name: formValue.name || '',
            account: formValue.account || '',
            chequeSequence: formValue.chequeSequence,
            eaAccount: formValue.eaAccount || '',
            address: {
                firstLine: formValue.firstLine || '',
                lastLine: formValue.lastLine || '',
                country: formValue.country || '',
                city: formValue.city || '',
                state: formValue.state || '',
                zipCode: formValue.zipCode || '',
            },
        };

        if (this.form.valid) {
            const bankAccountRequest$ =
                this.isEdit && this.ref.data.backAccountID
                    ? this.bankAccountService.updateBankAccount(this.ref.data.backAccountID, payload)
                    : this.bankAccountService.createBankAccount(payload);

            bankAccountRequest$.subscribe({
                next: (res) => {
                    if (res) {
                        this.showToast(
                            EToasterStatus.SUCCESS,
                            this.isEdit ? this.constant.BANK_ACCOUNT_MSG.UPDATE_SUCCESS : this.constant.BANK_ACCOUNT_MSG.SUCCESS
                        );
                        this.ref.close(true);
                    }
                },
                error: (err) => {
                    this.showToast(
                        EToasterStatus.DANGER,
                        this.isEdit ? this.constant.BANK_ACCOUNT_MSG.UPDATE_FAILURE : this.constant.BANK_ACCOUNT_MSG.FAILURE
                    );
                },
            });
        }
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }
}
