import { Component } from '@angular/core';
import { AnimationSettingsModel, SpinSettingsModel } from '@syncfusion/ej2-splitbuttons';
import { NgForm } from '@angular/forms';

import { Constant } from '@core/constants';
import { AuthService } from '@core/services';

@Component({
    selector: 'app-forgot-password',
    styleUrls: ['./forgot-password.component.scss'],
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
    public requestPasswordActionMessage = '';
    public showSuccessMessage = false;
    public showErrorMessage = false;
    public submitted = false;
    public emailAddress = '';
    public slideRight: AnimationSettingsModel = { effect: 'SlideRight' };
    public spinCenter: SpinSettingsModel = { position: 'Center' };
    public emailValidationPattern: RegExp;

    constructor(private constant: Constant, private authService: AuthService) {
        this.emailValidationPattern = this.constant.EMAIL_VALIDATION_PATTERN;
    }

    onSubmit(requestPassForm: NgForm): void {
        this.submitted = true;
        const email: string = ((requestPassForm || {}).value || {}).email || '';

        this.authService.requestPassword(email).subscribe(
            () => {
                this.showSuccessMessage = true;
                this.showErrorMessage = false;
                this.requestPasswordActionMessage = this.constant.REQUEST_PASSWORD_MSG.SUCCESS;
                requestPassForm.resetForm();
                this.submitted = false;
            },
            () => {
                this.showErrorMessage = true;
                this.showSuccessMessage = false;
                this.requestPasswordActionMessage = this.constant.REQUEST_PASSWORD_MSG.FAILURE;
                requestPassForm.resetForm();
                this.submitted = false;
            }
        );
    }

    validateEmail(email: string): boolean {
        console.log(this.emailValidationPattern.test(email));
        return this.emailValidationPattern.test(email);
    }
}
