import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    styleUrls: ['./footer.component.scss'],
    template: `
        <div class="socials d-flex align-items-center justify-content-end">
            <a href="#" target="_blank" class="ion ion-social-github">&nbsp;</a>

            <a href="#" target="_blank" class="ion ion-social-facebook">
                &nbsp;
            </a>

            <a href="#" target="_blank" class="ion ion-social-twitter">
                &nbsp;
            </a>

            <a href="#" target="_blank" class="ion ion-social-linkedin">
                &nbsp;
            </a>
        </div>
    `,
})
export class FooterComponent {}
