import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class Constant {
    USER_ROLES = {
        ADMIN: 'admin',
        EMP: 'employee',
    };

    CHEQUE_MSG = {
        SUCCESS: 'Transaction created successfully',
        FAILURE: 'Transaction creation failed',
        UPDATE_SUCCESS: 'Transaction updated successfully',
        UPDATE_FAILURE: 'Transaction updation failed',
    };

    BANK_ACCOUNT_MSG = {
        SUCCESS: 'Bank account created successfully',
        FAILURE: 'Bank account creation failed',
        UPDATE_SUCCESS: 'Bank account updated successfully',
        UPDATE_FAILURE: 'Bank account updation failed',
    };

    BATCH_PRINT_MSG = {
        SUCCESS: 'Batch printing has been completed successfully.',
        FAILURE: 'Batch printing failed.',
        MERGE_SUCCESS: 'Transactions merged successfully',
        MERGE_FAILURE: 'Transactions merge failed.',
    };

    EXPENSE_ACCOUNT_MSG = {
        SUCCESS: 'Expense account created successfully',
        FAILURE: 'Expense account creation failed',
        UPDATE_SUCCESS: 'Expense account updated successfully',
        UPDATE_FAILURE: 'Expense account updation failed',
    };

    TAX_SETUP_MSG = {
        SUCCESS: 'The tax setup is complete.',
        FAILURE: 'The tax setup has failed.',
        UPDATE_SUCCESS: 'The tax setup update is complete.',
        UPDATE_FAILURE: 'The tax setup update has failed.',
    };

    REGISTER_MSG = {
        SUCCESS: 'Registration successful. We have sent a verification email. Please follow instructions on that email.',
        FAILURE: 'Registration failed',
    };

    REQUEST_PASSWORD_MSG = {
        SUCCESS: 'We have sent a reset password link email. Please follow instructions on that email.',
        FAILURE: 'Reset password link failed',
    };

    RESET_PASSWORD_MSG = {
        SUCCESS: 'Your password has been updated successfully.',
        FAILURE: 'New password updation failed.',
    };

    REDIRECT_LINKS = {
        LOGIN: '/login',
        REGISTER: '/register',
        DASHBOARD: '/pages/dashboard',
        PROFILE: '/pages/profile',
    };

    EMAIL_VALIDATION_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    TOAST_ACTION = {
        SUCCESS: 'success',
        FAILURE: 'danger',
    };

    SUPPLIER_CRUD_MSG = {
        SUCCESS: 'Supplier created successfully',
        FAILURE: 'Supplier creation failed',
        UPDATE_SUCCESS: 'Supplier updated successfully',
        UPDATE_FAILURE: 'Supplier updation failed',
        DELETE_SUCCESS: 'Supplier deleted successfully',
        DELETE_FAILURE: 'Supplier deletion failed',
    };

    USER_CRUD_MSG = {
        SUCCESS: 'User created successfully',
        FAILURE: 'User creation failed',
        UPDATE_SUCCESS: 'User updated successfully',
        UPDATE_FAILURE: 'User updation failed',
    };

    ORGANIZATION_CRUD_MSG = {
        ORGANIZATION_UPDATE_SUCCESS: 'Company details updated successfully',
        ORGANIZATION_UPDATE_FAILURE: 'Company details updation failed',
    };

    DOMAIN_URLS = {
        DEV: 'https://dev.easycheque.ca',
        STAGING: 'https://staging.easycheque.ca',
        PRODUCTION: 'https://easycheque.ca',
        LOCAL: 'http://localhost:4200',
    };

    PAYMENT_TYPES = [
        { name: 'Cheque', id: 'Cheque' },
        { name: 'Cash', id: 'Cash' },
        { name: 'E-Transfer', id: 'E-Transfer' },
        { name: 'ACH', id: 'ACH' },
        { name: 'Wire', id: 'Wire' },
    ];

    SELECTED_PAYMENT_TYPES = ['Cheque', 'Cash', 'E-Transfer', 'ACH', 'Wire'];

    API_URLS = {
        LOGIN: '/auth',
        RESET_PASSWORD: '/resetpassword',
        FORGOT_PASSWORD: '/forgotpassword',
        DASHBOARD: '/pages/dashboard',
        CHANGE_PASSWORD: (id: string) => `/${id}/changepassword`,
        VERIFY_USER: '/verify',
        UPDATE_USER: (id: string) => `/${id}`,
        GET_USER: (id: string) => `/${id}`,
        GET_EMPLOYEE: '/',
        GET_EMPLOYEE_INFO: (id: string) => `/${id}`,
        UPDATE_EMPLOYEE: (id: string) => `/${id}`,
        GET_ALL_EMPLOYEES: (id: string) => `/${id}/employees`,
        UPDATE_EMPLOYEE_ADDRESS: (id: string) => `/${id}/address`,
        CREATE_ORGANIZATION: '',
        GET_ORGANIZATION_BY_ID: (id: string) => `/${id}`,
        GET_ORGANIZATION_ADDRESS: (id: string) => `/${id}/address`,
        GET_BANK_ACCOUNT: '/bank-account',
        GET_BANK_ACCOUNT_BY_ID: (id: string) => `/bank-account/${id}`,
        GET_EXPENSE_ACCOUNT: '/expense-accounts',
        GET_EXPENSE_ACCOUNT_BY_ID: (id: string) => `/expense-accounts/${id}`,
        GET_TAXES: '/tax-setup',
        GET_SUPPLIERS: (qString: string) => (qString ? `/suppliers?${qString}` : '/suppliers'),
        GET_SUPPLIER_BY_ID: (id: string) => `/suppliers/${id}`,
        GET_SUPPLIERS_ADDRESS: (id: string) => `/suppliers/${id}/address`,
        GET_CHEQUES: (qString: string) => (qString ? `/expense/?${qString}` : '/expense'),
        GET_CHEQUE_PDF: (id: string) => `/${id}`,
        GET_TRANSACTION_BY_ID: (id: string) => `/expense/${id}`,
        GET_PAYMENT_TYPES: () => '/payment-type',
        CREATE_BATCH_PRINT: '/print-config',
        GET_BATCH_TRANSACTION_BY_ID: (id: string) => `/print-config/${id}`,
        GET_BATCH_PDF: `/merge-pdf`,
    };
}
