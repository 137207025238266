import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonModule, SwitchAllModule } from '@syncfusion/ej2-angular-buttons';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TabAllModule } from '@syncfusion/ej2-angular-navigations';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { NumericTextBoxModule, TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { CompanySettingsComponent } from './company-settings.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { BankAccountsComponent } from './bank-accounts/bank-accounts.component';
import { SharedModule } from '@shared/shared.module';
import { ExpenseAccountsComponent } from './expense-accounts/expense-accounts.component';
import { TaxSetupComponent } from './tax-setup/tax-setup.component';
import { EditCompanyDetailComponent } from './company-details/edit-company-details/edit-company-details.component';
import { AddBankAccountsComponent } from './bank-accounts/add-bank-accounts/add-bank-accounts.component';
import { AddExpenseAccountsComponent } from './expense-accounts/add-expense-accounts/add-expense-accounts.component';
import { AddTaxSetupComponent } from './tax-setup/add-tax-setup/add-tax-setup.component';
import { CheckPrintSetupComponent } from './check-print-setup/check-print-setup.component';
import { UserComponent } from './users/users.component';
import { UserDialogComponent } from './users/user-dialog/user-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        GridAllModule,
        TooltipModule,
        SharedModule,
        DialogModule,
        TabAllModule,
        DropDownListModule,
        NumericTextBoxModule,
        TextBoxAllModule,
        MaskedTextBoxAllModule,
        PdfViewerModule,
        SwitchAllModule,
    ],
    declarations: [
        CompanySettingsComponent,
        CompanyDetailsComponent,
        BankAccountsComponent,
        ExpenseAccountsComponent,
        TaxSetupComponent,
        EditCompanyDetailComponent,
        AddBankAccountsComponent,
        AddExpenseAccountsComponent,
        AddTaxSetupComponent,
        CheckPrintSetupComponent,
        UserComponent,
        UserDialogComponent,
    ],
    exports: [EditCompanyDetailComponent],
})
export class CompanySettingsModule {}
