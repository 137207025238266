import { createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions/auth.actions';
import { ILoginResponse, User, AuthState } from '../dependencies';

//set the initial state with localStorage
export const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
    errorMessage: null,
};

export const authReducer = createReducer<AuthState>(
    { ...initialState },
    on(
        fromActions.loginSuccess,
        (state: AuthState, action: { payload: ILoginResponse }) => {
            return {
                ...state,
                isAuthenticated: true,
                user: {
                    ...action.payload,
                    email: action.payload?.profile?.email,
                },
                errorMessage: null,
            };
        }
    ),
    on(
        fromActions.loginFailed,
        (state: AuthState, action: { payload: Error }) => {
            return {
                ...state,
                errorMessage: 'Wrong credentials.',
            };
        }
    ),
    on(fromActions.logout, (state: AuthState) => {
        return {
            ...state,
        };
    })
);
