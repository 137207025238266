import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LoaderService } from '@core/services';

@Injectable()
export class HttpRequestInterceptor<T> implements HttpInterceptor {
    constructor(private _loader: LoaderService) {}

    intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        this._loader.show();

        return next
            .handle(request)
            .pipe(
                catchError((err) => {
                    this._loader.hide();
                    return throwError(err);
                })
            )
            .pipe(
                map((evt: HttpEvent<T>) => {
                    if (evt instanceof HttpResponse) {
                        this._loader.hide();
                    }
                    return evt;
                })
            );
    }
}
