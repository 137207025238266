import {
    NgModule,
    Optional,
    SkipSelf,
    ModuleWithProviders,
} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';

import { throwIfAlreadyLoaded } from './guards';

@NgModule({
    imports: [CommonModule, HttpClientModule],
    providers: [DatePipe],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [],
        };
    }
}
