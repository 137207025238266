import { Component, inject } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

interface Data {
    title: string;
    msg: string;
}

@Component({
    selector: 'app-confirmation-dialog',
    template: `
        <div class="confirmation-dialog">
            <header class="confirmation-dialog__header">
                <h1 class="confirmation-dialog__title">{{ title }}</h1>
            </header>

            <div class="confirmation-dialog__body">
                <div class="confirmation-dialog__msg">{{ msg }}</div>
            </div>

            <footer class="confirmation-dialog__action">
                <button
                    [ngClass]="['e-btn e-outline e-danger']"
                    id="cancelBtn"
                    content="Cancel"
                    (click)="cancel()"
                >
                    Cancel
                </button>

                <button
                    [ngClass]="['e-btn e-outline e-success']"
                    id="cancelBtn"
                    content="Save"
                    (click)="confirm()"
                >
                    Save
                </button>
            </footer>
        </div>
    `,
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationComponent {
    public ref: DialogRef<Data, boolean> = inject(DialogRef);

    get title() {
        if (!this.ref.data) return 'Confirm';
        return this.ref.data.title;
    }

    get msg() {
        if (!this.ref.data) return 'Are you sure?';
        return this.ref.data.msg;
    }

    cancel = () => {
        this.ref.close(false);
    };

    confirm = () => {
        this.ref.close(true);
    };
}
