import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import { StoreModule, ActionReducer } from '@ngrx/store';

import { authFeatureKey } from './feature-key';
import { authFeatureReducer, AuthFeatureState } from './reducers';
import { authFeatureEffects } from './effects';
import { authFeatureFacades } from './facades';

export function localStorageSyncReducer(
    reducer: ActionReducer<AuthFeatureState>
): ActionReducer<AuthFeatureState> {
    return localStorageSync({ keys: ['auth'], rehydrate: true })(reducer);
}

@NgModule({
    declarations: [],
    providers: [...authFeatureFacades],
    imports: [
        CommonModule,
        StoreModule.forFeature<AuthFeatureState>(
            authFeatureKey,
            authFeatureReducer,
            {
                metaReducers: [localStorageSyncReducer],
            }
        ),
        EffectsModule.forFeature(authFeatureEffects),
    ],
})
export class AuthStoreModule {}
