import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

import { IMenuItem } from '@core/models';
import { MenuService } from '@core/services';

@Component({
    selector: 'app-chq-pages',
    styleUrls: ['pages.component.scss'],
    templateUrl: './pages.component.html',
})
export class PagesComponent implements OnInit, AfterViewInit {
    @ViewChild('dockBar')
    dockBar!: SidebarComponent;
    public isBrowserSupport = false;
    public enableDock = false;
    public dockSize = '56px';
    public width = '256px';
    public selectedMenu = '';
    public menuSelection$: Observable<string> = this.menuService.menuSelection$;
    public isMobile = false;
    public menus: IMenuItem[] = [
        { icon: 'dashboard', label: 'Dashboard', path: 'dashboard' },
        {
            icon: 'room_preferences',
            label: 'Company Settings',
            path: 'company-settings',
        },
        { icon: 'local_shipping', label: 'Suppliers', path: 'suppliers' },
        { icon: 'payments', label: 'Cheques / ePayments', path: 'cheques' },
        { icon: 'print', label: 'Batch Printing', path: 'batch-printing' },
        { icon: 'list_alt', label: 'Reports', path: 'reports' },
    ];

    constructor(
        private menuService: MenuService,
        private cdRef: ChangeDetectorRef,
        private router: Router,
        private breakpointObserver: BreakpointObserver
    ) {}

    ngOnInit(): void {
        this.getBrowser();

        this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait]).subscribe((result) => {
            this.isMobile = result.matches;
        });
    }

    ngAfterViewInit(): void {
        this.menuService.menuState.subscribe((val: boolean) => {
            if (!this.isMobile) {
                this.enableDock = val;
                this.dockBar.toggle();
            } else {
                if (this.enableDock !== val) {
                    this.enableDock = val;

                    if (val) {
                        this.dockBar.show();
                    } else {
                        this.dockBar.hide();
                    }
                }
            }

            this.cdRef.detectChanges();
        });

        this.menuSelection$.subscribe((res: string) => {
            this.selectedMenu = res;
            this.cdRef.detectChanges();
        });
    }

    onCreated() {
        this.dockBar.element.style.visibility = '';
    }

    handleChange = () => {
        if (this.enableDock && !this.dockBar.isOpen) {
            this.menuService.toggleStatus(this.dockBar.isOpen);
        }
    };

    handleMenuClick = (menu: IMenuItem) => {
        const { path } = menu;

        if (path) {
            this.menuService.updateMenuSelection(path);
            this.router.navigate(['pages', path]);
        }
    };

    getBrowser() {
        const ua: string = navigator.userAgent;
        let tem: RegExpMatchArray | null;
        let M: RegExpMatchArray =
            ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || ([] as unknown as RegExpMatchArray);
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) {
            M.splice(1, 1, tem[1]);
        }
        if (M[0].toLocaleLowerCase() == 'safari') {
            this.isBrowserSupport = false;
            console.log('Application does not support your browser, please try to use in another browser');
        } else {
            this.isBrowserSupport = true;
        }
    }
}
