import { Component, OnInit, inject } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';

import { EToasterStatus, ITax } from '@core/models';
import { TaxSetupService, ToasterService } from '@app/core/services';
import { Constant } from '@core/constants';

interface Data {
    isEdit: boolean;
    selectedTax?: ITax | null;
}

@Component({
    selector: 'app-add-tax-setup',
    templateUrl: './add-tax-setup.component.html',
    styleUrls: ['./add-tax-setup.component.scss'],
})
export class AddTaxSetupComponent implements OnInit {
    public ref: DialogRef<Data, boolean> = inject(DialogRef);
    public form: FormGroup = new FormGroup({
        taxName: new FormControl<string>('', [
            Validators.required,
            Validators.maxLength(50),
        ]),
        taxPercentage: new FormControl<number>(0, [Validators.required]),
        code: new FormControl<string>('', [
            Validators.required,
            Validators.maxLength(3),
        ]),
    });
    public loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private taxSetupService: TaxSetupService,
        private toasterService: ToasterService,
        private constant: Constant
    ) {
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(50)]],
            tax: ['', [Validators.required]],
            code: ['', [Validators.required, Validators.maxLength(3)]],
        });
    }

    ngOnInit(): void {
        if (this.isEdit && this.selectedTax) {
            this.form.patchValue({
                name: this.selectedTax?.name || '',
                tax: this.selectedTax?.tax || '',
                code: this.selectedTax?.code || '',
            });
            this.form.get('code')?.disable();
        }
    }

    get formControlF(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    get title() {
        return this.ref.data.isEdit ? 'Edit Tax' : 'Add Tax';
    }

    get isEdit() {
        return this.ref.data?.isEdit;
    }

    get selectedTax() {
        return this.ref.data?.selectedTax || null;
    }

    cancel = () => {
        this.ref.close(false);
    };

    addTaxSetup = () => {
        this.loading = true;

        if (this.form.valid) {
            const formValue: ITax = this.form.getRawValue();
            const payload: ITax = {
                name: formValue.name,
                tax: formValue.tax,
                code: formValue.code,
            };
            this.taxSetupService.createNewTax(payload).subscribe({
                next: (res: ITax) => {
                    this.loading = false;
                    this.showToast(
                        EToasterStatus.SUCCESS,
                        this.isEdit
                            ? this.constant.TAX_SETUP_MSG.UPDATE_SUCCESS
                            : this.constant.TAX_SETUP_MSG.SUCCESS
                    );
                    this.ref.close(true);
                },
                error: (err) => {
                    this.loading = false;
                    this.showToast(
                        EToasterStatus.DANGER,
                        this.isEdit
                            ? this.constant.TAX_SETUP_MSG.UPDATE_FAILURE
                            : this.constant.TAX_SETUP_MSG.FAILURE
                    );
                },
            });
        }
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }
}
