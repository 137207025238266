import { Component } from '@angular/core';
import { AnimationSettingsModel, SpinSettingsModel } from '@syncfusion/ej2-splitbuttons';

import { Constant } from '@core/constants';
import * as fromDeps from '../../dependencies';

@Component({
    selector: 'app-login',
    styleUrls: ['./login.component.scss'],
    templateUrl: './login.component.html',
})
export class LoginComponent {
    public user: fromDeps.User = new fromDeps.User();
    public errorMessage = '';
    public submitted = false;
    public showPasswordFlag = false;
    public slideRight: AnimationSettingsModel = { effect: 'SlideRight' };
    public spinCenter: SpinSettingsModel = { position: 'Center' };
    public emailValidationPattern: RegExp;

    constructor(private constant: Constant, private authFacade: fromDeps.store.AuthFacade) {
        this.emailValidationPattern = this.constant.EMAIL_VALIDATION_PATTERN;
    }

    onSubmit(): void {
        this.submitted = true;
        const actionPayload: fromDeps.ILoginPayload = {
            email: this.user.email,
            password: this.user.password || '',
        };
        this.authFacade.onLogin(actionPayload);
    }

    validateEmail(): boolean {
        return this.emailValidationPattern.test(this.user.email);
    }

    togglePassword(id: string) {
        this.showPasswordFlag = !this.showPasswordFlag;
        const passEleType = (<HTMLInputElement>document.getElementById(id)).type;
        if (passEleType == 'password') {
            (<HTMLInputElement>document.getElementById(id)).type = 'text';
        } else if (passEleType == 'text') {
            (<HTMLInputElement>document.getElementById(id)).type = 'password';
        }
    }
}
