import { Component, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';
import { Observable } from 'rxjs';

import { TaxSetupService, ExpenseAccountService, UserService, ToasterService } from '@core/services';
import { EToasterStatus, IExpenseAccountPayload, IExpenseAccounts, ITax } from '@core/models';
import { Constant } from '@core/constants';

interface Data {
    isEdit: boolean;
    expenseAccountID: string;
}

@Component({
    selector: 'app-add-expense-accounts',
    templateUrl: './add-expense-accounts.component.html',
    styleUrls: ['./add-expense-accounts.component.scss'],
})
export class AddExpenseAccountsComponent implements OnInit {
    public ref: DialogRef<Data, boolean> = inject(DialogRef);
    public form: FormGroup = new FormGroup({
        name: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
        account: new FormControl<string>('', [Validators.maxLength(255)]),
        taxCode: new FormControl<string>('', [Validators.required, Validators.maxLength(3)]),
        eaAccount: new FormControl<string>('', [Validators.maxLength(255)]),
    });
    public loading: boolean = false;
    public taxes: ITax[] = [];
    public taxCode: string = '';

    constructor(
        private formBuilder: FormBuilder,
        private taxSetupService: TaxSetupService,
        private expenseAccountService: ExpenseAccountService,
        private userService: UserService,
        private toasterService: ToasterService,
        private constant: Constant
    ) {
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(255)]],
            account: ['', [Validators.maxLength(255)]],
            taxCode: ['', [Validators.maxLength(3)]],
            eaAccount: ['', Validators.maxLength(255)],
        });
    }

    ngOnInit(): void {
        this.getTaxSetup();
    }

    get formControlF(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    get title() {
        return this.ref.data.isEdit ? 'Edit Expense Accounts' : 'Add Expense Accounts';
    }

    get isEdit() {
        return this.ref.data?.isEdit;
    }

    get expenseAccountID() {
        return this.ref.data?.expenseAccountID || '';
    }

    cancel = () => {
        this.ref.close(false);
    };

    addExpenseAccount = () => {
        this.loading = true;
        if (this.form.valid) {
            const formValue: IExpenseAccountPayload = this.form.getRawValue();
            const orgID: string = this.userService.organizationId;

            const payload: IExpenseAccountPayload = {
                account: formValue.account || '',
                name: formValue.name || '',
                taxCode: formValue.taxCode || '',
                organization: orgID,
                eaAccount: formValue.eaAccount || '',
            };
            const expenseAccountReq$: Observable<IExpenseAccounts> = this.isEdit
                ? this.expenseAccountService.updateExpenseAccount(this.expenseAccountID, payload)
                : this.expenseAccountService.createExpenseAccount(payload);
            expenseAccountReq$.subscribe({
                next: (res: IExpenseAccounts) => {
                    this.loading = false;
                    this.showToast(
                        EToasterStatus.SUCCESS,
                        this.isEdit ? this.constant.EXPENSE_ACCOUNT_MSG.UPDATE_SUCCESS : this.constant.EXPENSE_ACCOUNT_MSG.SUCCESS
                    );
                    this.ref.close(true);
                },
                error: (err) => {
                    this.showToast(
                        EToasterStatus.DANGER,
                        this.isEdit ? this.constant.EXPENSE_ACCOUNT_MSG.UPDATE_FAILURE : this.constant.EXPENSE_ACCOUNT_MSG.FAILURE
                    );
                    this.loading = false;
                },
            });
        }
    };

    getTaxSetup = () => {
        this.loading = true;

        this.taxSetupService.getTaxes().subscribe({
            next: (res: ITax[]) => {
                this.loading = false;
                this.taxes = res || [];

                if (this.isEdit && this.expenseAccountID) {
                    this.getExpenseAccountByID(this.expenseAccountID);
                }
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    getExpenseAccountByID = (expenseID: string) => {
        this.loading = true;

        if (expenseID) {
            this.expenseAccountService.getExpenseAccountByID(expenseID).subscribe({
                next: (res) => {
                    this.form.patchValue({
                        name: res.name,
                        account: res.account,
                        taxCode: res.taxCode,
                        eaAccount: res.eaAccount,
                    });
                    this.taxCode = res.taxCode;
                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                },
            });
        }
    };

    changeTaxCode = (taxCode: string) => {
        this.taxCode = taxCode;
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }

    formatTaxDisplay = (data: ITax) => {
        return `${data.code} (${data.name} - ${data.tax}%)`;
    };
}
