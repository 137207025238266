import { Component, OnInit } from '@angular/core';
import { AnimationSettingsModel, SpinSettingsModel } from '@syncfusion/ej2-splitbuttons';
import { NgForm } from '@angular/forms';

import { EToasterStatus } from '@core/models';
import { Constant } from '@core/constants';
import { AuthState, IChangePasswordPayload } from '@core/models';
import { AuthService, ToasterService, UserService } from '@core/services';

@Component({
    selector: 'app-profile',
    styleUrls: ['./profile.component.scss'],
    templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
    public submitted = false;
    public userInfo!: AuthState;
    public userName: string = '';
    public initial: string = '';
    public newPassword: string = '';
    public confirmPassword: string = '';
    public oldPassword: string = '';
    public userId: string = '';
    public showPasswordFlag: boolean = false;
    public showOldPasswordFlag: boolean = false;
    public showRePasswordFlag: boolean = false;
    public slideRight: AnimationSettingsModel = { effect: 'SlideRight' };
    public spinCenter: SpinSettingsModel = { position: 'Center' };

    constructor(
        private constant: Constant,
        private authService: AuthService,
        private toasterService: ToasterService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.updateUser();

        this.userService.userNameChanged$.subscribe({
            next: (status: boolean) => {
                if (status) {
                    this.updateUser();
                }
            },
        });
    }

    updateUser = () => {
        const userStr: string | null = localStorage.getItem('auth');
        this.userInfo = userStr ? JSON.parse(userStr) : null;

        const name = this.userInfo?.user?.profile?.name || {
            first: '',
            last: '',
        };

        const first = name.first || '';
        const last = name.last || '';

        this.userName = last ? `${first} ${last}`.trim() : first.trim();
        this.initial = `${first.charAt(0).toUpperCase()}${last.charAt(0).toUpperCase()}`;
        this.userId = this.userInfo?.user?.profile?.id || '';
        this.userService.updateUserNameChangeStatus(false);
    };

    changePass(changePassForm: NgForm) {
        const payload: IChangePasswordPayload = {
            password: ((changePassForm || {}).value || {}).oPassword || '',
            new_password: ((changePassForm || {}).value || {}).password || '',
        };

        this.authService.changePassword(this.userId, payload).subscribe({
            next: () => {
                this.showToast(EToasterStatus.SUCCESS, this.constant.RESET_PASSWORD_MSG.SUCCESS);
                changePassForm.resetForm();
            },
            error: (err) => {
                const errMsg = ((err || {}).error || {}).error || '';
                this.showToast(EToasterStatus.DANGER, errMsg);
                changePassForm.resetForm();
            },
        });
    }

    togglePassword(id: string) {
        if (id === 'input-old-password') {
            this.showOldPasswordFlag = !this.showOldPasswordFlag;
        } else if (id === 'input-password') {
            this.showPasswordFlag = !this.showPasswordFlag;
        } else if (id === 'input-re-password') {
            this.showRePasswordFlag = !this.showRePasswordFlag;
        }

        const passEleType = (<HTMLInputElement>document.getElementById(id)).type;
        if (passEleType === 'password') {
            (<HTMLInputElement>document.getElementById(id)).type = 'text';
        } else if (passEleType === 'text') {
            (<HTMLInputElement>document.getElementById(id)).type = 'password';
        }
    }

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }
}
