import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent, PageSettingsModel, SortService } from '@syncfusion/ej2-angular-grids';
import { DialogService } from '@ngneat/dialog';

import { ITax } from '@core/models';
import { AddTaxSetupComponent } from './add-tax-setup/add-tax-setup.component';
import { TaxSetupService, UserService } from '@core/services';
import { Constant } from '@core/constants';

@Component({
    selector: 'app-tax-setup',
    templateUrl: './tax-setup.component.html',
    styleUrls: ['./tax-setup.component.scss'],
    providers: [SortService],
})
export class TaxSetupComponent implements OnInit {
    @ViewChild('overviewgrid')
    gridInstance!: GridComponent;
    public pageSizes: number[] = [6, 12, 18];
    public pageSettings!: PageSettingsModel;
    public showPaginationFlag: boolean = true;
    public loading: boolean = false;
    public taxes: ITax[] = [];
    public selectedTax: ITax | null = null;
    public isAdminUser: boolean = false;

    constructor(
        private dialog: DialogService,
        private taxSetupService: TaxSetupService,
        private userService: UserService,
        private constant: Constant
    ) {}

    ngOnInit(): void {
        const role: string = this.userService.userRole;
        this.isAdminUser = role === this.constant.USER_ROLES.ADMIN;
        this.getTaxSetup();
    }

    handleTaxSetupDlg = (isEdit: boolean, selectedTax: ITax | null = null, event?: MouseEvent) => {
        event && event.stopPropagation();
        this.selectedTax = selectedTax;

        const dialogRef = this.dialog.open(AddTaxSetupComponent, {
            data: {
                isEdit,
                selectedTax,
            },
        });

        dialogRef.afterClosed$.subscribe((result) => {
            if (result) {
                this.getTaxSetup();
            }
        });
    };

    getTaxSetup = () => {
        this.loading = true;

        this.taxSetupService.getTaxes().subscribe({
            next: (res: ITax[]) => {
                this.loading = false;
                this.taxes = res;
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };
}
