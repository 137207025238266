<div class="expense-account-chart" *ngIf="refreshDashboard">
    <div class="expense-account-chart__title">{{ title }}</div>

    <div echarts [options]="options" [merge]="mergeOptions" class="expense-account-chart__container" *ngIf="transactions.length > 0"></div>

    <div
        *ngIf="transactions.length === 0"
        [ngClass]="[transactions.length === 0 ? 'expense-account-chart__container empty' : 'expense-account-chart__container']"
    >
        <div class="expense-account-chart__container--empty">{{ emptyMessage }}</div>
    </div>
</div>
