import { Component, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';
import { Observable } from 'rxjs';
import { ChangeEventArgs } from '@syncfusion/ej2-dropdowns';
import { SelectEventArgs } from '@syncfusion/ej2-angular-navigations';

import { CommonService, ExpenseAccountService, SupplierService, TaxSetupService, ToasterService, UserService } from '@core/services';
import { EToasterStatus, IECHQAddress, IExpenseAccounts, ISupplier, ISupplierForm, ISupplierPayload, ITax } from '@core/models';
import { Constant } from '@core/constants';

interface Data {
    isEdit: boolean;
    supplierID?: string;
}

@Component({
    selector: 'app-add-new-supplier',
    templateUrl: './add-new-supplier.component.html',
    styleUrls: ['./add-new-supplier.component.scss'],
})
export class AddSupplierComponent implements OnInit {
    public ref: DialogRef<Data, boolean> = inject(DialogRef);
    public supplierForm: FormGroup = new FormGroup({
        first: new FormControl<string>('', [Validators.maxLength(255)]),
        last: new FormControl<string>('', [Validators.maxLength(255)]),
        companyName: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
        nameOnCheque: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
        phone: new FormControl<string>(''),
        extension: new FormControl<string>(''),
        email: new FormControl<string>('', [Validators.pattern(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)]),
        website: new FormControl<string>(''),
        contactName: new FormControl<string>('', [Validators.maxLength(255)]),
        defaultExpenseAccount: new FormControl<string>('', []),
        defaultTaxCode: new FormControl<string>('', []),
        active: new FormControl<boolean>(false, []),
    });
    public headerText: { text: string }[] = [{ text: 'Supplier Details' }, { text: 'Address' }];
    public showSettingFlag: boolean = false;
    public defaultExpenseAccount: string = '';
    public defaultTaxCode: string = '';
    public isSupplierActive: boolean = true;
    public loading: boolean = false;
    public expenseAccounts: IExpenseAccounts[] = [];
    public taxes: ITax[] = [];
    public fields: { text: string; value: string } = {
        text: 'name',
        value: 'id',
    };
    public taxFields: { text: string; value: string; tax: string } = { text: 'name', value: 'code', tax: 'tax' };
    public supplierID: string = '';
    public addressFormData!: IECHQAddress;
    public initialFormValue!: ISupplierForm;
    public initialAddressFormData!: IECHQAddress;
    public selectedTabIndex: number = 0;

    constructor(
        private formBuilder: FormBuilder,
        private expenseAccountService: ExpenseAccountService,
        private taxSetupService: TaxSetupService,
        private supplierService: SupplierService,
        private userService: UserService,
        private toasterService: ToasterService,
        private commonService: CommonService,
        private constant: Constant
    ) {
        this.supplierForm = this.formBuilder.group({
            first: ['', [Validators.maxLength(255)]],
            last: ['', [Validators.maxLength(255)]],
            companyName: ['', [Validators.required, Validators.maxLength(255)]],
            nameOnCheque: ['', [Validators.required, Validators.maxLength(255)]],
            email: ['', [Validators.pattern(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)]],
            phone: [''],
            extension: [''],
            website: [''],
            contactName: ['', [Validators.maxLength(255)]],
            defaultExpenseAccount: ['', []],
            defaultTaxCode: ['', []],
            active: [true, []],
        });

        this.supplierID = this.ref.data?.supplierID || '';
    }

    ngOnInit(): void {
        this.getExpenseAccounts();
    }

    get supplierFormF(): { [key: string]: AbstractControl } {
        return this.supplierForm.controls;
    }

    get title() {
        return this.ref.data.isEdit ? 'Edit Supplier' : 'Add New Supplier';
    }

    get isEdit() {
        return this.ref.data?.isEdit;
    }

    getSupplierAddress = (supplierID: string) => {
        this.loading = true;
        this.supplierService.getSupplierAddress(supplierID).subscribe({
            next: (data) => {
                this.loading = false;

                if (data) {
                    this.addressFormData = data;
                    this.initialAddressFormData = data;
                    delete this.addressFormData.id;
                    delete this.initialAddressFormData.id;
                }
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    getSupplierInfo = () => {
        this.loading = true;
        this.supplierService.getSupplierInfo(this.supplierID).subscribe({
            next: (res: ISupplier) => {
                this.loading = false;
                this.bindFormData(res);
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    bindFormData = (formData: ISupplier) => {
        this.supplierForm.patchValue({
            first: formData.name.first || '',
            last: formData.name.last || '',
            companyName: formData.companyName || '',
            nameOnCheque: formData.nameOnCheque || '',
            email: formData.email || '',
            phone: formData?.phone?.number || '',
            extension: formData?.phone?.extension || '',
            website: formData?.website || '',
            contactName: formData?.contactName || '',
            defaultExpenseAccount: formData.defaultExpenseAccount || '',
            defaultTaxCode: formData.defaultTaxCode || '',
            active: formData.active || false,
        });
        this.defaultTaxCode = formData.defaultTaxCode || '';
        this.defaultExpenseAccount = formData.defaultExpenseAccount || '';
        this.initialFormValue = this.supplierForm.value;
    };

    checkFormModified() {
        const currentFormValue: ISupplierForm = this.supplierForm.value;
        return !this.commonService.isEqual(currentFormValue, this.initialFormValue);
    }

    getExpenseAccounts = () => {
        this.loading = true;
        this.expenseAccountService.getExpenseAccounts().subscribe({
            next: (res: IExpenseAccounts[]) => {
                this.loading = false;
                this.expenseAccounts = res;
                this.getTaxes();
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    getTaxes = () => {
        this.loading = true;
        this.taxSetupService.getTaxes().subscribe({
            next: (res: ITax[]) => {
                this.loading = false;
                this.taxes = res;

                if (this.supplierID) {
                    this.getSupplierAddress(this.supplierID);
                    this.getSupplierInfo();
                }

                const userRole: string = this.userService.userRole;
                if (userRole === this.constant.USER_ROLES.EMP) {
                    this.supplierForm.disable();
                }
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    cancel = () => {
        this.ref.close(false);
    };

    createPayload = (): ISupplierPayload => {
        const formValue = this.supplierForm.getRawValue();
        const orgID: string = this.userService.organizationId;

        const payload: ISupplierPayload = {
            active: formValue.active || false,
            website: formValue.website || '',
            email: formValue.email || '',
            nameOnCheque: formValue.nameOnCheque || '',
            defaultTaxCode: formValue.defaultTaxCode || '',
            defaultExpenseAccount: formValue.defaultExpenseAccount || '',
            contactName: formValue.contactName || '',
            companyName: formValue.companyName || '',
            organization: orgID,
            name: {
                first: formValue.first || '',
                last: formValue.last || '',
            },
            phone: {
                number: formValue.phone || '',
                extension: formValue.extension || '',
            },
        };

        return payload;
    };

    updateSupplier = () => {
        this.loading = true;
        const payload: ISupplierPayload = this.createPayload();
        const supplierReq$: Observable<ISupplier> = this.isEdit
            ? this.supplierService.updateSupplier(this.supplierID, payload)
            : this.supplierService.createSupplier(payload);

        supplierReq$.subscribe({
            next: (res: ISupplier) => {
                this.loading = false;
                this.initialFormValue = this.supplierForm.value;
                if (this.isEdit && !this.commonService.isEqual(this.addressFormData, this.initialAddressFormData)) {
                    this.handleAddressSubmit(this.addressFormData);
                } else {
                    this.ref.close(true);
                    this.showToast(
                        EToasterStatus.SUCCESS,
                        this.isEdit ? this.constant.SUPPLIER_CRUD_MSG.UPDATE_SUCCESS : this.constant.SUPPLIER_CRUD_MSG.SUCCESS
                    );
                }
            },
            error: () => {
                this.loading = false;
                this.showToast(
                    EToasterStatus.DANGER,
                    this.isEdit ? this.constant.SUPPLIER_CRUD_MSG.UPDATE_FAILURE : this.constant.SUPPLIER_CRUD_MSG.FAILURE
                );
            },
        });
    };

    changeDefaultExpenseAccount = (event: ChangeEventArgs) => {
        const expenseAccount: string = event.value ? `${event.value}` : '';
        this.defaultExpenseAccount = expenseAccount;
        const selectedExpenseAccount: IExpenseAccounts | null =
            this.expenseAccounts.find((account) => account.id === expenseAccount) || null;

        this.defaultTaxCode = selectedExpenseAccount?.taxCode || '';
        this.supplierForm.patchValue({
            defaultTaxCode: selectedExpenseAccount?.taxCode || null,
        });
    };

    createAddressPayload = (data: IECHQAddress): IECHQAddress => {
        const actionPayload: IECHQAddress = {
            firstLine: (data || {}).firstLine || '',
            lastLine: (data || {}).lastLine || '',
            city: data.city,
            state: data.state,
            country: data.country,
            zipCode: data.zipCode,
        };
        return actionPayload;
    };

    handleAddressFormCancel = (event: IECHQAddress | null) => {
        this.ref.close(false);
    };

    handleAddressSubmit = (address: IECHQAddress) => {
        this.addressFormData = address;
        const isSupplierDetailsModified: boolean = this.checkFormModified();

        if (address && this.supplierForm.valid) {
            this.loading = true;
            const payload: IECHQAddress = this.createAddressPayload(address);
            this.supplierService.updateAddress(this.supplierID, payload).subscribe({
                next: (res: IECHQAddress) => {
                    this.loading = false;
                    this.initialAddressFormData = this.addressFormData;
                    if (this.isEdit && isSupplierDetailsModified) {
                        this.updateSupplier();
                    } else {
                        this.ref.close(true);
                        this.showToast(EToasterStatus.SUCCESS, this.constant.SUPPLIER_CRUD_MSG.UPDATE_SUCCESS);
                    }
                },
                error: (err) => {
                    this.loading = false;
                    this.showToast(EToasterStatus.DANGER, this.constant.SUPPLIER_CRUD_MSG.UPDATE_FAILURE);
                },
            });
        }
    };

    handleTabChange = (event: SelectEventArgs) => {
        this.selectedTabIndex = event.selectedIndex;
    };

    handleAddressFormUpdated = (addressForm: FormGroup) => {
        this.addressFormData = addressForm.value;
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }

    formatTaxDisplay = (data: ITax) => {
        return `${data.code} (${data.name} - ${data.tax}%)`;
    };
}
