import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent, ComingSoonComponent } from './pages';

@NgModule({
    declarations: [HomeComponent, ComingSoonComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class HomeModule {}
