import { IAddress, IECHQAddress } from './organization';

export class CompanyDetails {
    id?: string = '';
    name = '';
    legalName = '';
    admins: {
        email: string;
        name: {
            first: string;
            middle: string;
            last: string;
        };
    }[] = [{ email: '', name: { first: '', middle: '', last: '' } }];
    website = '';
    phone: {
        number: string;
        extension: string;
    } = { number: '', extension: '' };
    address?: {
        id: string;
        address: string;
        city: string;
        state: string;
        country: string;
        zip: string;
    } = {
        id: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
    };

    constructor(init?: Partial<CompanyDetails>) {
        Object.assign(this, init);
    }
}

export interface ICompanyDetailsForm {
    companyName: string;
    legalName: string;
    website: string;
    phone: string;
    extension: string;
    email: string;
}

export interface IBankAccounts {
    id: string;
    organization: string;
    deleted: boolean;
    name: string;
    account: string;
    chequeSequence: string;
    created: string;
    modified: string;
    eaAccount?: string;
    address?: IECHQAddress;
    supplierName?: string;
}

export interface IBankAccountPayload {
    organization: string;
    name: string;
    account: string;
    chequeSequence: string;
    eaAccount: string;
    address: IECHQAddress;
}

export interface IExpenseAccounts {
    account: string;
    created?: string;
    deleted?: boolean;
    id: string;
    modified?: string;
    name: string;
    organization: string;
    taxCode: string;
    eaAccount: string;
    taxCodeName?: string;
}

export interface IExpenseAccountPayload {
    name: string;
    taxCode: string;
    account: string;
    organization: string;
    eaAccount: string;
}

export interface ITax {
    name: string;
    tax: number;
    code: string;
}

export interface IUserPayload {
    email: string;
    name: {
        first: string;
        middle: string;
        last: string;
    };
    phones: [
        {
            number: string;
            extension: string;
        }
    ];
    organization: string;
    details: {
        birthday: string;
        hireDate: string;
        salary: string;
        title: string;
        gender: string;
        notes: string;
        terminationDate: string;
    };
    role: string;
    active: boolean;
    reportingTo: string;
    revokeAccess: boolean;
}

export interface IUserForm {
    firstName: string;
    lastName: string;
    middleName: string;
    phone: string;
    extension: string;
    email: string;
    confirmEmail: string;
    active: boolean;
    revokeAccess: boolean;
    role: string;
}
