<div class="add-tax-setup">
    <div class="add-tax-setup__title">{{ title }}</div>

    <div class="add-tax-setup__body">
        <form id="reactive" [formGroup]="form" class="add-tax-setup__form form">
            <div class="form__group required">
                <ejs-textbox
                    placeholder="Tax Name"
                    floatLabelType="Always"
                    formControlName="name"
                />

                <ng-container
                    *ngIf="
                        formControlF['name'].touched &&
                        formControlF['name'].errors
                    "
                >
                    <p
                        class="caption status-danger"
                        *ngIf="formControlF['name'].errors['required']"
                    >
                        Tax Name is required.
                    </p>

                    <p
                        class="caption status-danger"
                        *ngIf="formControlF['name'].errors['maxlength']"
                    >
                        Tax Name can't be more than 50 characters.
                    </p>
                </ng-container>
            </div>

            <div class="form__group required">
                <ejs-numerictextbox
                    cssClass="e-style"
                    placeholder="Tax %"
                    floatLabelType="Always"
                    formControlName="tax"
                    decimals="2"
                    format="n2"
                    [required]="true"
                />

                <ng-container
                    *ngIf="
                        formControlF['tax'].touched &&
                        formControlF['tax'].errors
                    "
                >
                    <p
                        class="caption status-danger"
                        *ngIf="formControlF['tax'].errors['required']"
                    >
                        Tax % is required.
                    </p>
                </ng-container>
            </div>

            <div class="form__group cheque-sequence required">
                <ejs-textbox
                    placeholder="Code"
                    floatLabelType="Always"
                    formControlName="code"
                />

                <ng-container
                    *ngIf="
                        formControlF['code'].touched &&
                        formControlF['code'].errors
                    "
                >
                    <p
                        class="caption status-danger"
                        *ngIf="formControlF['code'].errors['required']"
                    >
                        code is required.
                    </p>
                    <p
                        class="caption status-danger"
                        *ngIf="formControlF['code'].errors['maxlength']"
                    >
                        code can't be more than 3 characters.
                    </p>
                </ng-container>
            </div>

            <div class="form__footer">
                <button
                    [ngClass]="['e-btn e-outline e-danger']"
                    id="cancelBtn"
                    content="Cancel"
                    (click)="cancel()"
                >
                    Cancel
                </button>

                <button
                    [ngClass]="[
                        'e-btn e-outline',
                        form.valid ? 'e-success' : ''
                    ]"
                    id="cancelBtn"
                    content="Save"
                    (click)="addTaxSetup()"
                    [disabled]="!form.valid"
                >
                    Save
                </button>
            </div>
        </form>
    </div>
</div>
