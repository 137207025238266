import { combineReducers } from '@ngrx/store';

import * as fromAuth from './auth.reducers';
import { AuthState } from '@core/models';

export interface AuthFeatureState {
    auth: AuthState;
}

export const authFeatureReducer = combineReducers<AuthFeatureState>({
    auth: fromAuth.authReducer,
});
