import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent, PageSettingsModel, RowSelectEventArgs, SortService } from '@syncfusion/ej2-angular-grids';
import { DialogService } from '@ngneat/dialog';

import { IExpenseAccounts, ITax, User } from '@core/models';
import { ExpenseAccountService, TaxSetupService, UserService } from '@core/services';
import { AddExpenseAccountsComponent } from './add-expense-accounts/add-expense-accounts.component';
import { Constant } from '@core/constants';

@Component({
    selector: 'app-expense-accounts',
    templateUrl: './expense-accounts.component.html',
    styleUrls: ['./expense-accounts.component.scss'],
    providers: [SortService],
})
export class ExpenseAccountsComponent implements OnInit {
    @ViewChild('overviewgrid')
    gridInstance!: GridComponent;
    public pageSizes: number[] = [6, 12, 18];
    public pageSettings!: PageSettingsModel;
    public expenseAccounts!: IExpenseAccounts[];
    public showPaginationFlag: boolean = true;
    public loading: boolean = false;
    public expenseAccountID: string = '';
    public isAdminUser: boolean = false;
    public taxes: ITax[] = [];

    constructor(
        private dialog: DialogService,
        private expenseAccountService: ExpenseAccountService,
        private constant: Constant,
        private userService: UserService,
        private taxSetupService: TaxSetupService
    ) {}

    ngOnInit(): void {
        const role: string = this.userService.userRole;
        this.isAdminUser = role === this.constant.USER_ROLES.ADMIN;
        this.pageSettings = { pageCount: 3, pageSize: this.pageSizes[0] };
        this.getTaxSetup();
    }

    getTaxSetup = () => {
        this.taxSetupService.getTaxes().subscribe({
            next: (res: ITax[]) => {
                this.taxes = res || [];
                this.getExpenseAccounts();
            },
        });
    };

    getExpenseAccounts = () => {
        this.loading = false;

        this.expenseAccountService.getExpenseAccounts().subscribe({
            next: (res: IExpenseAccounts[]) => {
                if (res) {
                    this.expenseAccounts = res.map((item) => {
                        const taxCode: ITax | null = this.taxes.find((tax) => tax.code === item.taxCode) || null;
                        return {
                            ...item,
                            taxCodeName: taxCode?.name || '',
                        };
                    });
                }
                this.loading = false;
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    rowSelected(args: RowSelectEventArgs) {
        if (this.isAdminUser) {
            this.expenseAccountID = (args.data as unknown as IExpenseAccounts)?.id || '';
            this.handleExpenseAccountDialog(true, this.expenseAccountID);
        }
    }

    handleExpenseAccountDialog = (isEdit: boolean, expenseID = '') => {
        const dialogRef = this.dialog.open(AddExpenseAccountsComponent, {
            data: {
                isEdit,
                expenseAccountID: expenseID,
            },
        });

        dialogRef.afterClosed$.subscribe((result) => {
            if (result) {
                this.getExpenseAccounts();
            }
        });
    };
}
