import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BnNgIdleService } from 'bn-ng-idle';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { provideDialogConfig } from '@ngneat/dialog';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressButtonAllModule } from '@syncfusion/ej2-angular-splitbuttons';

import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import * as fromStore from '@core/store';
import { StartupService } from '@core/services';
import { CompanySettingsModule, HomeModule } from '@pages/index';
import { HttpRequestInterceptor } from '@core/interceptors';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from '@shared/components';

export function startupServiceFactory(startupService: StartupService) {
    return () => startupService.load();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        fromStore.AppStoreModule,
        CoreModule.forRoot(),
        ProgressButtonAllModule,
        SharedModule,
        ToastModule,
        HomeModule,
        CompanySettingsModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
        BnNgIdleService,
        provideDialogConfig({
            closeButton: false,
            enableClose: {
                escape: false,
                backdrop: false,
            },
            backdrop: true,
            resizable: false,
            draggable: false,
        }),
    ],
    bootstrap: [AppComponent, LoaderComponent],
})
export class AppModule {}
