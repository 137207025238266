import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';

import { IECHQAddress } from '@core/models';
import { Address, Constant } from '@core/constants';
import { UserService } from '@core/services';
import { ChangeEventArgs } from '@syncfusion/ej2-dropdowns';

@Component({
    selector: 'app-address-form',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit, OnChanges {
    public country: string = '';
    public state: string = '';
    public zip: string = '';
    public countryList: string[] = [];
    public provinceList: string[] = [];
    public stateList: string[] = [];
    public provinceFlag: boolean = false;
    public usaCountryFlag: boolean = false;
    public selectedState: string = '';
    @Input() parentForm: FormGroup | null = null;
    @Input() formData: IECHQAddress | null = null;
    @Input() isVisible: boolean = false;
    @Input() isInlineForm: boolean = false;
    @Output() cancelAddress: EventEmitter<IECHQAddress | null> = new EventEmitter();
    @Output() submitAddress: EventEmitter<IECHQAddress> = new EventEmitter();
    @Output() addressFormUpdated: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    public addressForm: FormGroup = new FormGroup({
        firstLine: new FormControl<string>('', [Validators.required]),
        lastLine: new FormControl<string>('', []),
        city: new FormControl<string>('', [Validators.required]),
        country: new FormControl<string>('', [Validators.required]),
        state: new FormControl<string>('', [Validators.required]),
        zipCode: new FormControl<string>('', [Validators.required]),
    });

    constructor(private formBuilder: FormBuilder, private address: Address, private userService: UserService, private constant: Constant) {
        this.countryList = this.address.COUNTRYLIST;
        this.provinceList = this.address.PROVINCES;
        this.stateList = this.address.STATES;

        this.addressForm = this.formBuilder.group({
            firstLine: ['', [Validators.required]],
            lastLine: ['', []],
            city: ['', [Validators.required]],
            country: ['', [Validators.required]],
            state: ['', [Validators.required]],
            zipCode: ['', [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.updateForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isInlineForm && changes['formData'] && 'currentValue' in changes['formData'] && changes['formData'].currentValue) {
            this.stateList = this.address.STATES;
            this.formData = changes['formData'].currentValue;
            this.updateForm();
        } else if (changes && changes['isVisible'] && !changes['isVisible'].currentValue) {
            if (this.addressForm.valid) {
                this.addressFormUpdated.emit(this.addressForm);
            }
        }
    }

    get addressFormF(): { [key: string]: AbstractControl } {
        return this.addressForm.controls;
    }

    updateForm = () => {
        this.bindAddressData(this.formData);

        const userRole: string = this.userService.userRole;
        if (userRole === this.constant.USER_ROLES.EMP) {
            this.addressForm.disable();
        }

        if (this.isInlineForm) {
            this.addressFormUpdated.emit(this.addressForm);
        }
    };

    bindAddressData = (data: IECHQAddress | null) => {
        if (data) {
            const c: string = (data || {}).country || '';

            this.checkCountrySelectionForBind(c);
            this.addressForm.patchValue({
                firstLine: (data || {}).firstLine || '',
                lastLine: (data || {}).lastLine || '',
                city: (data || {}).city || '',
                state: (data || {}).state || '',
                country: (data || {}).country || '',
                zipCode: (data || {}).zipCode || '',
            });
            this.country = c;
            this.state = (data || {}).state || '';
            this.selectedState = (data || {}).state || '';
        }
    };

    get addressDetailsF(): { [key: string]: AbstractControl } {
        return this.addressForm.controls;
    }

    changeCountry = (event: ChangeEventArgs) => {
        this.checkCountrySelection(event.value as string);
        this.updateAddress();
    };

    checkCountrySelection(value: string) {
        this.usaCountryFlag = false;
        this.provinceFlag = false;
        this.state = '';
        this.zip = '';

        if (value == 'Canada') {
            this.provinceFlag = true;
        } else if (value == 'United States of America') {
            this.usaCountryFlag = true;
        }
    }

    checkCountrySelectionForBind(value: string) {
        this.usaCountryFlag = false;
        this.provinceFlag = false;

        if (value == 'Canada') {
            this.provinceFlag = true;
        } else if (value == 'United States of America') {
            this.usaCountryFlag = true;
        }
    }

    cancel = () => {
        this.cancelAddress.emit(null);
    };

    updateAddress = () => {
        if (this.addressForm.valid) {
            this.submitAddress.emit(this.addressForm.getRawValue());
        }
    };
}
