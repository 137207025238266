import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent, RowSelectEventArgs, PageSettingsModel, SortService } from '@syncfusion/ej2-angular-grids';
import { DialogService } from '@ngneat/dialog';

import { IBankAccounts } from '@core/models';
import { AddBankAccountsComponent } from './add-bank-accounts/add-bank-accounts.component';
import { BankAccountService, UserService } from '@core/services';
import { Constant } from '@core/constants';

@Component({
    selector: 'app-bank-accounts',
    templateUrl: './bank-accounts.component.html',
    styleUrls: ['./bank-accounts.component.scss'],
    providers: [SortService],
})
export class BankAccountsComponent implements OnInit, AfterViewInit {
    @ViewChild('overviewgrid')
    gridInstance!: GridComponent;
    public pageSizes: number[] = [6, 12, 18];
    public pageSettings!: PageSettingsModel;
    public bankAccounts: IBankAccounts[] = [];
    public showPaginationFlag: boolean = true;
    public loading: boolean = false;
    public isAdminUser: boolean = false;

    constructor(
        private dialog: DialogService,
        private bankAccountService: BankAccountService,
        private userService: UserService,
        private constant: Constant
    ) {}

    ngOnInit(): void {
        const role: string = this.userService.userRole;
        this.isAdminUser = role === this.constant.USER_ROLES.ADMIN;
        this.getBankAccounts();
    }

    ngAfterViewInit(): void {
        this.pageSettings = { pageCount: 3, pageSize: this.pageSizes[0] };
    }

    getBankAccounts = () => {
        this.loading = true;
        this.bankAccountService.getBankAccounts().subscribe({
            next: (data) => {
                this.bankAccounts = data;
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    openAddNewBankAccount = () => {
        const dialogRef = this.dialog.open(AddBankAccountsComponent, {
            data: {
                isEdit: false,
            },
        });

        dialogRef.afterClosed$.subscribe((result) => {
            if (result) {
                this.getBankAccounts();
            }
        });
    };

    rowSelected(args: RowSelectEventArgs) {
        if (this.isAdminUser) {
            this.handleEditBankAccount((args.data as unknown as IBankAccounts)?.id);
        }
    }

    handleEditBankAccount = (id: string) => {
        const dialogRef = this.dialog.open(AddBankAccountsComponent, {
            data: {
                isEdit: true,
                backAccountID: id,
            },
        });

        dialogRef.afterClosed$.subscribe((result) => {
            if (result) {
                this.getBankAccounts();
            }
        });
    };
}
