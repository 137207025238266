<div class="header d-flex align-items-center justify-content-space-between">
    <div class="header__logo d-flex align-items-center">
        <a
            (click)="toggleSidebar()"
            href="javascript:void(0)"
            class="header__menu d-flex align-items-center"
        >
            <span class="e-icons e-menu"></span>
        </a>

        <img
            class="header__logo--img"
            (click)="goToDashboard()"
            (keypress)="handleKeyPress($event)"
            src="assets/images/logo.png"
            alt="App logo"
            height="1%"
            width="90%"
            tabindex="1"
        />
    </div>

    <div class="header__user">
        <div class="profile-dropdown" #dropdownMenu>
            <button class="profile-dropdown__button" (click)="toggleDropdown()">
                <div class="profile-dropdown__initials">
                    {{ getUserInitials(userName) }}
                </div>
                <span class="profile-dropdown__username">{{ userName }}</span>
            </button>

            <ul class="profile-dropdown__menu" *ngIf="isDropdownOpen">
                <li
                    class="profile-dropdown__menu-item"
                    *ngFor="let item of userMenu"
                >
                    <a
                        href="javascript:void(0)"
                        (click)="handleHeaderDropdown(item)"
                        >{{ item.title }}</a
                    >
                </li>
            </ul>
        </div>
    </div>
</div>
