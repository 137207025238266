import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { environment } from '@environments/environment';
import { Constant } from '@core/constants';
import { AuthState } from '@core/models';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(private constant: Constant, private datePipe: DatePipe, private http: HttpClient) {}

    getToken(): string {
        const userStr: string | null = localStorage.getItem('auth');
        const userInfo: AuthState = userStr ? JSON.parse(userStr) : null;
        return ((userInfo || {}).user || {}).token || '';
    }

    getHttpOptions() {
        const token = this.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }),
        };
        return httpOptions;
    }

    getBaseURL = (version: string, service: string): string => {
        if (version && service) {
            if (location.origin == this.constant.DOMAIN_URLS.DEV) {
                return `${environment.dev_url}/${version}/${service}`;
            } else if (location.origin == this.constant.DOMAIN_URLS.STAGING) {
                return `${environment.staging_url}/${version}/${service}`;
            } else if (location.origin == this.constant.DOMAIN_URLS.PRODUCTION) {
                return `${environment.production_url}/${version}/${service}`;
            } else if (location.origin == this.constant.DOMAIN_URLS.LOCAL) {
                return `${environment.dev_url}/${version}/${service}`;
            }
        }

        return '';
    };

    convertToUserTz = (dateStr: string, format = 'dd-MM-yyyy'): string | null => {
        if (dateStr) {
            // const timezoneOffset: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const dt: DateTime = DateTime.fromISO(dateStr, { zone: 'utc' });

            const day: number = dt.day;
            const month: number = dt.month;
            const year: number = dt.year;

            // Format the date components
            const formattedDate: string = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
            return formattedDate;
            // return this.datePipe.transform(dateStr, format, timezoneOffset.toString());
        }
        return '';
    };

    getPDFFile = (fileURL: string) => {
        return this.http.get(fileURL, { responseType: 'blob' });
    };

    isEqual<T extends object>(obj1: T, obj2: T): boolean {
        if (obj1 && obj2) {
            const keys: (keyof T)[] = Object.keys(obj1) as (keyof T)[];

            for (let key of keys) {
                const val1 = obj1[key];
                const val2 = obj2[key];

                if (typeof val1 === 'object' && typeof val2 === 'object') {
                    if (val1 && val2 && !this.isEqual(val1, val2)) {
                        return false;
                    }
                } else if (val1 !== val2) {
                    return false;
                }
            }

            return true;
        } else if (!obj1 && !obj2) {
            return true;
        }

        return false;
    }
}
