import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { EChartsOption } from 'echarts';

import { ICheque, IExpenseAccounts, IMeta } from '@core/models';
import { ChequeService, ExpenseAccountService } from '@core/services';

@Component({
    selector: 'app-expense-account-chart',
    styleUrls: ['./expense-account-chart.component.scss'],
    templateUrl: './expense-account-chart.component.html',
})
export class ExpenseAccountChartComponent implements AfterViewInit, OnInit {
    @Input() refreshDashboard = false;
    public transactions: ICheque[] = [];
    public title?: string = 'Cheque Amounts - Last 3 Months';
    public expenseAccounts: IExpenseAccounts[] = [];
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public colors: string[] = [
        '#EC4954',
        '#725CD4',
        '#4AA3C8',
        '#4577F8',
        '#F19973',
        '#ED5C52',
        '#EF8B96',
        '#6A7C95',
        '#8D7EDC',
        '#80C9DC',
        '#6992F9',
        '#F4AE90',
        '#EE7773',
        '#0C2550',
    ];
    public options!: EChartsOption;
    public mergeOptions!: EChartsOption;
    public emptyMessage = 'No expenses available with valid expense accounts in the last three months';

    constructor(private expenseAccountService: ExpenseAccountService, private chequeService: ChequeService) {
        this.fromDate = DateTime.local().minus({ months: 3 }).toJSDate();
        this.toDate = new Date();
    }

    ngOnInit(): void {
        this.options = {
            legend: {
                bottom: 0,
            },
            tooltip: {
                valueFormatter: (value) => '$' + (value as number).toFixed(2),
            },
            dataset: {
                source: [],
            },
            xAxis: { type: 'category' },
            yAxis: { type: 'value', axisLine: { show: true } },
            series: [],
        };
    }

    ngAfterViewInit(): void {
        this.getLastThreeMonthCheques();
    }

    objectValuesToArrayExceptField<T>(obj: Record<string, T>, fieldToExclude: string): T[] {
        const valuesArray: T[] = [];

        for (const key in obj) {
            if (key !== fieldToExclude) {
                valuesArray.push(obj[key]);
            }
        }

        return valuesArray;
    }

    getLastThreeMonthCheques = () => {
        let qString = '';

        if (this.fromDate && this.toDate && this.convertJSDateToUTC(this.fromDate) && this.convertJSDateToUTC(this.toDate)) {
            qString = `fromDate=${this.convertJSDateToUTC(this.fromDate)}&toDate=${this.convertJSDateToUTC(this.toDate)}`;
        }

        this.chequeService.getCheques(qString).subscribe({
            next: (res: { content: ICheque[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.transactions = res.content;
                    this.getExpenseAccounts();
                }
            },
            error: () => {
                this.transactions = [];
            },
        });
    };

    convertJSDateToUTC = (jsDate: Date) => {
        if (jsDate) {
            return jsDate.toISOString().slice(0, 10) + 'T00:00:00.000Z';
        }

        return '';
    };

    getExpenseAccounts = () => {
        this.expenseAccountService.getExpenseAccounts().subscribe({
            next: (res: IExpenseAccounts[]) => {
                this.expenseAccounts = res;
                const source = [['product', ...this.expenseAccounts.map((expenseAccount) => expenseAccount.name)]];
                const transactionList: ICheque[] = this.transactions;

                transactionList.forEach((item: ICheque) => {
                    item['month'] = this.getMonthFromDate(item.transactionDate);
                });

                const lastThreeMonths: string[] = this.getLastThreeMonths();
                lastThreeMonths.forEach((month) => {
                    const monthlyTotalAmounts: any[] = [];

                    this.expenseAccounts.forEach((expense: IExpenseAccounts) => {
                        const totalAmount: number = transactionList
                            .filter(
                                (obj) =>
                                    obj.month === month &&
                                    obj?.amountBreakdown &&
                                    obj?.amountBreakdown.find((bd) => bd.expenseAccount === expense.id)
                            )
                            .map((obj) => {
                                obj['totalExpenseAmount'] = obj?.amountBreakdown
                                    ? obj?.amountBreakdown
                                        .filter((bd) => bd.expenseAccount === expense.id)
                                        .map((obj) => obj.amount)
                                        .reduce((curr, prev) => curr + prev, 0)
                                    : 0;
                                return obj['totalExpenseAmount'];
                            })
                            .reduce((curr, prev) => curr + prev, 0);
                        monthlyTotalAmounts.push(totalAmount);
                    });
                    source.push([month, ...monthlyTotalAmounts]);
                });

                this.mergeOptions = {
                    dataset: {
                        source,
                    },
                    series: this.expenseAccounts.map((bankAccount, index) => ({
                        type: 'bar',
                        itemStyle: {
                            color: this.colors[index],
                        },
                    })),
                };
            },
        });
    };

    getMonthFromDate = (dateString: string): string => {
        if (this.isValidDateString(dateString)) {
            const dateTime = DateTime.fromISO(dateString, { zone: 'utc' });
            return dateTime.toFormat('LLL');
        }

        return '';
    };

    getLastThreeMonths = (): string[] => {
        const today: DateTime = DateTime.now();
        const months: string[] = [];

        for (let i = 0; i < 3; i++) {
            const lastMonth = today.minus({ months: i });
            const formattedMonth = lastMonth.toFormat('LLL');
            months.unshift(formattedMonth);
        }

        return months;
    };

    isValidDateString = (dateString: string) => {
        try {
            DateTime.fromISO(dateString, { zone: 'utc' });
            return true;
        } catch (error) {
            return false;
        }
    };
}
