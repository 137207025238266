import { Component, OnInit } from '@angular/core';

import { MenuService } from '@core/services';

@Component({
    selector: 'app-company-settings',
    styleUrls: ['./company-settings.component.scss'],
    templateUrl: './company-settings.component.html',
})
export class CompanySettingsComponent implements OnInit {
    constructor(private menuService: MenuService) {}

    ngOnInit(): void {
        this.menuService.updateMenuSelection('company-settings');
    }
}
