<div class="bank-accounts">
    <div class="bank-accounts__header header d-flex align-items-center justify-content-space-between">
        <div class="header__left">
            <div class="header__title">Bank Accounts</div>
        </div>

        <div class="header__right">
            <button
                [ngClass]="['e-btn e-small e-outline e-success']"
                id="addNewBtn"
                content="Add New"
                (click)="openAddNewBankAccount()"
                *ngIf="isAdminUser"
            >
                Add New
            </button>
        </div>
    </div>

    <div class="bank-accounts__body">
        <ejs-grid
            #overviewgrid
            id="overviewgrid"
            [dataSource]="bankAccounts"
            rowHeight="40"
            height="240"
            [allowSorting]="true"
            [allowPaging]="showPaginationFlag"
            [pageSettings]="pageSettings"
            (rowSelected)="rowSelected($event)"
            class="body-font"
        >
            <e-columns>
                <e-column field="name" headerText="Bank Name"></e-column>
                <e-column field="account" headerText="Account Number"></e-column>
                <e-column field="chequeSequence" headerText="Cheque Sequence Number"></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>
