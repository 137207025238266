import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

import { AuthenticationActionTypes, login, loginSuccess, loginFailed } from '../actions/auth.actions';
import { ILoginResponse, AuthService, UserService, EmployeeService, Constant } from '../dependencies';
import { EToasterStatus } from '@app/core/models';
import { ToasterService } from '@app/core/services';

@Injectable()
export class AuthenticationEffects {
    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private userService: UserService,
        private employeeService: EmployeeService,
        private toasterService: ToasterService,
        private constants: Constant,
        private router: Router
    ) {}

    public onLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActionTypes.LOGIN),
            map((action: ReturnType<typeof login>) => action.payload),
            switchMap((payload) => {
                return this.authService.login(payload.email, payload.password).pipe(
                    map((payload: ILoginResponse) => loginSuccess({ payload })),
                    catchError((error) => {
                        const errorMsg = ((error || {}).error || {}).error || '';
                        this.showToast(EToasterStatus.DANGER, errorMsg ? errorMsg : this.constants.TOAST_ACTION.FAILURE);
                        return of(loginFailed(error));
                    })
                );
            })
        )
    );

    public onLoginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.getEmployee();
                })
            ),
        { dispatch: false }
    );

    public onLoginFailure$ = createEffect(() => this.actions$.pipe(ofType(AuthenticationActionTypes.LOGIN_FAILURE)), { dispatch: false });

    public onLogout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActionTypes.LOGOUT),
                tap(() => {
                    localStorage.clear();
                    const url = this.constants.REDIRECT_LINKS.LOGIN;
                    this.router.navigateByUrl(url);
                })
            ),
        { dispatch: false }
    );

    getEmployee(): void {
        this.employeeService.getEmployee().subscribe({
            next: (data) => {
                this.userService.setUserDetails(data);
                const url = this.constants.API_URLS.DASHBOARD;
                this.router.navigateByUrl(url);
            },
            error: (err) => {
                const url = this.constants.API_URLS.DASHBOARD;
                this.router.navigateByUrl(url);
            },
        });
    }

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }
}
