<div class="users">
    <div class="users__header header d-flex align-items-center justify-content-space-between">
        <div class="header__left">
            <div class="header__title">Users</div>
        </div>

        <div class="header__right">
            <button
                [ngClass]="['e-btn e-small e-outline e-success']"
                id="addNewBtn"
                content="Add New"
                (click)="handleUserDialog(false)"
                *ngIf="isAdminUser"
            >
                Add New
            </button>
        </div>
    </div>

    <div class="users__body">
        <ejs-grid
            #overviewgrid
            id="overviewgrid"
            [dataSource]="users"
            rowHeight="50"
            height="240"
            [allowSorting]="true"
            [allowPaging]="showPaginationFlag"
            [pageSettings]="pageSettings"
            (rowSelected)="rowSelected($event)"
            class="body-font"
        >
            <e-columns>
                <e-column field="name" headerText="Name" [allowFiltering]="false">
                    <ng-template #template let-data>
                        <div class="employee-avatar">
                            <div class="employee-avatar__left">
                                <span
                                    class="user-picture"
                                    [ngStyle]="{
                                        'background-color': getColor(data.name)
                                    }"
                                    >{{ getInitialName(data.name) }}</span
                                >
                            </div>

                            <div class="employee-avatar__right cursor employee">
                                <div class="employee__name">
                                    {{ data.employeeName }}
                                </div>

                                <div *ngIf="data.title" style="font-size: 11px; color: gray" class="employee__title">
                                    {{ data.title }}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </e-column>

                <e-column field="email" headerText="Email" [autoFit]="true" />
                <e-column field="phone" headerText="Phone" [allowFiltering]="false" />

                <e-column field="role" headerText="Role" />
            </e-columns>
        </ejs-grid>
    </div>
</div>
