import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Constant } from '@core/constants';
import { UserService, CommonService } from '@core/services';
import { IBatchPrintingPayload, IBatchTransation } from '@core/models';

@Injectable({
    providedIn: 'root',
})
export class BatchPrintingService {
    public baseURL = '';

    constructor(
        private http: HttpClient,
        private constant: Constant,
        private commonService: CommonService,
        private userService: UserService
    ) {
        this.baseURL = this.commonService.getBaseURL('v1', 'cheque');
    }

    getHttpOptions() {
        const token: string = this.commonService.getToken();
        const orgId: string = this.userService.organizationId;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                orgid: orgId,
            }),
        };
        return httpOptions;
    }

    CreateBatchPrinting(
        payload: IBatchPrintingPayload
    ): Observable<{ status: boolean; data: string }> {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.CREATE_BATCH_PRINT;
        return this.http.post<{ status: boolean; data: string }>(
            this.baseURL + url,
            payload,
            httpOptions
        );
    }

    getBatchTransactionByID = (id: string): Observable<IBatchTransation[]> => {
        const httpOptions = this.getHttpOptions();
        const url: string =
            this.constant.API_URLS.GET_BATCH_TRANSACTION_BY_ID(id);
        return this.http.get<IBatchTransation[]>(
            this.baseURL + url,
            httpOptions
        );
    };

    getBatchPDF = (payload: {
        id: string[];
    }): Observable<{ status: boolean; data: string }> => {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_BATCH_PDF;
        return this.http.post<{ status: boolean; data: string }>(
            this.baseURL + url,
            payload,
            httpOptions
        );
    };
}
