import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import {
    ExcelQueryCellInfoEventArgs,
    GridComponent,
    Column,
    RowSelectEventArgs,
    PagerComponent,
    SortService,
    ColumnModel,
} from '@syncfusion/ej2-angular-grids';
import { DialogService } from '@ngneat/dialog';

import {
    CompanyDetails,
    EToasterStatus,
    IBankAccounts,
    IExpenseAccounts,
    IMeta,
    ISupplier,
    ISupplierTransactions,
    ITax,
} from '@core/models';
import {
    BankAccountService,
    ChequeService,
    CommonService,
    ExpenseAccountService,
    MenuService,
    SupplierService,
    TaxSetupService,
    ToasterService,
    UserService,
} from '@core/services';
import { AddSupplierComponent } from './add-new-supplier/add-new-supplier.component';
import { ConfirmationComponent } from '@shared/components';
import { Constant } from '@core/constants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-suppliers',
    styleUrls: ['./suppliers.component.scss'],
    templateUrl: './suppliers.component.html',
    providers: [SortService],
})
export class SuppliersComponent implements OnInit, AfterViewInit {
    @ViewChild('overviewgrid')
    gridInstance!: GridComponent;
    @ViewChild('supplierTransactionsGrid')
    supplierTransactionsInstance!: GridComponent;
    @ViewChild('supplierTransactionsPager')
    supplierTransactionsPager!: PagerComponent;
    public suppliers: ISupplier[] = [];
    public expenseAccounts: IExpenseAccounts[] = [];
    public bankAccounts: IBankAccounts[] = [];
    public organizations: CompanyDetails[] = [];
    public taxes: ITax[] = [];
    private dialog: DialogService = inject(DialogService);
    public supplierTransactions: ISupplierTransactions[] = [];
    public selectedSupplier: ISupplier | null = null;
    public pdfUrl: SafeResourceUrl = '';
    @ViewChild('pdfViewer') pdfViewer!: ElementRef;
    public transactionPageSize: number = 5;
    public transactionTotalRecords: number = 0;
    public transactionPageCount: number = 0;
    public transactionPageNumber: number = 1;
    public supplierPageCount: number = 1;
    public supplierPageSize: number = 5;
    public supplierTotalRecords: number = 0;
    public isAdminUser: boolean = false;
    public paymentTypes: { name: string; id: string }[] = [];

    constructor(
        private menuService: MenuService,
        private supplierService: SupplierService,
        private expenseAccountService: ExpenseAccountService,
        private toasterService: ToasterService,
        private commonService: CommonService,
        private chequeService: ChequeService,
        private constant: Constant,
        private sanitizer: DomSanitizer,
        private bankAccountService: BankAccountService,
        private userService: UserService,
        private taxSetupService: TaxSetupService
    ) {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    }

    ngOnInit(): void {
        const role: string = this.userService.userRole;
        this.isAdminUser = role === this.constant.USER_ROLES.ADMIN;
        this.menuService.updateMenuSelection('suppliers');
        this.getTaxSetup();
        this.getBankAccounts();
        this.getPaymentTypes();
    }

    ngAfterViewInit(): void {
        ((this.supplierTransactionsInstance as GridComponent).columns[1] as ColumnModel).customAttributes = { class: 'currency-column' };
    }

    getPaymentTypes = () => {
        this.chequeService.getPaymentTypes().subscribe({
            next: (res: { name: string; id: string }[]) => {
                if (res) {
                    this.paymentTypes = res;
                }
            },
        });
    };

    supplierTransactionConfig = () => {
        this.supplierTransactionsInstance.localeObj['localeStrings'].EmptyRecord = 'There are no cheques related to this Supplier';
    };

    getSuppliers = () => {
        const queryString = `page=${this.supplierPageCount}&size=${this.supplierPageSize}`;

        this.supplierService.getSuppliers(queryString).subscribe({
            next: (res: { content: ISupplier[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.suppliers = res.content.map((obj) => {
                        obj['expenseAccount'] = this.expenseAccounts.find((item) => item.id === obj.defaultExpenseAccount)?.name || '';
                        obj['defaultTaxCodeName'] = this.taxes.find((item) => item.code === obj.defaultTaxCode)?.name || '';
                        return obj;
                    });

                    if (res.content.length > 0) {
                        this.selectedSupplier = res.content[0];

                        if ('id' in this.selectedSupplier && this.selectedSupplier.id) {
                            this.transactionPageNumber = 1;
                            this.getTransactionBySupplierID(this.selectedSupplier.id);
                        }
                    }
                }

                if (res && 'meta' in res && res.meta) {
                    this.supplierTotalRecords = res.meta.total;
                    this.supplierPageCount = Math.ceil(res.meta.total / this.supplierPageSize);
                }
            },
        });
    };

    handleSupplierPaging = (event: number) => {
        this.supplierPageCount = event;
        this.getSuppliers();

        if (this.suppliers.length > 0) {
            const currentIndex: number = (event - 1) * 5;
            this.selectedSupplier = this.suppliers[currentIndex];

            if (this.selectedSupplier && 'id' in this.selectedSupplier && this.selectedSupplier.id) {
                this.transactionPageNumber = 1;
                this.getTransactionBySupplierID(this.selectedSupplier.id);
            }
        }
    };

    getTransactionBySupplierID = (id: string) => {
        const qString: string = id ? `supplierId=${id}&page=${this.transactionPageNumber}&size=5&sort=organizationUpdatedIndex-desc` : '';
        this.supplierService.getFilteredTransactions(qString).subscribe({
            next: (res: { content: ISupplierTransactions[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.supplierTransactions = res.content.map((obj) => {
                        obj['bankAccountName'] = this.bankAccounts.find((item) => item.id === obj.bankAccount)?.name || '';
                        obj['paymentTypeName'] = this.paymentTypes.find((item) => item.id === obj.paymentType)?.name || '';
                        return obj;
                    });
                }

                if (res && 'meta' in res && res.meta) {
                    this.transactionPageCount = Math.ceil(res.meta.total / this.transactionPageSize);
                    this.transactionTotalRecords = res.meta.total;
                }
            },
        });
    };

    handlePaging = (event: number) => {
        this.transactionPageNumber = event;

        if (this.selectedSupplier && 'id' in this.selectedSupplier && this.selectedSupplier.id) {
            this.getTransactionBySupplierID(this.selectedSupplier.id);
        }
    };

    getBankAccounts = () => {
        this.bankAccountService.getBankAccounts().subscribe({
            next: (res: IBankAccounts[]) => {
                this.bankAccounts = res;
            },
        });
    };

    getTaxSetup = () => {
        this.taxSetupService.getTaxes().subscribe({
            next: (res: ITax[]) => {
                this.taxes = res || [];
                this.getExpenseAccounts();
            },
        });
    };

    getExpenseAccounts = () => {
        this.expenseAccountService.getExpenseAccounts().subscribe({
            next: (res: IExpenseAccounts[]) => {
                this.expenseAccounts = res;
                this.getSuppliers();
            },
        });
    };

    handleToolbarClick = (type: string) => {
        switch (type) {
            case 'download':
                if (this.gridInstance) {
                    (this.gridInstance.columns[4] as Column).visible = false;
                    this.gridInstance.excelExport();
                }
                break;
            case 'print':
                if (this.gridInstance) this.gridInstance.print();
                break;
            case 'add-new':
                this.manageSupplier(false);
                break;
            default:
                console.log('No match found');
        }
    };

    excelExportComplete = () => {
        if (this.gridInstance) {
            (this.gridInstance.columns[4] as Column).visible = true;
        }
    };

    rowSelected(args: RowSelectEventArgs) {
        this.selectedSupplier = args.data as ISupplier;
        this.getTransactionBySupplierID((args.data as unknown as ISupplier)?.id);
    }

    manageSupplier = (isEdit: boolean, supplierID = '', event?: MouseEvent) => {
        event && event.stopPropagation();
        const dialogRef = this.dialog.open(AddSupplierComponent, {
            minWidth: '70%',
            maxWidth: '100%',
            maxHeight: '75vh',
            data: {
                isEdit,
                supplierID,
            },
        });

        dialogRef.afterClosed$.subscribe((result) => {
            if (result) {
                this.supplierPageCount = 1;
                this.suppliers = [];
                this.gridInstance.refresh();
                this.getTaxSetup();
            }
        });
    };

    handleDelete = (supplier: ISupplier, event: MouseEvent) => {
        event.stopPropagation();

        if (supplier && supplier.id) {
            const dialogRef = this.dialog.open(ConfirmationComponent, {
                minWidth: '35vw',
                maxWidth: '100%',
                data: {
                    title: 'Confirm',
                    msg: 'Are you sure want to delete this supplier?',
                },
            });
            dialogRef.afterClosed$.subscribe((result) => {
                if (result) {
                    this.deleteSupplier(supplier.id);
                }
            });
        }
    };

    deleteSupplier = (id: string) => {
        if (id) {
            this.supplierService.deleteSupplier(id).subscribe({
                next: (res: { status: boolean }) => {
                    this.showToast(EToasterStatus.SUCCESS, this.constant.SUPPLIER_CRUD_MSG.DELETE_SUCCESS);
                    this.getSuppliers();
                },
                error: () => {
                    this.showToast(EToasterStatus.SUCCESS, this.constant.SUPPLIER_CRUD_MSG.DELETE_FAILURE);
                },
            });
        }
    };

    handlePrintCheque = (event: MouseEvent, id: string) => {
        event.stopPropagation();

        if (id) {
            this.chequeService.getChequePDF(id).subscribe({
                next: (res: { status: boolean; data: { url: string } }) => {
                    if (res && 'data' in res && res.data && 'url' in res.data && res.data.url) {
                        this.getPdfFile(res.data?.url);
                    }
                },
            });
        }
    };

    getPdfFile = (fileURL: string) => {
        this.commonService.getPDFFile(fileURL).subscribe((response: Blob) => {
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result as ArrayBuffer;
                const byteArray = new Uint8Array(arrayBuffer);

                // Create a Blob with the correct MIME type
                const blob = new Blob([byteArray], {
                    type: 'application/pdf',
                });

                // Create a Blob URL and open it in a new window
                const url = URL.createObjectURL(blob);
                this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

                setTimeout(() => {
                    this.printFile();
                    this.gridInstance.refresh();
                }, 1000);
            };

            reader.readAsArrayBuffer(response);
        });
    };

    printFile = () => {
        if (this.pdfViewer) {
            const iframe = this.pdfViewer.nativeElement;
            iframe.contentWindow.print();
        }
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }

    convertToUserTz = (dateStr: string): string | null => {
        return this.commonService.convertToUserTz(dateStr);
    };

    sortComparer = (reference: { first: string; last: string }, comparer: { first: string; last: string }) => {
        const referenceFullName: string = reference ? `${reference?.first || ''}${reference?.last || ''}` : '';
        const comparerFullName: string = comparer ? `${comparer?.first || ''}${comparer?.last || ''}` : '';
        return referenceFullName.localeCompare(comparerFullName);
    };
}
