import { Injectable } from '@angular/core';

import { IEmployee } from '@core/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    userId!: string;
    userName!: string;
    firstName!: string;
    lastName!: string;
    email!: string;
    accessToken!: string;
    isActive!: boolean;
    organizationId!: string;
    organizationName!: string;
    userRole!: string;
    reportingTo!: string;
    userNameChangedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    userNameChanged$ = this.userNameChangedSubject.asObservable();

    updateUserNameChangeStatus = (status: boolean) => {
        this.userNameChangedSubject.next(status);
    };

    setUserDetails(data: IEmployee): void {
        this.userId = (data || {}).id || '';
        this.email = (data || {}).email || '';
        this.firstName = ((data || {}).name || {}).first || '';
        this.lastName = ((data || {}).name || {}).last || '';
        this.organizationId = (data || {}).organization || '';
        this.userRole = (data || {}).role || '';
        this.reportingTo = (data || {}).reportingTo || '';
    }
}
