<div class="login d-flex align-items-center justify-content-center">
    <div class="login__content">
        <div class="login__header d-flex align-items-center justify-content-center">
            <a href="/" class="login__header--logo">
                <img src="assets/images/logo.png" alt="App Logo" height="1%" width="100%" />
            </a>
        </div>

        <form class="login__form form" #form="ngForm" aria-labelledby="title" (ngSubmit)="onSubmit()">
            <div class="form__group template">
                <div id="form__field" class="e-float-input" [ngClass]="{ 'e-success': email.valid }">
                    <input type="text" id="email" name="email" [(ngModel)]="user.email" #email="ngModel" required />
                    <span class="e-float-line"></span>
                    <label
                        class="e-float-text"
                        [ngClass]="{
                            'e-label-top': user.email !== '',
                            'e-label-bottom': user.email === ''
                        }"
                        for="name"
                        >Email</label
                    >
                </div>
                <span class="e-error" *ngIf="email.errors && email.errors['required'] && (email.dirty || email.touched)">
                    Enter your email address
                </span>
                <span class="e-error" *ngIf="!validateEmail() && user.email && (email.dirty || email.touched)">
                    Enter a valid email address
                </span>
            </div>

            <div class="form__group form__password template">
                <div id="pass" class="e-float-input form__field" [ngClass]="{ 'e-success': password.valid }">
                    <input
                        type="password"
                        id="input-password"
                        name="password"
                        [(ngModel)]="user.password"
                        #password="ngModel"
                        required
                        pattern="(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d$@$!%#^*?&].{11,}"
                    />
                    <span class="e-float-line"></span>
                    <label
                        class="e-float-text"
                        [ngClass]="{
                            'e-label-top': user.password !== '',
                            'e-label-bottom': user.password === ''
                        }"
                        for="name"
                        >Password</label
                    >

                    <span
                        *ngIf="!showPasswordFlag"
                        class="material-icons-outlined pass-icon"
                        icon="eye-off-outline"
                        (click)="togglePassword('input-password')"
                        (keypress)="togglePassword('input-password')"
                        tabindex="0"
                    >
                        visibility
                    </span>

                    <span
                        *ngIf="showPasswordFlag"
                        class="material-icons-outlined pass-icon"
                        icon="eye-outline"
                        (click)="togglePassword('input-password')"
                        (keypress)="togglePassword('input-password')"
                        tabindex="0"
                    >
                        visibility_off
                    </span>
                </div>
                <span class="e-error" *ngIf="password.errors && password.errors['required'] && (password.dirty || password.touched)">
                    Enter password
                </span>
                <span class="e-error" *ngIf="password.errors && password.errors['pattern'] && (password.dirty || password.touched)">
                    Password should contains minimum 12 characters, one lower case and one upper case.
                </span>
            </div>

            <div class="form__group template center">
                <div class="submitBtn">
                    <button
                        class="submit-btn e-btn"
                        id="submit-btn"
                        ejs-progressbutton
                        content="SIGN IN"
                        [enableProgress]="true"
                        [spinSettings]="spinCenter"
                        [animationSettings]="slideRight"
                        cssClass="e-outline e-success e-reverse-progress"
                        type="submit"
                        [disabled]="!form.valid"
                        [duration]="1000"
                    >
                        SIGN IN
                    </button>
                </div>
            </div>

            <section class="form__group center form__action" aria-label="Register">
                To Register
                <a class="text-link" routerLink="../register">Click here</a>
            </section>

            <section class="form__group center form__action" aria-label="Forgot Password">
                Forgot the password?
                <a class="text-link" routerLink="../forgot-password"> Click here </a>
            </section>
        </form>
    </div>
</div>
