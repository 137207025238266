import { Injectable } from '@angular/core';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';

import { EToasterStatus } from '@core/models';

@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    private toasterElement!: ToastComponent;

    setToaster(toasterElement: ToastComponent) {
        this.toasterElement = toasterElement;
    }

    showToast(title: EToasterStatus, message: string): void {
        if (this.toasterElement) {
            this.toasterElement.show({
                content: message,
                title: this.getTitle(title),
                cssClass: `e-toast-${title}`,
            });
        } else {
            console.error(
                'Toaster component is not set. Please set the toaster using setToaster() method.'
            );
        }
    }

    getTitle = (title: EToasterStatus): string => {
        if (title === EToasterStatus.DANGER) {
            return 'Error';
        } else if (title === EToasterStatus.SUCCESS) {
            return 'Success';
        } else {
            return 'Warning';
        }
    };
}
