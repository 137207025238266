import { Component, OnInit } from '@angular/core';
import {
    AnimationSettingsModel,
    SpinSettingsModel,
} from '@syncfusion/ej2-splitbuttons';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Constant } from '@core/constants';
import { AuthService, ToasterService } from '@core/services';
import { EToasterStatus } from '@core/models';

@Component({
    selector: 'app-verify-user',
    styleUrls: ['./verify-user.component.scss'],
    templateUrl: './verify-user.component.html',
})
export class VerifyUserComponent implements OnInit {
    public resetPasswordActionMessage = '';
    public showSuccessMessage = false;
    public showErrorMessage = false;
    public submitted = false;
    public newPassword = '';
    public confirmPassword = '';
    public verifyToken = '';
    public showPasswordFlag = false;
    public showRePasswordFlag = false;
    public slideRight: AnimationSettingsModel = { effect: 'SlideRight' };
    public spinCenter: SpinSettingsModel = { position: 'Center' };

    constructor(
        private constant: Constant,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toasterService: ToasterService
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.verifyToken = ((params || {})['verifyid'] || '').replace(
                /\s+/g,
                '+'
            );
        });
    }

    verifyUser(resetPassForm: NgForm): void {
        this.submitted = true;
        const password: string =
            ((resetPassForm || {}).value || {}).password || '';

        this.authService.verifyUser(password, this.verifyToken).subscribe({
            next: () => {
                this.showSuccessMessage = true;
                this.showToast(
                    EToasterStatus.SUCCESS,
                    this.constant.RESET_PASSWORD_MSG.SUCCESS
                );
                resetPassForm.resetForm();
                this.submitted = false;
                const url = this.constant.REDIRECT_LINKS.LOGIN;
                this.router.navigateByUrl(url);
            },
            error: () => {
                this.showErrorMessage = true;
                this.showToast(
                    EToasterStatus.DANGER,
                    this.constant.RESET_PASSWORD_MSG.FAILURE
                );
                resetPassForm.resetForm();
            },
        });
    }

    togglePassword(id: string) {
        if (id == 'input-password') {
            this.showPasswordFlag = !this.showPasswordFlag;
        } else if (id == 'input-re-password') {
            this.showRePasswordFlag = !this.showRePasswordFlag;
        }

        const passEleType = (<HTMLInputElement>document.getElementById(id))
            .type;
        if (passEleType == 'password') {
            (<HTMLInputElement>document.getElementById(id)).type = 'text';
        } else if (passEleType == 'text') {
            (<HTMLInputElement>document.getElementById(id)).type = 'password';
        }
    }

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }
}
