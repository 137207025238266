import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';

import { IEmployee } from '@core/models';

@Component({
    selector: 'app-personal-details-card',
    styleUrls: ['./personal-details-card.component.scss'],
    templateUrl: './personal-details-card.component.html',
})
export class PersonalDetailsCardComponent {
    @Input() userInfo: IEmployee | null = null;
    @Input() employeeList: IEmployee[] = [];

    getDateFormat(date: string): string {
        if (date) {
            return DateTime.fromISO(date).toFormat(' dd LLL yyyy');
        }

        return date;
    }

    getManagerName(id: string): string {
        let managerName = '';
        this.employeeList.forEach((element) => {
            if (element.id == id) {
                managerName = element.name.last + ', ' + element.name.first;

                if (element.name.middle) {
                    managerName = managerName + ' ' + element.name.middle;
                }
            }
        });

        return managerName;
    }
}
