<div class="add-expense-account">
    <div class="add-expense-account__title">{{ title }}</div>

    <div class="add-expense-account__body">
        <form id="reactive" [formGroup]="form" class="add-expense-account__form form">
            <div class="form__group required">
                <ejs-textbox placeholder="Expense Name" floatLabelType="Always" formControlName="name" />

                <ng-container *ngIf="formControlF['name'].touched && formControlF['name'].errors">
                    <p class="caption status-danger" *ngIf="formControlF['name'].errors['required']">Expense Name is required.</p>

                    <p class="caption status-danger" *ngIf="formControlF['name'].errors['maxlength']">
                        Expense Name can't be more than 255 characters.
                    </p>
                </ng-container>
            </div>

            <div class="form__group">
                <ejs-textbox placeholder="Expense Account #" floatLabelType="Always" formControlName="account" />

                <ng-container *ngIf="formControlF['account'].touched && formControlF['account'].errors">
                    <p class="caption status-danger" *ngIf="formControlF['account'].errors['maxlength']">
                        Expense Account can't be more than 255 characters.
                    </p>
                </ng-container>
            </div>

            <div class="form__group cheque-sequence">
                <ejs-dropdownlist
                    id="taxCode"
                    name="taxCode"
                    [(ngModel)]="taxCode"
                    (ngModelChange)="changeTaxCode(taxCode)"
                    formControlName="taxCode"
                    [dataSource]="taxes"
                    placeholder="Tax Code"
                    floatLabelType="Always"
                    [itemTemplate]="itemTemplate"
                    [showClearButton]="true"
                    [fields]="{ text: 'name', value: 'code', tax: 'tax' }"
                >
                    <ng-template #itemTemplate="" let-data="">
                        <div class="tax-display">{{ formatTaxDisplay(data) }}</div>
                    </ng-template>
                </ejs-dropdownlist>

                <ng-container *ngIf="formControlF['taxCode'].touched && formControlF['taxCode'].errors">
                    <p class="caption status-danger" *ngIf="formControlF['taxCode'].errors['maxlength']">
                        Default Tax Code can't be more than 1 characters.
                    </p>
                </ng-container>
            </div>

            <div class="form__group">
                <ejs-textbox placeholder="EA Account Name" floatLabelType="Always" formControlName="eaAccount" />

                <ng-container
                    *ngIf="formControlF['eaAccount'].value && formControlF['eaAccount'].touched && formControlF['eaAccount'].errors"
                >
                    <p class="caption status-danger" *ngIf="formControlF['eaAccount'].errors['maxlength']">
                        EA Account Name can't be more than 255 characters.
                    </p>
                </ng-container>
            </div>

            <div class="form__footer">
                <button [ngClass]="['e-btn e-outline e-danger']" id="cancelBtn" content="Cancel" (click)="cancel()">Cancel</button>

                <button
                    [ngClass]="['e-btn e-outline', form.valid ? 'e-success' : '']"
                    id="cancelBtn"
                    content="Save"
                    (click)="addExpenseAccount()"
                    [disabled]="!form.valid"
                >
                    Save
                </button>
            </div>
        </form>
    </div>
</div>
