<div class="profile">
    <div class="profile__card">
        <div class="profile__header header">
            <div class="header__initial">
                <div class="header__initial--text">{{ initial }}</div>
            </div>

            <div class="header__username">{{ userName }}</div>
        </div>

        <div class="profile__body">
            <form class="profile__form form" (ngSubmit)="changePass(changePassForm)" #changePassForm="ngForm" aria-labelledby="title">
                <div class="form__group form__password template">
                    <div id="pass" class="e-float-input form__field" [ngClass]="{ 'e-success': oPassword.valid }">
                        <input
                            type="password"
                            id="input-old-password"
                            name="oPassword"
                            [(ngModel)]="oldPassword"
                            #oPassword="ngModel"
                            required
                            pattern="(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d$@$!%#^*?&].{11,}"
                        />
                        <span class="e-float-line"></span>
                        <label
                            class="e-float-text required"
                            [ngClass]="{
                                'e-label-top': oldPassword !== '',
                                'e-label-bottom': oldPassword === ''
                            }"
                            for="name"
                            >Old Password</label
                        >

                        <span
                            *ngIf="!showOldPasswordFlag"
                            class="material-icons-outlined pass-icon"
                            icon="eye-off-outline"
                            (click)="togglePassword('input-old-password')"
                            (keypress)="togglePassword('input-old-password')"
                            tabindex="0"
                        >
                            visibility
                        </span>

                        <span
                            *ngIf="showOldPasswordFlag"
                            class="material-icons-outlined pass-icon"
                            icon="eye-outline"
                            (click)="togglePassword('input-old-password')"
                            (keypress)="togglePassword('input-old-password')"
                            tabindex="0"
                        >
                            visibility_off
                        </span>
                    </div>
                    <span
                        class="e-error"
                        *ngIf="oPassword.errors && oPassword.errors['required'] && (oPassword.dirty || oPassword.touched)"
                    >
                        Enter password
                    </span>
                    <span class="e-error" *ngIf="oPassword.errors && oPassword.errors['pattern'] && (oPassword.dirty || oPassword.touched)">
                        Password should contains minimum 12 characters, one lower case and one upper case.
                    </span>
                </div>

                <div class="form__group form__password template">
                    <div id="pass" class="e-float-input form__field" [ngClass]="{ 'e-success': password.valid }">
                        <input
                            type="password"
                            id="input-password"
                            name="password"
                            [(ngModel)]="newPassword"
                            #password="ngModel"
                            required
                            pattern="(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d$@$!%#^*?&].{11,}"
                        />
                        <span class="e-float-line"></span>
                        <label
                            class="e-float-text required"
                            [ngClass]="{
                                'e-label-top': newPassword !== '',
                                'e-label-bottom': newPassword === ''
                            }"
                            for="name"
                            >New Password</label
                        >

                        <span
                            *ngIf="!showPasswordFlag"
                            class="material-icons-outlined pass-icon"
                            icon="eye-off-outline"
                            (click)="togglePassword('input-password')"
                            (keypress)="togglePassword('input-password')"
                            tabindex="0"
                        >
                            visibility
                        </span>

                        <span
                            *ngIf="showPasswordFlag"
                            class="material-icons-outlined pass-icon"
                            icon="eye-outline"
                            (click)="togglePassword('input-password')"
                            (keypress)="togglePassword('input-password')"
                            tabindex="0"
                        >
                            visibility_off
                        </span>
                    </div>
                    <span class="e-error" *ngIf="password.errors && password.errors['required'] && (password.dirty || password.touched)">
                        Enter password
                    </span>

                    <span class="e-error" *ngIf="password.errors && password.errors['pattern'] && (password.dirty || password.touched)">
                        Password should contains minimum 12 characters, one lower case and one upper case.
                    </span>
                </div>

                <div class="form__group form__password template">
                    <div id="pass" class="e-float-input form__field" [ngClass]="{ 'e-success': rePass.valid }">
                        <input
                            type="password"
                            id="input-re-password"
                            name="rePass"
                            [(ngModel)]="confirmPassword"
                            #rePass="ngModel"
                            required
                            pattern="(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d$@$!%#^*?&].{11,}"
                        />
                        <span class="e-float-line"></span>
                        <label
                            class="e-float-text required"
                            [ngClass]="{
                                'e-label-top': confirmPassword !== '',
                                'e-label-bottom': confirmPassword === ''
                            }"
                            for="name"
                            >Confirm Password</label
                        >

                        <span
                            *ngIf="!showRePasswordFlag"
                            class="material-icons-outlined pass-icon"
                            icon="eye-off-outline"
                            (click)="togglePassword('input-re-password')"
                            (keypress)="togglePassword('input-re-password')"
                            tabindex="0"
                        >
                            visibility
                        </span>

                        <span
                            *ngIf="showRePasswordFlag"
                            class="material-icons-outlined pass-icon"
                            icon="eye-outline"
                            (click)="togglePassword('input-re-password')"
                            (keypress)="togglePassword('input-re-password')"
                            tabindex="0"
                        >
                            visibility_off
                        </span>
                    </div>
                    <span class="e-error" *ngIf="rePass.errors && rePass.errors['required'] && (rePass.dirty || rePass.touched)">
                        Enter password
                    </span>

                    <span class="e-error" *ngIf="rePass.errors && rePass.errors['pattern'] && (rePass.dirty || rePass.touched)">
                        Password should contains minimum 12 characters, one lower case and one upper case.
                    </span>

                    <span class="e-error" *ngIf="password.value !== rePass.value && (rePass.dirty || rePass.touched)">
                        Password does not match the confirm password.
                    </span>
                </div>

                <div class="form__group template center">
                    <div class="submitBtn">
                        <button
                            [ngClass]="[
                                'submit-btn e-btn',
                                submitted || !changePassForm.valid || password.value !== rePass.value ? 'disabled' : ''
                            ]"
                            id="submit-btn"
                            ejs-progressbutton
                            content="CHANGE PASSWORD"
                            [spinSettings]="spinCenter"
                            [animationSettings]="slideRight"
                            [enableProgress]="true"
                            cssClass="e-outline e-success e-reverse-progress"
                            type="submit"
                            [duration]="1000"
                            [disabled]="submitted || !changePassForm.valid || password.value !== rePass.value"
                        >
                            CHANGE PASSWORD
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
