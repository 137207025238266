import { Component, OnInit, inject } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SelectEventArgs } from '@syncfusion/ej2-angular-navigations';

import { IECHQAddress, EToasterStatus, IUserPayload, IUserForm, IEmployee } from '@core/models';
import { CommonService, EmployeeService, ToasterService, UserService } from '@core/services';
import { Constant } from '@core/constants';
import { MustMatch } from '@core/helpers';

interface Data {
    isEdit: boolean;
    userID?: string;
}

@Component({
    selector: 'app-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent implements OnInit {
    public form!: FormGroup;
    public disableFormFlag: boolean = false;
    public loading: boolean = false;
    public showSettingFlag: boolean = false;
    public headerText: { text: string }[] = [{ text: 'User Details' }, { text: 'Address' }];
    public employeeID: string = '';
    public addressFormData!: IECHQAddress;
    public ref: DialogRef<Data, boolean | { status: boolean; payload: IUserPayload }> = inject(DialogRef);
    public isUserActive: boolean = true;
    public fields: { text: string; value: string } = {
        text: 'name',
        value: 'value',
    };
    public userRoles: { name: string; value: string }[] = [
        { name: 'User', value: 'employee' },
        { name: 'Admin', value: 'admin' },
    ];
    public initialFormValue!: IUserForm;
    public initialAddressFormData!: IECHQAddress;
    public selectedTabIndex: number = 0;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private toasterService: ToasterService,
        private employeeService: EmployeeService,
        private commonService: CommonService,
        private constant: Constant
    ) {
        this.form = this.formBuilder.group(
            {
                firstName: [null, [Validators.required, Validators.maxLength(255)]],
                middleName: ['', [Validators.maxLength(255)]],
                lastName: [null, [Validators.required, Validators.maxLength(255)]],
                email: [null, [Validators.required, Validators.pattern(this.constant.EMAIL_VALIDATION_PATTERN)]],
                confirmEmail: [null, [Validators.required, Validators.pattern(this.constant.EMAIL_VALIDATION_PATTERN)]],
                phone: [''],
                extension: [''],
                active: [true, [Validators.required]],
                revokeAccess: [false, [Validators.required]],
                role: ['employee', [Validators.required]],
            },
            {
                validators: MustMatch('email', 'confirmEmail'),
            }
        );
        this.employeeID = this.ref.data?.userID || '';
        this.companyDetailsF['active'].disable();

        if (this.isEdit) {
            this.companyDetailsF['email'].disable();
            this.companyDetailsF['confirmEmail'].disable();
        }
    }

    ngOnInit(): void {
        if (this.employeeID) {
            this.getEmployeeAddress(this.employeeID);
            this.fetchEmployeeDetails();
        }

        const userRole: string = this.userService.userRole;
        if (userRole === this.constant.USER_ROLES.EMP) {
            this.form.disable();
            this.disableFormFlag = true;
        }
    }

    get isEdit() {
        return this.ref.data?.isEdit;
    }

    get companyDetailsF(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    fetchEmployeeDetails = () => {
        this.loading = true;
        this.employeeService.getEmployeeInfo(this.employeeID).subscribe({
            next: (res: IEmployee) => {
                this.loading = false;
                this.bindFormData(res);
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    getEmployeeAddress = (empID: string) => {
        this.loading = true;
        this.employeeService.getEmployeeAddress(empID).subscribe({
            next: (data) => {
                this.loading = false;

                if (data) {
                    this.addressFormData = data;
                    this.initialAddressFormData = data;
                    delete this.addressFormData.id;
                    delete this.initialAddressFormData.id;
                }
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    bindFormData = (formData: IEmployee) => {
        const [{ number, extension }] = formData?.phones && formData?.phones.length > 0 ? formData.phones : [{ number: '', extension: '' }];

        this.form.patchValue({
            firstName: formData.name?.first || '',
            middleName: formData.name?.middle || '',
            lastName: formData.name?.last || '',
            email: formData.email || '',
            confirmEmail: formData.email || '',
            active: formData.active,
            revokeAccess: formData.revokeAccess,
            role: formData.role,
            phone: number,
            extension: extension,
        });
        this.initialFormValue = this.form.value;
    };

    cancel = () => {
        this.ref.close(false);
    };

    checkFormModified() {
        const currentFormValue: IUserForm = this.form.value;
        return !this.commonService.isEqual(currentFormValue, this.initialFormValue);
    }

    updateEmployee = () => {
        this.loading = true;
        const payload: IUserPayload = this.createPayload(this.form.getRawValue());
        const employeeReq$: Observable<IEmployee> = this.isEdit
            ? this.employeeService.updateEmployee(this.employeeID, payload)
            : this.employeeService.createEmployee(payload);

        employeeReq$.subscribe({
            next: (res: IEmployee) => {
                this.loading = false;
                this.initialFormValue = this.form.value;

                if (this.isEdit && !this.commonService.isEqual(this.addressFormData, this.initialAddressFormData)) {
                    this.handleAddressSubmit(this.addressFormData);
                } else {
                    this.ref.close({ status: true, payload });
                    this.showToast(
                        EToasterStatus.SUCCESS,
                        this.isEdit ? this.constant.USER_CRUD_MSG.UPDATE_SUCCESS : this.constant.USER_CRUD_MSG.SUCCESS
                    );
                }
            },
            error: (err) => {
                this.loading = false;
                this.showToast(
                    EToasterStatus.DANGER,
                    this.isEdit ? this.constant.USER_CRUD_MSG.UPDATE_FAILURE : this.constant.USER_CRUD_MSG.FAILURE
                );
            },
        });
    };

    createPayload = (data: IUserForm): IUserPayload => {
        const orgID: string = this.userService.organizationId;
        const payload: IUserPayload = {
            email: data.email,
            name: {
                first: data.firstName || '',
                middle: data.middleName || '',
                last: data.lastName || '',
            },
            phones: [
                {
                    number: data.phone || '',
                    extension: data.extension || '',
                },
            ],
            organization: orgID,
            details: {
                birthday: '',
                hireDate: new Date().toISOString(),
                salary: '',
                title: '',
                gender: '',
                notes: '',
                terminationDate: '',
            },
            active: this.isUserActive,
            role: data.role,
            revokeAccess: data.revokeAccess,
            reportingTo: '',
        };

        return payload;
    };

    createAddressPayload = (data: IECHQAddress): IECHQAddress => {
        const actionPayload: IECHQAddress = {
            id: this.employeeID,
            firstLine: (data || {}).firstLine || '',
            lastLine: (data || {}).lastLine || '',
            city: data.city || '',
            state: data.state || '',
            country: data.country || '',
            zipCode: data.zipCode || '',
        };
        return actionPayload;
    };

    isFieldValid(field: string): boolean {
        return !!(!this.form.get(field)?.valid && (this.form.get(field)?.dirty || this.form.get(field)?.touched));
    }

    handleAddressFormCancel = (event: IECHQAddress | null) => {
        this.ref.close(false);
    };

    handleAddressSubmit = (address: IECHQAddress) => {
        this.addressFormData = address;
        const isUserDetailsModified: boolean = this.checkFormModified();

        if (address) {
            this.loading = true;
            const payload: IECHQAddress = this.createAddressPayload(address);
            this.employeeService.updateAddress(this.employeeID, payload).subscribe({
                next: (res: IECHQAddress) => {
                    this.loading = false;
                    this.initialAddressFormData = this.addressFormData;
                    if (this.isEdit && isUserDetailsModified) {
                        this.updateEmployee();
                    } else {
                        this.ref.close(true);
                        this.showToast(EToasterStatus.SUCCESS, this.constant.USER_CRUD_MSG.UPDATE_SUCCESS);
                    }
                },
                error: (err) => {
                    this.loading = false;
                    this.showToast(EToasterStatus.DANGER, this.constant.USER_CRUD_MSG.UPDATE_FAILURE);
                },
            });
        }
    };

    handleTabChange = (event: SelectEventArgs) => {
        this.selectedTabIndex = event.selectedIndex;
    };

    handleAddressFormUpdated = (addressForm: FormGroup) => {
        this.addressFormData = addressForm.value;
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }
}
