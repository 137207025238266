<section class="forgot-password d-flex align-items-center justify-content-center">
    <div class="forgot-password__content">
        <div class="forgot-password__header d-flex align-items-center justify-content-center">
            <a href="/" class="forgot-password__header--logo">
                <img src="assets/images/logo.png" alt="App Logo" height="1%" width="100%" />
            </a>
        </div>

        <p class="forgot-password__description">Enter your email address and we’ll send a link to reset your password</p>

        <form class="forgot-password__form form" #form="ngForm" aria-labelledby="title" (ngSubmit)="onSubmit(form)">
            <ng-container *ngIf="showErrorMessage || showSuccessMessage">
                <div [ngClass]="[showErrorMessage ? 'error-msg' : 'success-msg']">
                    <b>{{ requestPasswordActionMessage }}</b>
                </div>
            </ng-container>

            <div class="form__group template">
                <div id="form__field" class="e-float-input" [ngClass]="{ 'e-success': email.valid }">
                    <input type="text" id="email" name="email" [(ngModel)]="emailAddress" #email="ngModel" required />

                    <span class="e-float-line"></span>
                    <label
                        class="e-float-text"
                        [ngClass]="{
                            'e-label-top': emailAddress !== '',
                            'e-label-bottom': emailAddress === ''
                        }"
                        for="email"
                    >
                        Email
                    </label>
                </div>

                <span class="e-error" *ngIf="email.errors && email.errors['required'] && (email.dirty || email.touched)">
                    Enter your email address
                </span>

                <span class="e-error" *ngIf="!validateEmail(emailAddress) && emailAddress && (email.dirty || email.touched)">
                    Enter a valid email address
                </span>
            </div>

            <div class="form-group template center">
                <div class="submitBtn">
                    <button
                        class="submit-btn e-btn"
                        id="submit-btn"
                        ejs-progressbutton
                        content="Request Password"
                        [enableProgress]="true"
                        [spinSettings]="spinCenter"
                        [animationSettings]="slideRight"
                        [ngClass]="[form.valid && validateEmail(emailAddress) ? 'e-success' : '']"
                        cssClass="e-outline e-reverse-progress"
                        type="submit"
                        [disabled]="!validateEmail(emailAddress) || !form.valid"
                        [duration]="1000"
                    >
                        Request Password
                    </button>
                </div>
            </div>

            <section class="form__group center form__action" aria-label="Sign in or sign up">
                <a class="text-link" routerLink="../login">Back to Log In</a>
            </section>

            <section class="form__group center form__action" aria-label="Register">
                <a routerLink="../register" class="text-link">Register</a>
            </section>
        </form>
    </div>
</section>
