//authentication.actions.ts
import { createAction, props } from '@ngrx/store';

import { ILoginPayload, ILoginResponse } from '../dependencies';

export enum AuthenticationActionTypes {
    LOGIN = '[Authentication] Login',
    LOGIN_SUCCESS = '[Authentication] Login Success',
    LOGIN_FAILURE = '[Authentication] Login Failure',
    LOGOUT = '[Authentication] Logout',
}

export const login = createAction(
    AuthenticationActionTypes.LOGIN,
    props<{ payload: ILoginPayload }>()
);

export const loginSuccess = createAction(
    AuthenticationActionTypes.LOGIN_SUCCESS,
    props<{ payload: ILoginResponse }>()
);

export const loginFailed = createAction(
    AuthenticationActionTypes.LOGIN_FAILURE,
    props<{ payload: Error }>()
);

export const logout = createAction(AuthenticationActionTypes.LOGOUT);
