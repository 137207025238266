<div class="company-details">
    <div
        class="company-details__header header d-flex align-items-flex-start justify-content-space-between"
    >
        <div class="header__left d-flex d-flex-column">
            <div class="header__title">Company Details</div>
            <div class="header__name">{{ companyDetail.name }}</div>
        </div>

        <div class="header__right">
            <button
                [ngClass]="['e-btn e-small e-outline e-success']"
                id="editBtn"
                content="Edit"
                (click)="handleCompanyEdit()"
                *ngIf="isAdminUser"
            >
                Edit
            </button>
        </div>
    </div>

    <div class="contact-details">
        <p
            *ngIf="
                companyDetail &&
                companyDetail.phone &&
                companyDetail.phone.number
            "
        >
            <i class="material-icons-outlined">call</i>
            <span class="smalltext">
                {{
                    companyDetail.phone.number
                        | formatPhonePipe : '###-###-####'
                }}</span
            >
        </p>

        <p
            *ngIf="
                companyDetail &&
                companyDetail.admins &&
                companyDetail.admins.length > 0 &&
                companyDetail.admins[0].email
            "
        >
            <i class="material-icons-outlined">alternate_email</i>
            <span class="smalltext">{{ companyDetail.admins[0].email }}</span>
        </p>

        <p
            *ngIf="
                companyDetail &&
                companyDetail.address &&
                companyDetail.address.address
            "
        >
            <i class="material-icons-outlined">location_on</i>

            <span class="smalltext" style="word-break: break-all">
                {{ companyDetail.address.address }},&nbsp;{{
                    companyDetail.address.city
                }},<br />&nbsp;{{ companyDetail.address.state }},&nbsp;{{
                    companyDetail.address.zip
                        | formatPhonePipe
                            : '###
                                ###'
                            : true
                }},<br />&nbsp;{{ companyDetail.address.country }}
            </span>
        </p>
    </div>

    <div
        class="company-details__item"
        *ngIf="companyDetail && companyDetail.legalName"
    >
        <p class="company-details__item--title">Legal Name</p>
        <p class="company-details__item--text">
            {{ companyDetail.legalName }}
        </p>
    </div>

    <div
        class="company-details__item"
        *ngIf="companyDetail && companyDetail.website"
    >
        <p class="company-details__item--title">Website</p>
        <p class="company-details__item--text">
            {{ companyDetail.website }}
        </p>
    </div>
</div>
