import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatPhonePipe',
})
export class FormatPhonePipe implements PipeTransform {
    transform(value: string, formatStr: string, u = false): string {
        if (!value) {
            return '';
        }

        let newString: string = value.replace(/\D/g, '');
        const values: string[] = newString.split('');
        const formatChars: string[] = formatStr.split('');

        for (let i = 0; i < formatChars.length; i++) {
            if (formatChars[i] === '#' && values.length > 0) {
                formatChars[i] = values.splice(0, 1)[0];
            } else if (values.length <= 0) {
                formatChars[i] = '';
            }
        }

        newString = formatChars.join('');
        return u ? newString.toUpperCase() : newString;
    }
}
