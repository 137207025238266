<div class="user-dialog">
    <div class="user-dialog__title">
        {{ isEdit ? 'Edit User' : 'Add User' }}
    </div>

    <div class="user-dialog__body">
        <ejs-tab id="tab_default" heightAdjustMode="Auto" (selected)="handleTabChange($event)">
            <e-tabitems>
                <e-tabitem [header]="headerText[0]">
                    <ng-template #content>
                        <form id="reactive" [formGroup]="form" class="user-dialog__form form">
                            <div class="form__group required">
                                <ejs-textbox placeholder="First Name" floatLabelType="Always" formControlName="firstName" />

                                <ng-container *ngIf="companyDetailsF['firstName'].touched && companyDetailsF['firstName'].errors">
                                    <p class="caption status-danger" *ngIf="companyDetailsF['firstName'].errors['required']">
                                        First Name is required.
                                    </p>

                                    <p class="caption status-danger" *ngIf="companyDetailsF['firstName'].errors['maxlength']">
                                        First Name can't be more than 255 characters.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group">
                                <ejs-textbox placeholder="Middle Name" floatLabelType="Always" formControlName="middleName" />

                                <ng-container
                                    *ngIf="
                                        companyDetailsF['middleName'].touched &&
                                        companyDetailsF['middleName'].errors &&
                                        companyDetailsF['middleName'].value
                                    "
                                >
                                    <p class="caption status-danger" *ngIf="companyDetailsF['middleName'].errors['maxlength']">
                                        Middle Name can't be more than 255 characters.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group required">
                                <ejs-textbox placeholder="Last Name" floatLabelType="Always" formControlName="lastName" />

                                <ng-container *ngIf="companyDetailsF['lastName'].touched && companyDetailsF['lastName'].errors">
                                    <p class="caption status-danger" *ngIf="companyDetailsF['lastName'].errors['required']">
                                        Last Name is required.
                                    </p>

                                    <p class="caption status-danger" *ngIf="companyDetailsF['lastName'].errors['maxlength']">
                                        Last Name can't be more than 255 characters.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group required">
                                <ejs-textbox placeholder="Email" floatLabelType="Always" formControlName="email" type="email" />

                                <ng-container *ngIf="companyDetailsF['email'].touched && companyDetailsF['email'].errors">
                                    <p class="caption status-danger" *ngIf="companyDetailsF['email'].errors['required']">
                                        Email is required.
                                    </p>

                                    <p class="caption status-danger" *ngIf="companyDetailsF['email'].errors['pattern']">
                                        Enter a valid email id.
                                    </p>
                                    <p class="caption status-danger" *ngIf="companyDetailsF['email'].errors['mustMatch']">
                                        Email does not match the confirm email!
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group required">
                                <ejs-textbox
                                    placeholder="Confirm Email"
                                    floatLabelType="Always"
                                    formControlName="confirmEmail"
                                    type="email"
                                />

                                <ng-container *ngIf="companyDetailsF['confirmEmail'].touched && companyDetailsF['confirmEmail'].errors">
                                    <p class="caption status-danger" *ngIf="companyDetailsF['confirmEmail'].errors['required']">
                                        Confirm email is required.
                                    </p>

                                    <p class="caption status-danger" *ngIf="companyDetailsF['confirmEmail'].errors['pattern']">
                                        Enter a valid email id.
                                    </p>

                                    <p class="caption status-danger" *ngIf="companyDetailsF['confirmEmail'].errors['mustMatch']">
                                        Email does not match the confirm email!
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__two-column">
                                <div class="form__group">
                                    <ejs-maskedtextbox
                                        placeholder="Mobile Number"
                                        name="phone"
                                        floatLabelType="Always"
                                        mask="000-000-0000"
                                        formControlName="phone"
                                    />

                                    <ng-container *ngIf="companyDetailsF['phone'].touched">
                                        <p class="caption status-danger" *ngIf="companyDetailsF['phone'].invalid">
                                            Enter Phone Number (123-456-7890) this format.
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="form__group">
                                    <ejs-textbox placeholder="Extension" floatLabelType="Always" formControlName="extension" />
                                </div>
                            </div>

                            <div class="form__group">
                                <ejs-dropdownlist
                                    #userRoleRef
                                    id="userRoleRef"
                                    name="role"
                                    formControlName="role"
                                    [dataSource]="userRoles"
                                    placeholder="Select Role"
                                    floatLabelType="Always"
                                    [fields]="fields"
                                />

                                <ng-container *ngIf="companyDetailsF['phone'].touched">
                                    <p class="caption status-danger" *ngIf="companyDetailsF['phone'].invalid">
                                        Enter Phone Number (123-456-7890) this format.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group switch">
                                <label for="checked">Active</label>
                                <ejs-switch id="checked" formControlName="active" [(ngModel)]="isUserActive"></ejs-switch>
                            </div>

                            <div class="form__group switch">
                                <label for="checked">Revoke Access</label>
                                <ejs-switch id="checked" formControlName="revokeAccess"></ejs-switch>
                            </div>

                            <div class="form__footer">
                                <button [ngClass]="['e-btn e-outline e-danger']" id="cancelBtn" content="Cancel" (click)="cancel()">
                                    Cancel
                                </button>

                                <button
                                    [ngClass]="['e-btn e-outline', !form.valid || disableFormFlag ? 'e-disabled' : 'e-success']"
                                    id="saveBtn"
                                    content="Save"
                                    (click)="updateEmployee()"
                                    [disabled]="!form.valid || disableFormFlag"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </ng-template>
                </e-tabitem>

                <e-tabitem [header]="headerText[1]" *ngIf="isEdit">
                    <ng-template #content>
                        <app-address-form
                            [isVisible]="selectedTabIndex === 1"
                            [formData]="addressFormData"
                            [parentForm]="form"
                            (cancelAddress)="handleAddressFormCancel($event)"
                            (submitAddress)="handleAddressSubmit($event)"
                            (addressFormUpdated)="handleAddressFormUpdated($event)"
                        />
                    </ng-template>
                </e-tabitem>
            </e-tabitems>
        </ejs-tab>
    </div>
</div>
