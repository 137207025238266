import { Component, OnDestroy, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { Constant } from '@core/constants';
import { AuthState } from '@core/models';
import { MenuService, UserService } from '@core/services';
import * as fromDeps from '@pages/auth/dependencies';

@Component({
    selector: 'app-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();
    userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
    userInfo!: AuthState;
    userName: string = '';
    isDropdownOpen: boolean = false;
    @ViewChild('dropdownMenu', { static: false })
    dropdownMenu!: ElementRef;
    private unSubscribeClickListener!: () => void;

    constructor(
        private constant: Constant,
        private router: Router,
        private menuService: MenuService,
        private userService: UserService,
        private renderer: Renderer2,
        private authFacade: fromDeps.store.AuthFacade
    ) {}

    ngOnInit() {
        this.updateUserName();

        this.userService.userNameChanged$.pipe(takeUntil(this.destroy$)).subscribe({
            next: (status: boolean) => {
                if (status) {
                    this.updateUserName();
                }
            },
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    updateUserName = () => {
        const userStr: string | null = localStorage.getItem('auth');
        this.userInfo = userStr ? JSON.parse(userStr) : null;

        this.userName =
            (((((this.userInfo || {}).user || {}).profile || {}).name || {}).first || '') +
            ' ' +
            (((((this.userInfo || {}).user || {}).profile || {}).name || {}).last || '');
        this.userService.updateUserNameChangeStatus(false);
    };

    toggleSidebar() {
        this.menuService.toggleMenu();
    }

    logOut() {
        this.authFacade.onLogout();
    }

    goToDashboard() {
        this.router.navigateByUrl(this.constant.REDIRECT_LINKS.DASHBOARD);
    }

    handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.goToDashboard();
        }
    };

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
        if (this.isDropdownOpen) {
            this.registerClickOutsideListener();
        } else {
            this.unregisterClickOutsideListener();
        }
    }

    handleHeaderDropdown = (item: { title: string }) => {
        const { title } = item;

        if (title === 'Log out') {
            this.logOut();
        } else if (title === 'Profile') {
            const url: string = this.constant.REDIRECT_LINKS.PROFILE;
            this.router.navigateByUrl(url);
        }
    };

    registerClickOutsideListener() {
        this.unSubscribeClickListener = this.renderer.listen('document', 'click', this.onClickOutside);
    }

    unregisterClickOutsideListener() {
        this.unSubscribeClickListener();
    }

    onClickOutside = (event: MouseEvent) => {
        if (!this.dropdownMenu.nativeElement.contains(event.target)) {
            this.isDropdownOpen = false;
            this.unregisterClickOutsideListener();
        }
    };

    getUserInitials(userName: string): string {
        const names = (userName || '').split(' ');
        const initials = names
            .map((name) => name.charAt(0))
            .join('')
            .toUpperCase();

        return initials || '';
    }
}
