import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(): boolean {
    if (location.origin === 'https://easycheque.ca' && environment.redirectUrl) {
      this.router.navigate([environment.redirectUrl]);
      return false;
    }

    return true;
  }
}