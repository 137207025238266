<div class="verify-user d-flex align-items-center justify-content-center">
    <div class="verify-user__content">
        <div class="verify-user__header d-flex align-items-center justify-content-center">
            <a href="/" class="verify-user__header--logo">
                <img src="assets/images/logo.png" alt="App Logo" height="1%" width="100%" />
            </a>
        </div>

        <div class="verify-user__description">
            Welcome to easyCheque. <br />
            Please create a password to complete resetting your password.
        </div>

        <form class="forgot-password__form form" (ngSubmit)="resetPassword(resetPassForm)" #resetPassForm="ngForm" aria-labelledby="title">
            <div class="form__group form__password template">
                <div id="pass" class="e-float-input form__field" [ngClass]="{ 'e-success': password.valid }">
                    <input
                        type="password"
                        id="input-password"
                        name="password"
                        [(ngModel)]="newPassword"
                        #password="ngModel"
                        required
                        pattern="(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d$@$!%#^*?&].{11,}"
                    />
                    <span class="e-float-line"></span>
                    <label
                        class="e-float-text"
                        [ngClass]="{
                            'e-label-top': newPassword !== '',
                            'e-label-bottom': newPassword === ''
                        }"
                        for="name"
                        >Create Password</label
                    >

                    <span
                        *ngIf="!showPasswordFlag"
                        class="material-icons-outlined pass-icon"
                        icon="eye-off-outline"
                        (click)="togglePassword('input-password')"
                        (keypress)="togglePassword('input-password')"
                        tabindex="0"
                    >
                        visibility
                    </span>

                    <span
                        *ngIf="showPasswordFlag"
                        class="material-icons-outlined pass-icon"
                        icon="eye-outline"
                        (click)="togglePassword('input-password')"
                        (keypress)="togglePassword('input-password')"
                        tabindex="0"
                    >
                        visibility_off
                    </span>
                </div>
                <span class="e-error" *ngIf="password.errors && password.errors['required'] && (password.dirty || password.touched)">
                    Enter password
                </span>
                <span class="e-error" *ngIf="password.errors && password.errors['pattern'] && (password.dirty || password.touched)">
                    Password should contains minimum 12 characters, one lower case and one upper case.
                </span>
            </div>

            <div class="form__group form__password template">
                <div id="pass" class="e-float-input form__field" [ngClass]="{ 'e-success': rePass.valid }">
                    <input
                        type="password"
                        id="input-re-password"
                        name="rePass"
                        [(ngModel)]="confirmPassword"
                        #rePass="ngModel"
                        required
                        pattern="(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d$@$!%#^*?&].{11,}"
                    />
                    <span class="e-float-line"></span>
                    <label
                        class="e-float-text"
                        [ngClass]="{
                            'e-label-top': confirmPassword !== '',
                            'e-label-bottom': confirmPassword === ''
                        }"
                        for="name"
                        >Confirm Password</label
                    >

                    <span
                        *ngIf="!showRePasswordFlag"
                        class="material-icons-outlined pass-icon"
                        icon="eye-off-outline"
                        (click)="togglePassword('input-re-password')"
                        (keypress)="togglePassword('input-re-password')"
                        tabindex="0"
                    >
                        visibility
                    </span>

                    <span
                        *ngIf="showRePasswordFlag"
                        class="material-icons-outlined pass-icon"
                        icon="eye-outline"
                        (click)="togglePassword('input-re-password')"
                        (keypress)="togglePassword('input-re-password')"
                        tabindex="0"
                    >
                        visibility_off
                    </span>
                </div>
                <span class="e-error" *ngIf="rePass.errors && rePass.errors['required'] && (rePass.dirty || rePass.touched)">
                    Enter password
                </span>

                <span class="e-error" *ngIf="rePass.errors && rePass.errors['pattern'] && (rePass.dirty || rePass.touched)">
                    Password should contains minimum 12 characters, one lower case and one upper case.
                </span>

                <span class="e-error" *ngIf="password.value !== rePass.value && (rePass.dirty || rePass.touched)">
                    Password does not match the confirm password.
                </span>
            </div>

            <div class="form__group template center">
                <div class="submitBtn">
                    <button
                        class="submit-btn e-btn"
                        id="submit-btn"
                        ejs-progressbutton
                        content="Save Password"
                        [enableProgress]="true"
                        [spinSettings]="spinCenter"
                        [animationSettings]="slideRight"
                        cssClass="e-outline e-success e-reverse-progress"
                        type="submit"
                        [duration]="1000"
                        [disabled]="submitted || !resetPassForm.valid || password.value !== rePass.value"
                    >
                        Save Password
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
