import { Component, OnInit } from '@angular/core';
import { DialogService } from '@ngneat/dialog';

import { CompanyDetails } from '@app/core/models';
import { EditCompanyDetailComponent } from './edit-company-details/edit-company-details.component';
import { OrganizationService, UserService } from '@core/services';
import { Constant } from '@core/constants';

@Component({
    selector: 'app-company-details',
    styleUrls: ['./company-details.component.scss'],
    templateUrl: './company-details.component.html',
})
export class CompanyDetailsComponent implements OnInit {
    public companyDetail: CompanyDetails = new CompanyDetails();
    public loading: boolean = false;
    public organizationID: string = '';
    public isAdminUser: boolean = false;

    constructor(
        private dialog: DialogService,
        private organizationService: OrganizationService,
        private userService: UserService,
        private constant: Constant
    ) {}

    ngOnInit(): void {
        const role: string = this.userService.userRole;
        this.isAdminUser = role === this.constant.USER_ROLES.ADMIN;
        this.organizationID = this.userService.organizationId;
        this.getCompanyDetails();
    }

    getCompanyDetails = () => {
        this.loading = true;
        this.organizationService.getOrganizationInfo(this.organizationID).subscribe({
            next: (data) => {
                this.companyDetail = new CompanyDetails(data as unknown as CompanyDetails);
                this.loading = false;
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    handleCompanyEdit = () => {
        const dialogRef = this.dialog.open(EditCompanyDetailComponent, {
            minWidth: '50vw',
            maxWidth: '100%',
            maxHeight: '80vh',
            data: {
                formData: this.companyDetail,
                organizationID: this.organizationID,
            },
        });

        dialogRef.afterClosed$.subscribe((result) => {
            this.getCompanyDetails();
        });
    };
}
