import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { CommonService } from '@core/services';
import { IECHQAddress, IEmployee, IUserPayload } from '@core/models';
import { Constant } from '@core/constants';

@Injectable({
    providedIn: 'root',
})
export class EmployeeService {
    baseURL = '';

    constructor(
        private http: HttpClient,
        private constant: Constant,
        private commonService: CommonService
    ) {
        this.baseURL = this.commonService.getBaseURL('v1', 'employee');
    }

    getEmployee(): Observable<IEmployee> {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_EMPLOYEE;
        return this.http.get<IEmployee>(this.baseURL + url, httpOptions);
    }

    getEmployeeInfo(empID: string): Observable<IEmployee> {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_EMPLOYEE_INFO(empID);
        return this.http.get<IEmployee>(this.baseURL + url, httpOptions);
    }

    getAllEmployees(id: string): Observable<IEmployee[]> {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_ALL_EMPLOYEES(id);
        return this.http.get<IEmployee[]>(this.baseURL + url, httpOptions);
    }

    createEmployee = (payload: IUserPayload) => {
        const httpOptions = this.commonService.getHttpOptions();
        return this.http.post<IEmployee>(this.baseURL, payload, httpOptions);
    };

    updateAddress = (
        empID: string,
        payload: IECHQAddress
    ): Observable<IECHQAddress> => {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string =
            this.constant.API_URLS.UPDATE_EMPLOYEE_ADDRESS(empID);
        return this.http.put<IECHQAddress>(
            this.baseURL + url,
            payload,
            httpOptions
        );
    };

    updateEmployee = (
        empID: string,
        payload: IUserPayload
    ): Observable<IEmployee> => {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.UPDATE_EMPLOYEE(empID);
        return this.http.put<IEmployee>(
            this.baseURL + url,
            payload,
            httpOptions
        );
    };

    getEmployeeAddress = (empID: string): Observable<IECHQAddress> => {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string =
            this.constant.API_URLS.UPDATE_EMPLOYEE_ADDRESS(empID);
        return this.http.get<IECHQAddress>(this.baseURL + url, httpOptions);
    };
}
