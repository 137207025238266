import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService, EmployeeService, MenuService } from '@core/services';
import { IEmployee } from '@core/models';

@Component({
    selector: 'app-dashboard',
    styleUrls: ['./dashboard.component.scss'],
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
    public employeeList: IEmployee[] = [];
    public userData: IEmployee | null = null;
    public refreshDashboard = false;
    public refreshSubscription: Subscription | null = null;

    constructor(private userService: UserService, private employeeService: EmployeeService, private menuService: MenuService) {}

    ngOnInit(): void {
        this.menuService.updateMenuSelection('dashboard');
        this.getAllEmployees();

        this.refreshSubscription = this.menuService.menuState.subscribe((val: boolean) => {
            this.refreshDashboard = false;

            setTimeout(() => {
                this.refreshDashboard = true;
            }, 1000);
        });
    }

    ngOnDestroy(): void {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    getAllEmployees() {
        const userId: string = this.userService.userId;
        if (userId) {
            this.employeeService.getAllEmployees(userId).subscribe((data) => {
                this.employeeList = data || [];
                this.userData =
                    this.employeeList.filter((res) => {
                        return res.id == userId;
                    })[0] || null;
            });
        }
    }
}
