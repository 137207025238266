<div class="dashboard d-flex">
    <div class="dashboard__card card-one">
        <app-personal-details-card [userInfo]="userData" [employeeList]="employeeList" *ngIf="refreshDashboard" />
    </div>

    <div class="dashboard__card card-two">
        <app-bank-account-chart [refreshDashboard]="refreshDashboard" />
    </div>

    <div class="dashboard__card card-three">
        <app-expense-account-chart [refreshDashboard]="refreshDashboard" />
    </div>

    <div class="dashboard__card card-four">
        <app-post-dated-cheques [refreshDashboard]="refreshDashboard" />
    </div>
</div>
