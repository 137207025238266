import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { LoaderState } from '@core/models';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private loaderSubject = new Subject<LoaderState>();
    public loaderState: Observable<LoaderState> =
        this.loaderSubject.asObservable();

    show() {
        this.loaderSubject.next(<LoaderState>{ show: true });
    }

    hide() {
        this.loaderSubject.next(<LoaderState>{ show: false });
    }
}
