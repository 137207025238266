<div class="post-dated-cheques" *ngIf="refreshDashboard">
    <div class="post-dated-cheques__title">Post Dated Cheques</div>

    <ejs-grid
        #postDatedTransactionsGrid
        id="postDatedTransactionsGrid"
        [dataSource]="transactions"
        rowHeight="45"
        (load)="transactionConfig()"
        [allowSorting]="true"
    >
        <e-columns>
            <e-column field="bankAccountNumber" headerText="Bank Name" minWidth="150" />
            <e-column field="chequeNumber" headerText="Cheque Number" minWidth="150">
                <ng-template #template let-data>
                    <a
                        href="javascript:void(0)"
                        class="post-dated-cheques__link"
                        *ngIf="data.paymentTypeName === 'Cheque'"
                        (click)="handlePrintCheque($event, data.chequeId)"
                    >
                        {{ data.chequeNumber }}
                    </a>

                    <a href="javascript:void(0)" class="post-dated-cheques__link" *ngIf="data.paymentTypeName !== 'Cheque'">
                        {{ data.paymentTypeName || '' }}
                    </a>
                </ng-template>
            </e-column>
            <e-column field="chequeDate" headerText="Transaction Date" minWidth="100">
                <ng-template #template let-data>
                    {{ convertToUserTz(data.transactionDate) ? convertToUserTz(data.transactionDate) : '' }}
                </ng-template>
            </e-column>

            <e-column field="amount" headerText="Amount" minWidth="100" textAlign="right">
                <ng-template #template let-data>
                    {{ data.amount | currency }}
                </ng-template>
            </e-column>

            <e-column field="supplierCompanyName" headerText="Supplier Name" minWidth="170" />

            <e-column field="memo" headerText="Memo" width="250" />
        </e-columns>
    </ejs-grid>

    <iframe #pdfViewer [src]="pdfUrl" width="100%" height="600" class="pdf-preview"></iframe>
</div>
