import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private menuSubject = new BehaviorSubject<boolean>(false);
    private menuSelectionSubject = new BehaviorSubject<string>('');
    menuState: Observable<boolean> = this.menuSubject.asObservable();
    menuSelection$: Observable<string> =
        this.menuSelectionSubject.asObservable();

    toggleMenu() {
        this.menuSubject.next(!this.menuSubject.value);
    }

    toggleStatus = (status: boolean) => {
        this.menuSubject.next(status);
    };

    updateMenuSelection = (selectedMenu: string) => {
        this.menuSelectionSubject.next(selectedMenu);
    };
}
