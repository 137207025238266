import { Component } from '@angular/core';

@Component({
    selector: 'app-ec-auth',
    styleUrls: ['./auth.component.scss'],
    template: `
        <div class="ec-app">
            <main class="ec-app__layout">
                <section class="ec-app__layout-container">
                    <div class="ec-app__column">
                        <div class="ec-card">
                            <div class="ec-card__body">
                                <div class="ec-app__no-auth">
                                    <router-outlet></router-outlet>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    `,
})
export class AuthComponent {}
