import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';

import { Constant } from '@core/constants';
import { StartupService, ToasterService } from '@core/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('toasterElement', { static: true })
        toasterElement!: ToastComponent;
    public position = { X: 'Right' };

    constructor(
        private router: Router,
        private constant: Constant,
        private bnIdle: BnNgIdleService,
        private startup: StartupService,
        private toasterService: ToasterService
    ) {}

    ngOnInit(): void {
        this.hideSpinner();
        this.bnIdle.startWatching(600).subscribe((isTimedOut: boolean) => {
            if (isTimedOut) {
                console.log('session expired');
                localStorage.clear();
                const url: string = this.constant.REDIRECT_LINKS.LOGIN;
                this.router.navigateByUrl(url);
            }
        });
        this.redirectToPath();
    }

    ngAfterViewInit(): void {
        this.toasterService.setToaster(this.toasterElement);
    }

    // Function to hide the spinner
    hideSpinner = () => {
        const spinner = document.getElementById('nb-global-spinner');
        if (spinner) {
            spinner.style.display = 'none';
        }
    };

    redirectToPath() {
        if (location.pathname != '/approve') {
            if (
                this.startup.getNavigationData.loggedInStatus &&
                this.startup.getNavigationData.isTokenValid &&
                (location.pathname == '/' ||
                    location.pathname == this.constant.REDIRECT_LINKS.LOGIN ||
                    location.pathname == this.constant.REDIRECT_LINKS.REGISTER)
            ) {
                this.router.navigateByUrl(
                    this.constant.REDIRECT_LINKS.DASHBOARD
                );
            } else if (
                this.startup.getNavigationData.loggedInStatus &&
                this.startup.getNavigationData.isTokenValid &&
                location.pathname != '/'
            ) {
                this.router.navigateByUrl(location.pathname);
            } else if (
                this.startup.getNavigationData.loggedInStatus &&
                !this.startup.getNavigationData.isTokenValid
            ) {
                this.router.navigateByUrl(this.constant.REDIRECT_LINKS.LOGIN);
            }
        }
    }
}
