import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from '@core/services';
import { LoaderState } from '@core/models';

@Component({
    selector: 'app-loader',
    templateUrl: './vta.loader.component.html',
    styleUrls: ['./vta.loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
    public show = false;
    private subscription: Subscription = new Subscription();

    constructor(private loaderService: LoaderService) {}

    ngOnInit() {
        this.subscription = this.loaderService.loaderState.subscribe(
            (state: LoaderState) => {
                this.show = state.show;
            }
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
