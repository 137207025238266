<div class="register d-flex align-items-center justify-content-center">
    <div class="register__content">
        <div class="register__header d-flex align-items-center justify-content-center">
            <a href="/" class="register__header--logo">
                <img src="assets/images/logo.png" alt="App Logo" height="1%" width="100%" />
            </a>
        </div>

        <form class="register__form form" [formGroup]="form" aria-labelledby="title">
            <ng-container *ngIf="!showSuccessMessage">
                <div class="form__group">
                    <div class="e-float-input form__field" [ngClass]="{ 'e-success': registerFormF['name'].valid }">
                        <input type="text" id="org-name" name="name" formControlName="name" />
                        <span class="e-float-line"></span>
                        <label
                            class="e-float-text required"
                            [ngClass]="{
                                'e-label-top': registerFormF['name'].value !== '',
                                'e-label-bottom': registerFormF['name'].value === ''
                            }"
                            for="name"
                        >
                            Organization name
                        </label>
                    </div>

                    <span class="e-error" *ngIf="registerFormF['name']?.dirty && registerFormF['name']?.errors?.['required']">
                        Organization name is required
                    </span>
                </div>

                <div class="form__group">
                    <div id="email" class="form__field e-float-input" [ngClass]="{ 'e-success': registerFormF['email'].valid }">
                        <input type="email" id="email" formControlName="email" />

                        <span class="e-float-line"></span>

                        <label
                            class="e-float-text required"
                            [ngClass]="{
                                'e-label-top': registerFormF['email'].value !== '',
                                'e-label-bottom': registerFormF['email'].value === ''
                            }"
                            for="name"
                        >
                            Email
                        </label>
                    </div>

                    <ng-container *ngIf="registerFormF['email']?.dirty && registerFormF['email']?.errors?.['required']">
                        <p class="e-error">Email is required!</p>
                    </ng-container>

                    <p class="e-error" *ngIf="registerFormF['email']?.dirty && registerFormF['email']?.errors?.['pattern']">
                        Enter a valid email address
                    </p>
                </div>

                <div class="form__group">
                    <div id="confirm-email" class="e-float-input form__field" [ngClass]="{ 'e-success': registerFormF['reEmail'].valid }">
                        <input type="email" id="reEmail" formControlName="reEmail" />
                        <span class="e-float-line"></span>

                        <label
                            class="e-float-text required"
                            [ngClass]="{
                                'e-label-top': registerFormF['reEmail'].value !== '',
                                'e-label-bottom': registerFormF['reEmail'].value === ''
                            }"
                            for="name"
                        >
                            Confirm Email
                        </label>
                    </div>

                    <ng-container *ngIf="registerFormF['reEmail']?.dirty && registerFormF['reEmail']?.errors">
                        <p class="e-error" *ngIf="registerFormF['reEmail']?.dirty && registerFormF['reEmail']?.errors?.['required']">
                            Confirm email is required!
                        </p>

                        <p class="e-error" *ngIf="registerFormF['reEmail']?.dirty && registerFormF['reEmail']?.errors?.['pattern']">
                            Enter a valid email address
                        </p>

                        <p class="e-error" *ngIf="registerFormF['reEmail']?.dirty && registerFormF['reEmail']?.errors?.['mustMatch']">
                            Email does not match the confirm email!
                        </p>
                    </ng-container>
                </div>

                <div class="form__group">
                    <div class="e-float-input form__field" [ngClass]="{ 'e-success': registerFormF['firstName'].valid }">
                        <input type="text" id="org-firstName" name="firstName" formControlName="firstName" />
                        <span class="e-float-line"></span>
                        <label
                            class="e-float-text required"
                            [ngClass]="{
                                'e-label-top': registerFormF['firstName'].value !== '',
                                'e-label-bottom': registerFormF['firstName'].value === ''
                            }"
                            for="firstName"
                        >
                            First name
                        </label>
                    </div>

                    <span class="e-error" *ngIf="registerFormF['firstName']?.dirty && registerFormF['firstName']?.errors?.['required']"
                        >First name is required
                    </span>
                </div>

                <div class="form__group">
                    <div class="e-float-input form__field" [ngClass]="{ 'e-success': registerFormF['middleName'].valid }">
                        <input type="text" id="org-middleName" name="middleName" formControlName="middleName" />

                        <span class="e-float-line"></span>

                        <label
                            class="e-float-text"
                            [ngClass]="{
                                'e-label-top': registerFormF['middleName'].value !== '',
                                'e-label-bottom': registerFormF['middleName'].value === ''
                            }"
                            for="middleName"
                        >
                            Middle name
                        </label>
                    </div>
                </div>

                <div class="form__group">
                    <div class="e-float-input form__field" [ngClass]="{ 'e-success': registerFormF['lastName'].valid }">
                        <input type="text" id="org-lastName" name="lastName" formControlName="lastName" />

                        <span class="e-float-line"></span>

                        <label
                            class="e-float-text required"
                            [ngClass]="{
                                'e-label-top': registerFormF['lastName'].value !== '',
                                'e-label-bottom': registerFormF['lastName'].value === ''
                            }"
                            for="lastName"
                        >
                            Last name
                        </label>
                    </div>
                    <span class="e-error" *ngIf="registerFormF['lastName']?.dirty && registerFormF['lastName']?.errors?.['required']"
                        >Last name is required
                    </span>
                </div>

                <div class="form__group center">
                    <div class="submitBtn">
                        <button
                            class="submit-btn e-btn"
                            id="submit-btn"
                            ejs-progressbutton
                            content="REGISTER"
                            [enableProgress]="true"
                            [spinSettings]="spinCenter"
                            [duration]="4000"
                            [animationSettings]="slideRight"
                            cssClass="e-outline e-reverse-progress"
                            [ngClass]="[form.valid ? 'e-success' : '']"
                            type="submit"
                            [disabled]="!form.valid"
                            [duration]="1000"
                            (click)="register()"
                        >
                            REGISTER
                        </button>
                    </div>
                </div>
            </ng-container>

            <div class="success-msg" *ngIf="showSuccessMessage">
                <div class="row" style="justify-content: center">
                    <div>
                        <div><span>Welcome to easyCheque</span></div>
                    </div>
                </div>

                <br />

                <p>
                    Your organization has been registered with easyCheque. We have sent you a verification email to confirm your email
                    address and set your password.
                </p>

                <br />

                <span>easyCheque Team.</span>

                <section class="form__group center form__action" aria-label="Sign in">
                    <a class="text-link" routerLink="../login"> Go back to login page </a>
                </section>
            </div>

            <section *ngIf="!showSuccessMessage" class="form__group center form__action" aria-label="Sign in">
                Already have an account?
                <a class="text-link" routerLink="../login">Log in</a>
            </section>
        </form>
    </div>
</div>
