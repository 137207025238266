import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';

import {
    LoaderComponent,
    AuthComponent,
    FooterComponent,
    HeaderComponent,
    AddressComponent,
    ConfirmationComponent,
} from '@shared/components';
import { FormatPhonePipe } from './pipes';
import { MustMatchDirective } from './directives/must-match.directive';

@NgModule({
    declarations: [
        LoaderComponent,
        AuthComponent,
        FooterComponent,
        HeaderComponent,
        FormatPhonePipe,
        AddressComponent,
        ConfirmationComponent,
        MustMatchDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ButtonModule,
        DropDownListModule,
        TextBoxAllModule,
        MaskedTextBoxAllModule,
    ],
    providers: [],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ButtonModule,
        LoaderComponent,
        AuthComponent,
        FooterComponent,
        HeaderComponent,
        FormatPhonePipe,
        AddressComponent,
        ConfirmationComponent,
        MustMatchDirective,
    ],
})
export class SharedModule {}
