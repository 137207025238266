import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { EChartsOption } from 'echarts';

import { BankAccountService, ChequeService } from '@core/services';
import { IBankAccounts, ICheque, IMeta } from '@core/models';

@Component({
    selector: 'app-bank-account-chart',
    styleUrls: ['./bank-account-chart.component.scss'],
    templateUrl: './bank-account-chart.component.html',
})
export class BankAccountChartComponent implements AfterViewInit, OnInit {
    @Input() refreshDashboard = false;
    public transactions: ICheque[] = [];
    public title?: string = 'Bank Accounts - Last 3 Months';
    public bankAccounts: IBankAccounts[] = [];
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public colors: string[] = [
        '#EC4954',
        '#725CD4',
        '#4AA3C8',
        '#4577F8',
        '#F19973',
        '#ED5C52',
        '#EF8B96',
        '#6A7C95',
        '#8D7EDC',
        '#80C9DC',
        '#6992F9',
        '#F4AE90',
        '#EE7773',
        '#0C2550',
    ];

    public emptyMessage = 'No expenses available with valid bank accounts in the last three months';
    public options!: EChartsOption;
    public mergeOptions!: EChartsOption;

    constructor(private bankAccountService: BankAccountService, private chequeService: ChequeService) {
        this.fromDate = DateTime.local().minus({ months: 3 }).toJSDate();
        this.toDate = new Date();
    }

    ngOnInit(): void {
        this.options = {
            legend: {
                bottom: 0,
            },
            tooltip: {
                valueFormatter: (value) => '$' + (value as number).toFixed(2),
            },
            dataset: {
                source: [],
            },
            xAxis: { type: 'category' },
            yAxis: { type: 'value', axisLine: { show: true } },
            series: [],
        };
    }

    ngAfterViewInit(): void {
        this.getLastThreeMonthCheques();
    }

    objectValuesToArrayExceptField<T>(obj: Record<string, T>, fieldToExclude: string): T[] {
        const valuesArray: T[] = [];

        for (const key in obj) {
            if (key !== fieldToExclude) {
                valuesArray.push(obj[key]);
            }
        }

        return valuesArray;
    }

    getLastThreeMonthCheques = () => {
        let qString = '';

        if (this.fromDate && this.toDate && this.convertJSDateToUTC(this.fromDate) && this.convertJSDateToUTC(this.toDate)) {
            qString = `fromDate=${this.convertJSDateToUTC(this.fromDate)}&toDate=${this.convertJSDateToUTC(this.toDate)}`;
        }

        this.chequeService.getCheques(qString).subscribe({
            next: (res: { content: ICheque[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.transactions = res.content;
                    this.getBankAccounts();
                }
            },
            error: () => {
                this.transactions = [];
            },
        });
    };

    convertJSDateToUTC = (jsDate: Date) => {
        if (jsDate) {
            return jsDate.toISOString().slice(0, 10) + 'T00:00:00.000Z';
        }

        return '';
    };

    getBankAccounts = () => {
        this.bankAccountService.getBankAccounts().subscribe({
            next: (res: IBankAccounts[]) => {
                this.bankAccounts = res;
                const source = [['product', ...this.bankAccounts.map((bankAccount) => bankAccount.name)]];
                const transactionList: ICheque[] = this.transactions;

                transactionList.forEach((item: ICheque) => {
                    item['month'] = this.getMonthFromDate(item.transactionDate);
                });

                const lastThreeMonths: string[] = this.getLastThreeMonths();
                lastThreeMonths.forEach((month) => {
                    const monthlyTotalAmounts: any[] = [];

                    this.bankAccounts.forEach((bankAccount: IBankAccounts) => {
                        const totalAmount: number = transactionList
                            .filter((obj) => obj.month === month && obj.bankAccount === bankAccount.id)
                            .map((obj) => obj.amount)
                            .reduce((curr, prev) => curr + prev, 0);
                        monthlyTotalAmounts.push(totalAmount);
                    });
                    source.push([month, ...monthlyTotalAmounts]);
                });

                this.mergeOptions = {
                    dataset: {
                        source,
                    },
                    series: this.bankAccounts.map((bankAccount, index) => ({
                        type: 'bar',
                        itemStyle: {
                            color: this.colors[index],
                        },
                    })),
                };
            },
        });
    };

    getMonthFromDate = (dateString: string): string => {
        if (this.isValidDateString(dateString)) {
            const dateTime = DateTime.fromISO(dateString, { zone: 'utc' });
            return dateTime.toFormat('LLL');
        }

        return '';
    };

    getLastThreeMonths = (): string[] => {
        const today = DateTime.now();
        const months: string[] = [];

        for (let i = 0; i < 3; i++) {
            const lastMonth = today.minus({ months: i });
            const formattedMonth = lastMonth.toFormat('LLL');
            months.unshift(formattedMonth);
        }
        return months;
    };

    isValidDateString = (dateString: string) => {
        try {
            DateTime.fromISO(dateString, { zone: 'utc' });
            return true;
        } catch (error) {
            return false;
        }
    };

    convertNameToProperty = (name: string): string => {
        return name ? name.toLowerCase().split(' ').join('-') : '';
    };
}
