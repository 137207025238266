<div class="add-supplier">
    <div class="add-supplier__title">{{ title }}</div>

    <div class="add-supplier__body">
        <ejs-tab id="tab_default" heightAdjustMode="Auto" (selected)="handleTabChange($event)">
            <e-tabitems>
                <e-tabitem [header]="headerText[0]">
                    <ng-template #content>
                        <form id="reactive" [formGroup]="supplierForm" class="edit-company-details__form form">
                            <div class="form__group required">
                                <ejs-textbox placeholder="Company Name" floatLabelType="Always" formControlName="companyName" />

                                <ng-container *ngIf="supplierFormF['companyName'].touched && supplierFormF['companyName'].errors">
                                    <p class="caption status-danger" *ngIf="supplierFormF['companyName'].errors['required']">
                                        Company Name is required.
                                    </p>

                                    <p class="caption status-danger" *ngIf="supplierFormF['companyName'].errors['maxlength']">
                                        Company Name can't be more than 255 characters.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group required">
                                <ejs-textbox placeholder="Name On Cheque" floatLabelType="Always" formControlName="nameOnCheque" />

                                <ng-container *ngIf="supplierFormF['nameOnCheque'].touched && supplierFormF['nameOnCheque'].errors">
                                    <p class="caption status-danger" *ngIf="supplierFormF['nameOnCheque'].errors['required']">
                                        Name on cheque is required.
                                    </p>

                                    <p class="caption status-danger" *ngIf="supplierFormF['nameOnCheque'].errors['maxlength']">
                                        Name on cheque can't be more than 255 characters.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group">
                                <ejs-textbox placeholder="First Name" floatLabelType="Always" formControlName="first" />

                                <ng-container *ngIf="supplierFormF['first'].touched && supplierFormF['first'].errors">
                                    <p class="caption status-danger" *ngIf="supplierFormF['first'].errors['maxlength']">
                                        First Name can't be more than 255 characters.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group">
                                <ejs-textbox placeholder="Last Name" floatLabelType="Always" formControlName="last" />

                                <ng-container *ngIf="supplierFormF['last'].touched && supplierFormF['last'].errors">
                                    <p class="caption status-danger" *ngIf="supplierFormF['last'].errors['maxlength']">
                                        Last Name can't be more than 255 characters.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group">
                                <ejs-textbox placeholder="Email" floatLabelType="Always" formControlName="email" type="email" />

                                <ng-container *ngIf="supplierFormF['email'].touched && supplierFormF['email'].errors">
                                    <p class="caption status-danger" *ngIf="supplierFormF['email'].errors['pattern']">
                                        Enter a valid email id.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__two-column">
                                <div class="form__group">
                                    <div
                                        class="e-float-input"
                                        [ngClass]="{
                                            'e-success': supplierFormF['phone'].valid
                                        }"
                                    >
                                        <input type="text" id="phone" mask="000-000-0000" name="phone" formControlName="phone" />
                                        <span class="e-float-line"></span>
                                        <label class="e-float-text e-label-top" for="phone">Phone Number</label>
                                    </div>

                                    <ng-container *ngIf="supplierFormF['phone'].touched">
                                        <p class="caption status-danger" *ngIf="supplierFormF['phone'].invalid">
                                            Enter Phone Number (123-456-7890) in this format.
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="form__group">
                                    <div
                                        class="e-float-input"
                                        [ngClass]="{
                                            'e-success': supplierFormF['extension'].valid
                                        }"
                                    >
                                        <input type="text" id="extension" name="extension" formControlName="extension" />
                                        <span class="e-float-line"></span>
                                        <label class="e-float-text e-label-top" for="email">Extension</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form__group">
                                <ejs-textbox placeholder="Website" floatLabelType="Always" formControlName="website" />
                            </div>

                            <div class="form__group">
                                <ejs-textbox placeholder="Contact Name" floatLabelType="Always" formControlName="contactName" />

                                <ng-container *ngIf="supplierFormF['contactName'].touched && supplierFormF['contactName'].errors">
                                    <p class="caption status-danger" *ngIf="supplierFormF['contactName'].errors['maxlength']">
                                        Contact Name can't be more than 255 characters.
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form__group">
                                <ejs-dropdownlist
                                    id="defaultExpenseAccount"
                                    name="defaultExpenseAccount"
                                    [(ngModel)]="defaultExpenseAccount"
                                    (change)="changeDefaultExpenseAccount($event)"
                                    formControlName="defaultExpenseAccount"
                                    [dataSource]="expenseAccounts"
                                    placeholder="Default Expense Account"
                                    floatLabelType="Always"
                                    [fields]="fields"
                                    [showClearButton]="true"
                                />
                            </div>

                            <div class="form__group">
                                <ejs-dropdownlist
                                    id="defaultTaxCode"
                                    name="defaultTaxCode"
                                    formControlName="defaultTaxCode"
                                    [dataSource]="taxes"
                                    placeholder="Default Tax Code"
                                    floatLabelType="Always"
                                    [fields]="taxFields"
                                    [itemTemplate]="itemTemplate"
                                    [showClearButton]="true"
                                >
                                    <ng-template #itemTemplate="" let-data="">
                                        <div class="tax-display">{{ formatTaxDisplay(data) }}</div>
                                    </ng-template>
                                </ejs-dropdownlist>
                            </div>

                            <div class="form__group switch">
                                <label for="checked">Active</label>
                                <ejs-switch id="checked" formControlName="active" [(ngModel)]="isSupplierActive" />
                            </div>

                            <div class="form__footer">
                                <button [ngClass]="['e-btn e-outline e-danger']" id="cancelBtn" content="Cancel" (click)="cancel()">
                                    Cancel
                                </button>

                                <button
                                    [ngClass]="['e-btn e-outline', supplierForm.valid ? 'e-success' : '']"
                                    id="cancelBtn"
                                    content="Save"
                                    (click)="updateSupplier()"
                                    [disabled]="!supplierForm.valid"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </ng-template>
                </e-tabitem>

                <e-tabitem [header]="headerText[1]" *ngIf="isEdit">
                    <ng-template #content>
                        <app-address-form
                            [isVisible]="selectedTabIndex === 1"
                            [formData]="addressFormData"
                            [parentForm]="supplierForm"
                            (cancelAddress)="handleAddressFormCancel($event)"
                            (submitAddress)="handleAddressSubmit($event)"
                            (addressFormUpdated)="handleAddressFormUpdated($event)"
                        />
                    </ng-template>
                </e-tabitem>
            </e-tabitems>
        </ejs-tab>
    </div>
</div>
