<div class="create-check">
    <div class="create-check__body">
        <div class="create-check__title">{{ title }}</div>

        <form id="createCheckForm" [formGroup]="checkForm" class="create-check__form form">
            <div class="form__body">
                <div class="form__top">
                    <div class="form__group required">
                        <ejs-dropdownlist
                            #paymentTypeRef
                            id="paymentType"
                            name="paymentType"
                            formControlName="paymentType"
                            [dataSource]="paymentTypes"
                            placeholder="Payment Type"
                            floatLabelType="Always"
                            [fields]="fields"
                            (change)="handlePaymentTypeChange($event)"
                        />

                        <ng-container *ngIf="checkFormF['paymentType'].touched && checkFormF['paymentType'].errors">
                            <p class="caption status-danger" *ngIf="checkFormF['paymentType'].errors['required']">
                                Payment type is required.
                            </p>
                        </ng-container>
                    </div>

                    <div class="form__group required">
                        <ejs-dropdownlist
                            id="bankAccount"
                            name="bankAccount"
                            [(ngModel)]="bankAccount"
                            (change)="changeBankAccount(bankAccount, $event)"
                            formControlName="bankAccount"
                            [dataSource]="bankAccounts"
                            placeholder="Bank Details"
                            floatLabelType="Always"
                            [fields]="fields"
                        />

                        <ng-container *ngIf="checkFormF['bankAccount'].touched && checkFormF['bankAccount'].errors">
                            <p class="caption status-danger" *ngIf="checkFormF['bankAccount'].errors['required']">
                                Bank details is required.
                            </p>
                        </ng-container>
                    </div>

                    <div class="form__group required">
                        <ejs-dropdownlist
                            id="supplierId"
                            name="supplierId"
                            [(ngModel)]="supplierId"
                            (change)="changeSupplierName(supplierId, $event)"
                            formControlName="supplierId"
                            [dataSource]="supplierNames"
                            placeholder="Supplier Details"
                            floatLabelType="Always"
                            [fields]="supplierFields"
                        />

                        <ng-container *ngIf="checkFormF['supplierId'].touched && checkFormF['supplierId'].errors">
                            <p class="caption status-danger" *ngIf="checkFormF['supplierId'].errors['required']">
                                Supplier details is required.
                            </p>
                        </ng-container>
                    </div>

                    <div class="form__group">
                        <div class="form__group--address address">
                            <ng-template #addressEmpty>-</ng-template>
                        </div>
                    </div>

                    <div class="form__group">
                        <div class="form__group--address address">
                            <ng-container *ngIf="bankAccountAddress; else addressEmpty">
                                <div class="address__text">
                                    {{ bankAccountAddress.firstLine }}
                                </div>

                                <div class="address__text">
                                    <span *ngIf="bankAccountAddress.lastLine">{{ bankAccountAddress.lastLine }}, &nbsp;</span>
                                    {{ bankAccountAddress.zipCode }}
                                </div>

                                <div class="address__text">
                                    <span *ngIf="bankAccountAddress.city">{{ bankAccountAddress.city }}, &nbsp;</span>
                                    {{ bankAccountAddress.state }}
                                </div>

                                <div class="address__text">
                                    {{ bankAccountAddress.country }}
                                </div>
                            </ng-container>

                            <ng-template #addressEmpty>-</ng-template>
                        </div>
                    </div>

                    <div class="form__group required">
                        <div class="form__group--address address">
                            <ng-container *ngIf="supplierAddress; else addressEmpty">
                                <div class="address__text">
                                    {{ supplierAddress.firstLine }}
                                </div>

                                <div class="address__text">
                                    <span *ngIf="supplierAddress.lastLine">{{ supplierAddress.lastLine }}, &nbsp;</span>
                                    <span>{{ supplierAddress.zipCode }}</span>
                                </div>

                                <div class="address__text">
                                    <span *ngIf="supplierAddress.city">{{ supplierAddress.city }}, &nbsp;</span>
                                    {{ supplierAddress.state }}
                                </div>

                                <div class="address__text">
                                    {{ supplierAddress.country }}
                                </div>
                            </ng-container>

                            <ng-template #addressEmpty>-</ng-template>
                        </div>
                    </div>

                    <div class="form__group cheque-date required">
                        <ejs-datepicker
                            id="datepicker"
                            name="date"
                            required
                            [placeholder]="selectedPaymentType?.name === 'Cheque' ? 'Cheque Date' : 'Payment Date'"
                            floatLabelType="Always"
                            formControlName="transactionDate"
                        />

                        <ng-container *ngIf="checkFormF['transactionDate'].touched && checkFormF['transactionDate'].errors">
                            <p class="caption status-danger" *ngIf="checkFormF['transactionDate'].errors['required']">
                                Transaction Date is required
                            </p>
                        </ng-container>
                    </div>

                    <div class="form__group">
                        <ejs-textbox
                            placeholder="Customer Transaction Number"
                            floatLabelType="Always"
                            formControlName="customTransactionNumber"
                        />
                    </div>

                    <div class="form__group required" *ngIf="selectedPaymentType?.name === 'Cheque' && !checkFormF['batchPrinting'].value">
                        <ejs-textbox placeholder="Cheque Number" floatLabelType="Always" formControlName="chequeNumber" />

                        <ng-container *ngIf="checkFormF['chequeNumber'].touched && checkFormF['chequeNumber'].errors">
                            <p class="caption status-danger" *ngIf="checkFormF['chequeNumber'].errors['required']">
                                Cheque number is required.
                            </p>
                        </ng-container>
                    </div>

                    <div class="form__two-column amount">
                        <div class="form__group switch amount">
                            <label for="amount">Cheque/ePayment Amount</label>
                            <div class="address">
                                {{ checkFormF['amount'].value | currency }}
                            </div>
                        </div>

                        <div class="form__group--amount-text">
                            <ng-container *ngIf="amountInWords; else noAmountToDisplay"> ({{ amountInWords }}) </ng-container>

                            <ng-template #noAmountToDisplay>&nbsp;</ng-template>
                        </div>
                    </div>

                    <div class="form__group memo">
                        <ejs-textbox placeholder="Memo" floatLabelType="Always" formControlName="memo" />

                        <ng-container *ngIf="checkFormF['memo'].touched && checkFormF['memo'].errors">
                            <p class="caption status-danger" *ngIf="checkFormF['memo'].value && checkFormF['memo'].errors['maxlength']">
                                Memo can't be more than 255 characters.
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form__bottom">
                    <div class="form__bottom--header">
                        <div class="form__bottom--header-title">Gross Amount is inclusive of Taxes</div>

                        <div class="form__group switch batch-print" *ngIf="selectedPaymentType?.name === 'Cheque'">
                            <label for="checked">Batch Printing (Save this cheque to be printed later)</label>
                            <div class="switch-container">
                                <ejs-switch
                                    id="checked"
                                    formControlName="batchPrinting"
                                    (change)="handleBatchPrintChange($event)"
                                ></ejs-switch>

                                <div class="switch-container__text">
                                    {{ checkFormF['batchPrinting'].value ? 'Yes' : 'No' }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <ejs-grid
                        #inlineChequeGrid
                        id="inlineChequeGrid"
                        [dataSource]="data"
                        allowPaging="true"
                        [allowSorting]="true"
                        [pageSettings]="pageSettings"
                        [editSettings]="editSettings"
                        [toolbar]="toolbar"
                        (actionBegin)="actionBegin($event)"
                        (actionComplete)="actionComplete($event)"
                    >
                        <e-columns>
                            <e-column field="id" headerText="ID" isPrimaryKey="true" [visible]="false" />

                            <e-column field="expenseAccountNumber" headerText="Expense Account" width="200" />

                            <e-column field="taxCodeValue" width="150" headerText="Tax Code" />

                            <e-column field="amount" headerText="Gross Amount" width="120" format="C2" textAlign="right" />

                            <e-column field="taxAmount" headerText="Tax Amount" width="120" format="C2" textAlign="right" />

                            <e-column field="netAmount" headerText="Net Amount" width="120" format="C2" textAlign="right" />

                            <e-column field="description" headerText="Description" />
                        </e-columns>

                        <ng-template #editSettingsTemplate let-data>
                            <div [formGroup]="expenseAccountForm">
                                <table class="e-table e-inline-edit" cellspacing="0.25">
                                    <colgroup>
                                        <col style="width: 200px" />
                                        <col style="width: 150px" />
                                        <col style="width: 120px" />
                                        <col style="width: 120px" />
                                        <col style="width: 120px" />
                                        <col />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <td class="e-rowcell">
                                                <ejs-dropdownlist
                                                    id="expenseAccount"
                                                    name="expenseAccount"
                                                    (change)="changeExpenseAccount($event)"
                                                    formControlName="expenseAccount"
                                                    [dataSource]="expenseAccounts"
                                                    placeholder="Select expense account"
                                                    floatLabelType="Auto"
                                                    [fields]="fields"
                                                />

                                                <ng-container
                                                    *ngIf="expenseFormF['expenseAccount'].touched && expenseFormF['expenseAccount'].errors"
                                                >
                                                    <p
                                                        class="caption status-danger"
                                                        *ngIf="expenseFormF['expenseAccount'].errors['required']"
                                                    >
                                                        Expense account is required.
                                                    </p>
                                                </ng-container>
                                            </td>

                                            <td class="e-rowcell">
                                                <ejs-dropdownlist
                                                    id="taxCode"
                                                    name="taxCode"
                                                    (change)="changeTaxCode($event)"
                                                    formControlName="taxCode"
                                                    [dataSource]="taxCodes"
                                                    placeholder="Tax Code"
                                                    floatLabelType="Auto"
                                                    [fields]="taxFields"
                                                    [itemTemplate]="itemTemplate"
                                                    [showClearButton]="true"
                                                >
                                                    <ng-template #itemTemplate="" let-data="">
                                                        <div class="tax-display">{{ formatTaxDisplay(data) }}</div>
                                                    </ng-template>
                                                </ejs-dropdownlist>

                                                <ng-container *ngIf="expenseFormF['taxCode'].touched && expenseFormF['taxCode'].errors">
                                                    <p class="caption status-danger" *ngIf="expenseFormF['taxCode'].errors['required']">
                                                        Tax Code is required.
                                                    </p>
                                                </ng-container>
                                            </td>

                                            <td class="e-rowcell">
                                                <ejs-numerictextbox
                                                    name="amount"
                                                    id="amount"
                                                    formControlName="amount"
                                                    floatLabelType="Never"
                                                    (change)="handleExpenseAmountChange($event)"
                                                />

                                                <ng-container *ngIf="expenseFormF['amount'].touched && expenseFormF['amount'].errors">
                                                    <p class="caption status-danger" *ngIf="expenseFormF['amount'].errors['required']">
                                                        Amount is required.
                                                    </p>
                                                </ng-container>
                                            </td>

                                            <td class="e-rowcell">
                                                <ejs-numerictextbox
                                                    name="taxAmount"
                                                    id="taxAmount"
                                                    formControlName="taxAmount"
                                                    floatLabelType="Never"
                                                    (change)="handleTaxAmountChange($event)"
                                                />

                                                <ng-container *ngIf="expenseFormF['taxAmount'].touched && expenseFormF['taxAmount'].errors">
                                                    <p class="caption status-danger" *ngIf="expenseFormF['taxAmount'].errors['required']">
                                                        Tax amount is required.
                                                    </p>
                                                </ng-container>

                                                <ng-container *ngIf="expenseFormF['taxAmount'].touched">
                                                    <p
                                                        class="caption status-danger"
                                                        *ngIf="expenseFormF['taxAmount'].value > expenseFormF['amount'].value"
                                                    >
                                                        Invalid tax amount
                                                    </p>
                                                </ng-container>
                                            </td>

                                            <td class="e-rowcell">
                                                <ejs-numerictextbox
                                                    name="netAmount"
                                                    id="netAmount"
                                                    formControlName="netAmount"
                                                    floatLabelType="Never"
                                                    [readonly]="true"
                                                />
                                            </td>

                                            <td class="e-rowcell">
                                                <div class="e-input-group">
                                                    <input
                                                        class="e-input e-field"
                                                        name="description"
                                                        formControlName="description"
                                                        type="text"
                                                        (focus)="focusIn($event.target)"
                                                        (blur)="focusOut($event.target)"
                                                    />
                                                </div>

                                                <ng-container
                                                    *ngIf="expenseFormF['description'].touched && expenseFormF['description'].errors"
                                                >
                                                    <p
                                                        class="caption status-danger"
                                                        *ngIf="expenseFormF['description'].errors['maxlength']"
                                                    >
                                                        Description can't be more than 255 characters.
                                                    </p>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>

                        <e-aggregates>
                            <e-aggregate>
                                <e-columns>
                                    <e-column type="Sum" field="taxCodeValue">
                                        <ng-template #footerTemplate let-data>Total</ng-template>
                                    </e-column>
                                    <e-column type="Sum" field="amount" format="C2">
                                        <ng-template #footerTemplate let-data>{{ data.Sum }}</ng-template>
                                    </e-column>
                                    <e-column type="Sum" field="taxAmount" format="C2">
                                        <ng-template #footerTemplate let-data>{{ data.Sum }}</ng-template>
                                    </e-column>
                                    <e-column type="Sum" field="netAmount" format="C2">
                                        <ng-template #footerTemplate let-data>{{ data.Sum }}</ng-template>
                                    </e-column>
                                </e-columns>
                            </e-aggregate>
                        </e-aggregates>
                    </ejs-grid>
                </div>
            </div>

            <div class="form__footer">
                <button
                    [ngClass]="['e-btn e-outline', checkForm.valid && !isEditingInProgress ? 'e-success' : '']"
                    id="cancelBtn"
                    content="Save"
                    (click)="createCheque()"
                    [disabled]="!checkForm.valid || isEditingInProgress"
                >
                    {{ checkFormF['batchPrinting'].value || selectedPaymentType?.name !== 'Cheque' ? 'Save' : 'Save and Print' }}
                </button>

                <button [ngClass]="['e-btn e-outline e-danger']" id="cancelBtn" content="Cancel" (click)="cancel()">Cancel</button>
            </div>
        </form>
    </div>
</div>
