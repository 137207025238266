<div class="personal-details">
    <div class="personal-details__header">
        <img src="https://preview.colorlib.com/theme/smash/images/image-2.jpg" alt="Circle Image" class="personal-details__img" />
    </div>

    <div class="personal-details__body">
        <div class="personal-details__title" *ngIf="userInfo">
            {{ userInfo.name.last }} , &nbsp;{{ userInfo.name.first }}&nbsp;{{ userInfo.name.middle }}
        </div>

        <div class="personal-details__designation">
            {{ userInfo && userInfo.details && userInfo.details.title }}
        </div>

        <div class="contact-details">
            <p *ngIf="userInfo && userInfo.phones && userInfo.phones[0].number">
                <i class="material-icons-outlined">call</i>
                <span *ngFor="let ph of userInfo.phones" class="smalltext"> {{ ph.number | formatPhonePipe : '###-###-####' }}</span>
            </p>

            <p *ngIf="userInfo && userInfo.email">
                <i class="material-icons-outlined">alternate_email</i>
                <span class="smalltext">{{ userInfo.email }}</span>
            </p>

            <p *ngIf="userInfo && userInfo.address && userInfo.address.address">
                <i class="material-icons-outlined">location_on</i>

                <span class="smalltext" style="word-break: break-all">
                    {{ userInfo.address.address }},&nbsp;{{ userInfo.address.city }},<br />&nbsp;{{ userInfo.address.state }},&nbsp;{{
                        userInfo.address.country
                    }},&nbsp;{{
                        userInfo.address.zip
                            | formatPhonePipe
                                : '###
									###'
                                : true
                    }}
                </span>
            </p>
        </div>
    </div>
</div>
