import { Component, OnInit, inject } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { SelectEventArgs } from '@syncfusion/ej2-angular-navigations';

import { CompanyDetails, EToasterStatus, ICompanyDetailsForm, IECHQAddress } from '@core/models';
import { CommonService, OrganizationService, ToasterService } from '@core/services';
import { Constant } from '@core/constants';

interface Data {
    formData: CompanyDetails;
    organizationID: string;
}

@Component({
    selector: 'app-edit-company-details',
    templateUrl: './edit-company-details.component.html',
    styleUrls: ['./edit-company-details.component.scss'],
})
export class EditCompanyDetailComponent implements OnInit {
    public form!: FormGroup;
    public disableFormFlag: boolean = false;
    public loading: boolean = false;
    public showAddressFlag: boolean = false;
    public showSettingFlag: boolean = false;
    public headerText: { text: string }[] = [{ text: 'Company Details' }, { text: 'Address' }];
    public companyID: string = '';
    public formData!: CompanyDetails;
    public addressFormData!: IECHQAddress;
    public initialFormValue!: ICompanyDetailsForm;
    public initialAddressFormData!: IECHQAddress;
    public ref: DialogRef<Data, boolean> = inject(DialogRef);
    public selectedTabIndex: number = 0;

    constructor(
        private formBuilder: FormBuilder,
        private organizationService: OrganizationService,
        private toasterService: ToasterService,
        private commonService: CommonService,
        private constant: Constant
    ) {
        this.form = this.formBuilder.group({
            companyName: ['', [Validators.required, Validators.maxLength(255)]],
            legalName: ['', [Validators.required, Validators.maxLength(255)]],
            email: [{ value: '', disabled: true }, [Validators.required, Validators.pattern('^w+([+.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$')]],
            phone: [''],
            extension: [''],
            website: ['', [Validators.maxLength(255), Validators.pattern('https?://.+')]],
        });
        this.formData = this.ref.data?.formData;
        this.companyID = this.ref.data?.organizationID;
    }

    ngOnInit(): void {
        this.bindFormData(this.formData);
        this.getOrganizationAddress();
    }

    get companyDetailsF(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    checkFormModified() {
        const currentFormValue: ICompanyDetailsForm = this.form.value;
        return !this.commonService.isEqual(currentFormValue, this.initialFormValue);
    }

    getOrganizationAddress = () => {
        this.loading = true;
        this.organizationService.getOrganizationAddress(this.companyID).subscribe({
            next: (data) => {
                this.initialAddressFormData = data;
                this.addressFormData = data;
                delete this.addressFormData.id;
                delete this.initialAddressFormData.id;
                this.loading = false;
            },
            error: (err) => {
                this.loading = false;
            },
        });
    };

    bindFormData = (formData: CompanyDetails) => {
        this.form.patchValue({
            companyName: (formData || {}).name || '',
            legalName: (formData || {}).legalName || '',
            phone: ((formData || {}).phone || {}).number || '',
            email: (formData || {}).admins[0].email || '',
            extension: ((formData || {}).phone || {}).extension || '',
            website: (formData || {}).website || '',
        });
        this.initialFormValue = this.form.value;
    };

    cancel = () => {
        this.ref.close(false);
    };

    handleAddressFormCancel = (address: IECHQAddress | null) => {
        this.ref.close(false);
    };

    updateCompanyDetails = () => {
        this.loading = true;
        const payload = this.createPayload(this.form.value);
        this.organizationService.updateOrganization(this.companyID, payload).subscribe({
            next: () => {
                this.loading = false;
                this.initialFormValue = this.form.value;

                if (this.commonService.isEqual(this.addressFormData, this.initialAddressFormData)) {
                    this.ref.close(true);
                    this.showToast(EToasterStatus.SUCCESS, this.constant.ORGANIZATION_CRUD_MSG.ORGANIZATION_UPDATE_SUCCESS);
                } else {
                    this.handleAddressSubmit(this.addressFormData);
                }
            },
            error: () => {
                this.loading = false;
                this.ref.close(false);
                this.showToast(EToasterStatus.DANGER, this.constant.ORGANIZATION_CRUD_MSG.ORGANIZATION_UPDATE_FAILURE);
            },
        });
    };

    createPayload = (data: ICompanyDetailsForm) => {
        const actionPayload: CompanyDetails = {
            name: (data || {}).companyName || '',
            admins: [
                {
                    email: (data || {}).email || '',
                    name: {
                        first: '',
                        last: '',
                        middle: '',
                    },
                },
            ],
            phone: {
                number: data.phone,
                extension: data.extension,
            },
            legalName: (data || {}).legalName || '',
            website: data.website,
        };
        return actionPayload;
    };

    handleAddressSubmit = (address: IECHQAddress) => {
        const isCompanyDetailsModified: boolean = this.checkFormModified();

        if (address) {
            const payload: IECHQAddress = this.createAddressPayload(address);

            this.loading = true;
            this.organizationService.updateOrganizationAddress(this.companyID, payload).subscribe({
                next: () => {
                    this.loading = false;
                    this.initialAddressFormData = this.addressFormData;
                    if (isCompanyDetailsModified) {
                        this.updateCompanyDetails();
                    } else {
                        this.ref.close(true);
                        this.showToast(EToasterStatus.SUCCESS, this.constant.ORGANIZATION_CRUD_MSG.ORGANIZATION_UPDATE_SUCCESS);
                    }
                },
                error: () => {
                    this.loading = false;
                    this.ref.close(false);
                    this.showToast(EToasterStatus.DANGER, this.constant.ORGANIZATION_CRUD_MSG.ORGANIZATION_UPDATE_FAILURE);
                },
            });
        }
    };

    createAddressPayload = (data: IECHQAddress): IECHQAddress => {
        const actionPayload: IECHQAddress = {
            firstLine: (data || {}).firstLine || '',
            lastLine: (data || {}).lastLine || '',
            city: data.city || '',
            state: data.state || '',
            country: data.country || '',
            zipCode: data.zipCode || '',
        };
        return actionPayload;
    };

    handleAddressFormUpdated = (addressForm: FormGroup) => {
        this.addressFormData = addressForm.value;
    };

    handleTabChange = (event: SelectEventArgs) => {
        this.selectedTabIndex = event.selectedIndex;
    };

    isFieldValid(field: string): boolean {
        return !!(!this.form.get(field)?.valid && (this.form.get(field)?.dirty || this.form.get(field)?.touched));
    }

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }
}
