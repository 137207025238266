<div class="tax-setup">
    <div class="tax-setup__header header d-flex align-items-center justify-content-space-between">
        <div class="header__left">
            <div class="header__title">Tax Details</div>
        </div>

        <div class="header__right">
            <button
                [ngClass]="['e-btn e-small e-outline e-success']"
                id="addNewBtn"
                content="Add New"
                (click)="handleTaxSetupDlg(false)"
                *ngIf="isAdminUser"
            >
                Add New
            </button>
        </div>
    </div>

    <div class="tax-setup__body">
        <ejs-grid
            #overviewgrid
            id="overviewgrid"
            [dataSource]="taxes"
            rowHeight="40"
            height="240"
            [allowSorting]="true"
            [allowPaging]="showPaginationFlag"
            [pageSettings]="pageSettings"
            class="body-font"
        >
            <e-columns>
                <e-column field="name" headerText="Name" />
                <e-column field="tax" headerText="%" />
                <e-column field="code" headerText="Code" />
                <e-column field="action" headerText="Action" headerText="" [visible]="true" width="40" class="tax-setup__action">
                    <ng-template #template let-data>
                        <div class="action-container">
                            <a
                                href="javascript:void(0)"
                                class="tax-setup__action--link edit"
                                (click)="handleTaxSetupDlg(true, data, $event)"
                                *ngIf="isAdminUser"
                            >
                                <span class="material-icons-outlined"> edit </span>
                            </a>
                        </div>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>
