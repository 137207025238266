<ng-container *ngIf="isBrowserSupport">
    <main class="chq-layout">
        <div class="chq-layout__scroll">
            <app-header></app-header>

            <div [ngClass]="['chq-layout__content', isMobile ? 'mobile' : '']">
                <ejs-sidebar
                    *ngIf="!isMobile"
                    #dockBar
                    id="dockSidebar"
                    [enableDock]="enableDock"
                    [width]="width"
                    [dockSize]="dockSize"
                    [ngClass]="['chq-layout__content--left sidebar', enableDock ? 'enabled' : 'disabled']"
                    (created)="onCreated()"
                    [enableGestures]="false"
                >
                    <div class="chq-layout__dock">
                        <ul class="sidebar__list">
                            <li class="sidebar__list--item" *ngFor="let menu of menus">
                                <a
                                    href="javascript:void(0)"
                                    [ngClass]="[
                                        'sidebar__list--link d-flex align-items-center',
                                        menu.path === selectedMenu ? 'active' : ''
                                    ]"
                                    (click)="handleMenuClick(menu)"
                                >
                                    <span class="material-icons-outlined">
                                        {{ menu.icon }}
                                    </span>

                                    <span class="e-text" *ngIf="!enableDock">
                                        {{ menu.label }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </ejs-sidebar>

                <ejs-sidebar
                    *ngIf="isMobile"
                    #dockBar
                    id="dockSidebar"
                    style="visibility: hidden"
                    (created)="onCreated()"
                    [ngClass]="['chq-layout__content--left sidebar']"
                    [showBackdrop]="true"
                    [closeOnDocumentClick]="true"
                    (change)="handleChange()"
                    [enableGestures]="false"
                >
                    <div class="chq-layout__dock">
                        <ul class="sidebar__list">
                            <li class="sidebar__list--item" *ngFor="let menu of menus">
                                <a
                                    href="javascript:void(0)"
                                    [ngClass]="[
                                        'sidebar__list--link d-flex align-items-center',
                                        menu.path === selectedMenu ? 'active' : ''
                                    ]"
                                    (click)="handleMenuClick(menu)"
                                >
                                    <span class="material-icons-outlined">
                                        {{ menu.icon }}
                                    </span>

                                    <span class="e-text">
                                        {{ menu.label }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </ejs-sidebar>

                <div [ngClass]="['chq-layout__content--right', enableDock ? 'enabled' : '']">
                    <div class="chq-layout__body">
                        <router-outlet></router-outlet>
                    </div>

                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </main>
</ng-container>

<ng-container *ngIf="!isBrowserSupport">
    <h1>Current browser cannot run this app, please try to use in another browser</h1>
</ng-container>
