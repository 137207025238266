<div class="bank-account-chart" *ngIf="refreshDashboard">
    <div class="bank-account-chart__title">{{ title }}</div>

    <div echarts [options]="options" [merge]="mergeOptions" class="bank-account-chart__container" *ngIf="transactions.length > 0"></div>
    <div
        [ngClass]="[transactions.length === 0 ? 'bank-account-chart__container empty' : 'bank-account-chart__container']"
        *ngIf="transactions.length === 0"
    >
        <div class="bank-account-chart__container--empty">{{ emptyMessage }}</div>
    </div>
</div>
