import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AggregateColumnModel,
    ColumnModel,
    GridComponent,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SortService,
} from '@syncfusion/ej2-angular-grids';
import { Subscription, delay, finalize, interval, of, switchMap, takeWhile } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import {
    BankAccountService,
    BatchPrintingService,
    ChequeService,
    CommonService,
    ExpenseAccountService,
    LoaderService,
    MenuService,
    SupplierService,
    ToasterService,
} from '@core/services';
import {
    EToasterStatus,
    IBankAccounts,
    IBatchPrintingPayload,
    IBatchTransation,
    ICheque,
    IECHQAddress,
    IExpenseAccounts,
    IMeta,
    ISupplier,
} from '@core/models';
import { Constant } from '@core/constants';
import { ConfirmationComponent } from '@shared/components';
import { DialogService } from '@ngneat/dialog';

@Component({
    selector: 'app-batch-printing',
    styleUrls: ['./batch-printing.component.scss'],
    templateUrl: './batch-printing.component.html',
    providers: [SortService],
})
export class BatchPrintingComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('chequeList')
    gridInstance!: GridComponent;
    public bankAccount = '';
    public bankAccounts: IBankAccounts[] = [];
    public bankDetailsForm: FormGroup = new FormGroup({
        bankAccount: new FormControl<string>('', [Validators.required]),
        chequeNumber: new FormControl<number | null>(null, []),
    });
    public fields: { text: string; value: string } = {
        text: 'name',
        value: 'id',
    };
    public isValidBankDetails: boolean = false;
    public pageSettings!: PageSettingsModel;
    public pageSizes: number[] = [10, 25, 50];
    public cheques: ICheque[] = [];
    public suppliers: ISupplier[] = [];
    public batchTransactionID: string = '';
    public pollingIsInProgress: boolean = false;
    public isPolling: boolean = false;
    private dataSubscription!: Subscription;
    public pdfUrl!: SafeResourceUrl;
    @ViewChild('pdfViewer') pdfViewer!: ElementRef;
    public bankAccountAddress: IECHQAddress | null = null;
    public expenseAccounts: IExpenseAccounts[] = [];
    private dialog: DialogService = inject(DialogService);
    public paymentTypes: { name: string; id: string }[] = [];

    constructor(
        private menuService: MenuService,
        private fb: FormBuilder,
        private bankAccountService: BankAccountService,
        private expenseAccountService: ExpenseAccountService,
        private chequeService: ChequeService,
        private supplierService: SupplierService,
        private batchPrintService: BatchPrintingService,
        private sanitizer: DomSanitizer,
        private toasterService: ToasterService,
        private constant: Constant,
        private commonService: CommonService,
        private loaderService: LoaderService,
        private cd: ChangeDetectorRef
    ) {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
        this.bankDetailsForm = this.fb.group({
            bankAccount: ['', [Validators.required]],
            chequeNumber: [{ value: null, disabled: true }, []],
        });
    }

    ngOnInit(): void {
        this.menuService.updateMenuSelection('batch-printing');
        this.getSuppliers();
        this.getBankAccounts();
        this.getPaymentTypes();
    }

    ngAfterViewInit(): void {
        this.pageSettings = { pageCount: 3, pageSize: this.pageSizes[0] };
    }

    ngOnDestroy() {
        this.stopPolling();
    }

    get bankDetailsFormF(): { [key: string]: AbstractControl } {
        return this.bankDetailsForm.controls;
    }

    getPaymentTypes = () => {
        this.chequeService.getPaymentTypes().subscribe({
            next: (res: { name: string; id: string }[]) => {
                if (res) {
                    this.paymentTypes = res;
                }
            },
        });
    };

    changeBankAccount = (bankAccount: string) => {
        this.bankAccount = bankAccount;

        if (bankAccount) {
            this.getExpenseAccounts();
            this.getBankAccountByID(bankAccount);
        } else {
            this.cheques = [];
        }
    };

    getCheques = () => {
        this.isValidBankDetails = true;
        const qString = `bankId=${this.bankAccount}&batchPrinting=true&sort=organizationUpdatedIndex-desc`;
        this.chequeService.getCheques(qString).subscribe({
            next: (res: { content: ICheque[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.cheques = res.content.map((obj) => {
                        const supplier = this.suppliers.find((item) => item.id === obj.supplierId);
                        obj['supplierName'] = supplier ? `${supplier.name?.first}, ${supplier.name?.last}` : '';
                        obj['expenseAccountNumber'] = this.expenseAccounts.find((item) => item.id === obj.expenseAccount)?.account || '';
                        obj['paymentTypeName'] = this.paymentTypes.find((item) => item.id === obj.paymentType)?.name || '';
                        obj['supplierCompanyName'] = obj.supplier?.companyName || '';
                        return obj;
                    });

                    ((this.gridInstance as GridComponent).columns[4] as ColumnModel).customAttributes = { class: 'currency-column' };
                    (((this.gridInstance as GridComponent).aggregates[0].columns || [])[0] as ColumnModel).customAttributes = {
                        class: 'currency-column',
                    };
                }
            },
        });
    };

    getExpenseAccounts = () => {
        this.expenseAccountService.getExpenseAccounts().subscribe({
            next: (res: IExpenseAccounts[]) => {
                this.expenseAccounts = res;
                this.getCheques();
            },
        });
    };

    getBankAccountByID = (id: string) => {
        this.bankAccountService.getBankAccountByID(id).subscribe({
            next: (res: IBankAccounts) => {
                if (res && 'address' in res && res.address) {
                    this.bankAccountAddress = res.address;
                }

                this.bankDetailsForm.patchValue({
                    chequeNumber: res.chequeSequence,
                });
            },
        });
    };

    getBankAccounts = () => {
        this.bankAccountService.getBankAccounts().subscribe({
            next: (res: IBankAccounts[]) => {
                this.bankAccounts = res;
            },
        });
    };

    getSuppliers = () => {
        this.supplierService.getSuppliers('').subscribe({
            next: (res: { content: ISupplier[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.suppliers = res.content;
                }
            },
        });
    };

    startBatchPrinting = () => {
        if (this.bankAccount) {
            const selection = this.gridInstance.getSelectedRecords() as unknown as ICheque[];
            if (selection.length > 0) {
                const payload: IBatchPrintingPayload = {
                    name: 'default',
                    transactions: selection.map((obj) => {
                        if (obj.chequeNumber) {
                            return {
                                id: obj.id,
                                chequeNumber: obj.chequeNumber,
                            };
                        } else {
                            return {
                                id: obj.id,
                            };
                        }
                    }),
                };
                this.batchPrintService.CreateBatchPrinting(payload).subscribe({
                    next: (res) => {
                        this.batchTransactionID = res?.data || '';
                        this.pollingIsInProgress = true;
                        this.isPolling = true;
                        this.startPolling();
                    },
                });
            }
        }
    };

    startPolling = () => {
        this.loaderService.show();
        this.dataSubscription = interval(5000)
            .pipe(
                takeWhile(() => this.pollingIsInProgress),
                switchMap(() =>
                    this.checkStatusAndFetchData().pipe(
                        finalize(() => {
                            if (!this.pollingIsInProgress) {
                                this.loaderService.hide();
                            }
                        })
                    )
                )
            )
            .subscribe({
                next: () => {
                    this.showToast(EToasterStatus.SUCCESS, this.constant.BATCH_PRINT_MSG.SUCCESS);
                },
                error: () => {
                    this.isPolling = false;
                    this.pollingIsInProgress = false;
                    this.showToast(EToasterStatus.DANGER, this.constant.BATCH_PRINT_MSG.FAILURE);
                },
            });
    };

    stopPolling() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    checkStatusAndFetchData = () => {
        return this.batchPrintService.getBatchTransactionByID(this.batchTransactionID).pipe(
            switchMap((res: IBatchTransation[]) => {
                if (res && res.length > 0 && res.every((item) => item.status === 'Completed')) {
                    this.stopPolling();
                    this.pollingIsInProgress = false;
                    const transactionIDs: string[] = res.map((item) => item.id);

                    this.cheques = this.cheques.map((obj) => {
                        const chequeToFind: IBatchTransation = res.find((item) => item.transactionId === obj.id) as IBatchTransation;
                        if (chequeToFind) {
                            obj['chequeId'] = chequeToFind.id || '';
                            obj['chequeNumber'] = chequeToFind.chequeNumber;
                        }

                        return obj;
                    });
                    this.gridInstance.refresh();

                    if (transactionIDs.length > 0) {
                        const payload: { id: string[] } = {
                            id: transactionIDs,
                        };

                        this.getMergedPDF(payload);
                    }
                } else if (res && res.length > 0 && res.some((item) => item.status === 'Failed')) {
                    this.stopPolling();
                    this.pollingIsInProgress = false;
                    this.isPolling = false;
                    this.loaderService.hide();
                    this.bankDetailsForm.get('bankAccount')?.enable();
                    this.gridInstance.refresh();
                    this.showToast(EToasterStatus.DANGER, this.constant.BATCH_PRINT_MSG.FAILURE);
                }
                // Delay for 5000 milliseconds before the next poll
                return of(null).pipe(delay(5000));
            })
        );
    };

    getMergedPDF = (payload: { id: string[] }) => {
        this.batchPrintService.getBatchPDF(payload).subscribe({
            next: (res: { status: boolean; data: string }) => {
                if (res && 'data' in res && res.data) {
                    this.getPdfFile(res.data);
                }

                this.showToast(EToasterStatus.SUCCESS, this.constant.BATCH_PRINT_MSG.MERGE_SUCCESS);
            },
            error: () => {
                this.showToast(EToasterStatus.DANGER, this.constant.BATCH_PRINT_MSG.FAILURE);
            },
        });
    };

    getPdfFile = (fileURL: string) => {
        this.commonService.getPDFFile(fileURL).subscribe((response: Blob) => {
            // Read the Blob data and convert it to an ArrayBuffer
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result as ArrayBuffer;
                const byteArray = new Uint8Array(arrayBuffer);
                // Create a Blob with the correct MIME type
                const blob = new Blob([byteArray], {
                    type: 'application/pdf',
                });
                // Create a Blob URL and open it in a new window
                const url = URL.createObjectURL(blob);
                this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

                setTimeout(() => {
                    this.printFile();
                    this.isPolling = false;
                    this.loaderService.hide();
                    this.bankDetailsForm.get('bankAccount')?.enable();
                    this.gridInstance.refresh();
                    this.cd.detectChanges();
                }, 1000);
            };

            reader.readAsArrayBuffer(response);
        });
    };

    printFile = () => {
        if (this.pdfViewer) {
            const iframe = this.pdfViewer.nativeElement;
            iframe.contentWindow.print();
        }
    };

    isPrintDisabled = (): boolean => {
        return (this.gridInstance && this.gridInstance.getSelectedRecords().length === 0) || this.isPolling;
    };

    public rowSelectionChanged() {
        const selection = this.gridInstance.getSelectedRecords();
        this.gridInstance.aggregateModule.refresh(selection);
    }

    public rowDataBound(args: RowDataBoundEventArgs): void {
        const rowData = args.data as ICheque;

        args.isSelectable = !(rowData?.chequeNumber && rowData?.chequeId);
    }

    handlePrintCheque = (event: MouseEvent, id: string) => {
        event.stopPropagation();

        if (id) {
            this.chequeService.getChequePDF(id).subscribe({
                next: (res: { status: boolean; data: { url: string } }) => {
                    if (res && 'data' in res && res.data && 'url' in res.data && res.data.url) {
                        this.getPdfFile(res.data?.url);
                    }
                },
            });
        }
    };

    cancel = () => {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            minWidth: '35vw',
            maxWidth: '100%',
            data: {
                title: 'Confirm',
                msg: 'Are you sure want to cancel the print selection?',
            },
        });
        dialogRef.afterClosed$.subscribe((result) => {
            if (result) {
                this.gridInstance.refresh();
            }
        });
    };

    customAggregateFn = (): number => {
        let sum = 0;
        (this.gridInstance.getSelectedRecords() as unknown as ICheque[]).forEach((data) => {
            sum += data['amount'];
        });
        return sum;
    };

    convertToUserTz = (dateStr: string): string | null => {
        return this.commonService.convertToUserTz(dateStr);
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }
}
