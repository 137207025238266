<div class="add-bank-account">
    <div class="add-bank-account__title">{{ title }}</div>

    <div class="add-bank-account__body">
        <form id="reactive" [formGroup]="form" class="add-bank-account__form form">
            <div class="form__group required">
                <ejs-textbox placeholder="Bank Name" floatLabelType="Always" formControlName="name" />

                <ng-container *ngIf="formControlF['name'].touched && formControlF['name'].errors">
                    <p class="caption status-danger" *ngIf="formControlF['name'].errors['required']">Bank Name is required.</p>

                    <p class="caption status-danger" *ngIf="formControlF['name'].errors['maxlength']">
                        Bank Name can't be more than 255 characters.
                    </p>
                </ng-container>
            </div>

            <div class="form__group">
                <ejs-textbox placeholder="Account#" floatLabelType="Always" formControlName="account" />

                <ng-container *ngIf="formControlF['account'].touched && formControlF['account'].errors">
                    <p class="caption status-danger" *ngIf="formControlF['account'].errors['maxlength']">
                        Bank account can't be more than 255 characters.
                    </p>
                </ng-container>
            </div>

            <div class="form__group required cheque-sequence">
                <ejs-numerictextbox
                    placeholder="Cheque Sequence Number"
                    floatLabelType="Always"
                    formControlName="chequeSequence"
                    format="#"
                    [decimals]="0"
                    [validateDecimalOnType]="true"
                />

                <ng-container *ngIf="formControlF['chequeSequence'].touched && formControlF['chequeSequence'].errors">
                    <p class="caption status-danger" *ngIf="formControlF['chequeSequence'].errors['required']">
                        Cheque Sequence Number is required.
                    </p>
                </ng-container>
            </div>

            <div class="form__group">
                <ejs-textbox placeholder="EA Account Name" floatLabelType="Always" formControlName="eaAccount" />

                <ng-container
                    *ngIf="formControlF['eaAccount'].value && formControlF['eaAccount'].touched && formControlF['eaAccount'].errors"
                >
                    <p class="caption status-danger" *ngIf="formControlF['eaAccount'].errors['maxlength']">
                        EA Account Name can't be more than 255 characters.
                    </p>
                </ng-container>
            </div>

            <div class="form__group">
                <ejs-textbox placeholder="Address Line 1" floatLabelType="Always" formControlName="firstLine" />
            </div>

            <div class="form__group">
                <ejs-textbox placeholder="Address Line 2" floatLabelType="Always" formControlName="lastLine" />
            </div>

            <div class="form__group">
                <ejs-dropdownlist
                    id="country"
                    name="country"
                    (change)="changeCountry($event)"
                    formControlName="country"
                    [dataSource]="countryList"
                    placeholder="Select Country"
                    floatLabelType="Always"
                    [showClearButton]="true"
                />
            </div>

            <div class="form__group" *ngIf="usaCountryFlag">
                <ejs-dropdownlist
                    id="state"
                    name="state"
                    formControlName="state"
                    [dataSource]="stateList"
                    placeholder="Select State"
                    floatLabelType="Always"
                />
            </div>

            <div class="form__group" *ngIf="provinceFlag">
                <ejs-dropdownlist
                    id="state"
                    name="state"
                    formControlName="state"
                    [dataSource]="provinceList"
                    placeholder="Select Province"
                    floatLabelType="Always"
                />
            </div>

            <div class="form__group" *ngIf="!usaCountryFlag && !provinceFlag">
                <ejs-textbox placeholder="State" floatLabelType="Always" formControlName="state" />
            </div>

            <div class="form__group">
                <ejs-textbox placeholder="City" floatLabelType="Always" formControlName="city" />
            </div>

            <div class="form__group" *ngIf="provinceFlag">
                <ejs-maskedtextbox
                    placeholder="Postal Code"
                    floatLabelType="Always"
                    mask="L0L 0L0"
                    formControlName="zipCode"
                    style="text-transform: uppercase"
                />

                <ng-container *ngIf="formControlF['zipCode'].touched && formControlF['zipCode'].errors">
                    <p class="caption status-danger" *ngIf="form.controls['zipCode'].invalid">
                        Enter zip code (X0X 0X0) this format. (eg: A1A1A1)
                    </p>
                </ng-container>
            </div>

            <div class="form__group" *ngIf="usaCountryFlag">
                <ejs-maskedtextbox
                    placeholder="Postal Code"
                    floatLabelType="Always"
                    mask="00000"
                    formControlName="zipCode"
                    style="text-transform: uppercase"
                />

                <ng-container *ngIf="formControlF['zipCode'].touched && formControlF['zipCode'].errors">
                    <p class="caption status-danger" *ngIf="form.controls['zipCode'].invalid">Enter zip code XXXXX in this format</p>
                </ng-container>
            </div>

            <div class="form__group" *ngIf="!usaCountryFlag && !provinceFlag">
                <ejs-textbox placeholder="Postal Code" floatLabelType="Always" formControlName="zipCode" />
            </div>

            <div class="form__footer">
                <button [ngClass]="['e-btn e-outline e-danger']" id="cancelBtn" content="Cancel" (click)="cancel()">Cancel</button>

                <button
                    [ngClass]="['e-btn e-outline', this.form.valid ? 'e-success' : '']"
                    id="cancelBtn"
                    [disabled]="!this.form.valid"
                    (click)="addBankAccount()"
                >
                    {{ isEdit ? 'Update' : 'Save' }}
                </button>
            </div>
        </form>
    </div>
</div>
