import { createSelector } from '@ngrx/store';

import { AuthState } from '@core/models';
import { getAuthFeature } from './auth-feature';

const getAuthState = createSelector(getAuthFeature, (state) => state.auth);

export const getUserAuthenticated = createSelector(
    getAuthState,
    (state: AuthState) => state.isAuthenticated
);

export const getUser = createSelector(
    getAuthState,
    (state: AuthState) => state.user
);

export const getError = createSelector(
    getAuthState,
    (state: AuthState) => state.errorMessage
);
