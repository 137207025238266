import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as fromDeps from '@pages/auth/dependencies';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService {
    public isLoggedIn = false;

    constructor(
        public router: Router,
        private authFacade: fromDeps.store.AuthFacade
    ) {
        this.authFacade.isUserAuthenticated$().subscribe((res) => {
            this.isLoggedIn = res;
        });
    }

    canActivate(): boolean {
        if (!this.isLoggedIn) {
            this.router.navigateByUrl('/login');
            return false;
        }
        return true;
    }
}
