export class User {
    email!: string;
    password?: string;
    token?: string;
    user?: string;
    profile?: IProfile;
}

export interface IProfile {
    date: {
        created: string;
        modified: string;
        verified: string;
    };
    active: boolean;
    status: number;
    email: string;
    id: string;
    name: {
        first: string;
        last: string;
    };
}

export interface AuthState {
    isAuthenticated: boolean;
    user: ILoginResponse | null;
    errorMessage: string | null;
}

export interface ILoginPayload {
    email: string;
    password: string;
}

export interface ILoginResponse {
    user: string;
    token: string;
    access: {
        token: string;
        expires: string;
    };
    googleCredentials: {
        clientId: string;
    };
    refresh: {
        token: string;
        expires: string;
    };
    profile: IProfile;
    email?: string;
}

export interface IChangePasswordPayload {
    password: string;
    new_password: string;
}

export interface IResetPasswordPayload {
    password: string;
    reset_token: string;
}

export interface IUpdateUserPayload {
    email: string;
    name: {
        first: string;
        last: string;
    };
    birthday: string;
    phone: string;
    gender: string;
    meta: {
        marketing_opt_in: boolean;
        additionalProp1: unknown;
    };
}
